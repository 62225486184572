import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import { ACCOUNT_BANK, BELL_ICON } from "../../helper/assets";
import Parser from "html-react-parser";
import { SET_SUCCESS_MESSAGE } from "../../redux/actions/types";
import notifications from "../../redux/api/notifications";
import moment from "moment";
function Notifications() {
  const [records, setRecords] = useState([]);

  const fetch = async () => {
    var x = await notifications.all();
    if (x.status == 200) {
      console.log("x.data.data", x.data.data);
      setRecords(x.data.data);
    }
  };

  useEffect(() => {
    fetch();
    return () => {
      //setRecords([]);
    };
  }, []);

  return (
    <Container className="container-area">
      <div className="content-area">
        <h1 className="text-4xl font-bold text-primary">Notifications</h1>
        <div className="notifications-board">
          {records?.length == 0 && (
            <h1 className="flex justify-center text-lg">No Notification</h1>
          )}
          {records.map((item, index) => (
            <>
              <div className="single-notifcation" key={index}>
                <div className="left-bar"></div>
                <div className="content-bar">
                  <h4>{item.title}</h4>
                  <div className="message">
                    <p>{item.message}</p>
                  </div>
                  <div className="timestamp">
                    {moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A")}
                  </div>
                </div>
                <div className="action-bar"></div>
              </div>
            </>
          ))}
        </div>
      </div>
    </Container>
  );
}
export default Notifications;
