import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContractCard from '../ContractCard/ContractCard';
import {BELL_ICON} from '../../helper/assets'; 
import Parser from 'html-react-parser';

import WizardContractCheckList from './ContractFormWizard/WizardContractCheckList';
import WizardContractPhoto from './ContractFormWizard/WizardContractPhoto';
import WizardContractSubmit from './ContractFormWizard/WizardContractSubmit';
import WizardContractConfirmation from './ContractFormWizard/WizardContractConfirmation';

import ContractHandoffProgressBar from './components/ContractHandoffProgressBar';

import { io } from 'socket.io-client';
import { conformsTo } from 'lodash';
import { A_HANDOFF_ACTIVE_STATE, X_SINGLE_CONTRACT_BY_ID } from '../../redux/actions/contracts.action';

function ContractHandoffPage() {
    const socket = io(process.env.REACT_APP_API_PATH);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user); 
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    
    const [contractHandoffUserState, setContractHandoffUserState] = useState({
        buyer: {
          id: -1,
          state: 'in_process'
        },
        seller: {
          id: -1,
          state: 'in_process'
        },
        state:'waiting',
      });
    const [socketState, setSocketState] = useState(null);
    /* const [isConnected, setIsConnected] = useState(socket.connected); */
    const [stateMessage, setStateMessage] = useState('connecting');
    const [fooEvents, setFooEvents] = useState([]);

    const handoff_contract_detail = useSelector((store) => store.contracts.handoff_contract_detail);
    const activeState = useSelector((store) => store.contracts.handoff_active_state);

 
    useEffect(() => {
        // Socket.IO event listeners
        socket.on('connect', () => {
            setStateMessage('connected')
            console.log('Connected to server' , socket.id);
        });

        dispatch({type : X_SINGLE_CONTRACT_BY_ID ,  payload : {id: params.id} }) 

        if(edit_contract && edit_contract.id) {

            var sender = ``;
            if(edit_contract.role == 'seller'){
                if(edit_contract.id && edit_contract.created_by_user_id == user.id){
                    sender = 'seller';
                }else{
                    sender = 'buyer';
                }
            }else if(edit_contract.role == 'buyer'){
                if(edit_contract.id && edit_contract.created_by_user_id == user.id){
                    sender = 'buyer';
                }else{
                    sender = 'seller';
                }
            }

            if(edit_contract.id && edit_contract.created_by_user_id == user.id) {
                console.log('sending seller data');
                socket.emit('joinRoom', edit_contract.id , sender , user.id);
            }else{
                console.log('sending buyer data');
                socket.emit('joinRoom', edit_contract.id , sender , user.id);
            }

            socket.on('receivedRoomState', (data) => { 
                console.log('Contract Handoff State:', data);
                setContractHandoffUserState(prevState => ({
                    ...prevState,
                    ...data
                }));
                
            });
    
            socket.on('disconnect', () => {
                setStateMessage('disconnected')
                console.log('Disconnected to server'); 
            });

        }

        // Cleanup on component unmount
        return () => {
            var sender = ``;
            if(edit_contract.role == 'seller'){
                if(edit_contract.id && edit_contract.created_by_user_id == user.id){
                    sender = 'seller';
                }else{
                    sender = 'buyer';
                }
            }else if(edit_contract.role == 'buyer'){
                if(edit_contract.id && edit_contract.created_by_user_id == user.id){
                    sender = 'buyer';
                }else{
                    sender = 'seller';
                }
            }
            socket.emit('leaveRoom', edit_contract.id ,  sender);
            socket.disconnect({contract_id: edit_contract.id , user_id : user.id});
        };
        
    }, [dispatch]);

  return (
    <Container className='container-area'>
        <div className="content-area">
           
        
            <div className="flex flex-col">
                <h1 className='text-3xl text-primary mb-4 font-bold'>Confirm Item Exchange</h1>

                <div className='connection-waiting'>
                    <div>{stateMessage} ...</div>
                </div>
                <div className='connection-update'>
                    <div className="flex justify-between w-full">
                        {
                            (() => {
                                if(edit_contract && edit_contract.id ){
                                    if(edit_contract.handoff_seller && edit_contract.handoff_seller.id){
                                        return <>Seller: Submited</>;
                                    }else{
                                        return <div>Seller: {contractHandoffUserState.seller.id == -1 ? 'Waiting' : 'Connected'} - {contractHandoffUserState.seller.state && contractHandoffUserState.seller.state.replace('_' , ' ')}    </div>
                                    }
                                }
                            })()
                        }
                        {
                            (() => {
                                if(edit_contract && edit_contract.id ){
                                    if(edit_contract.handoff_buyer && edit_contract.handoff_buyer.id){
                                        return <div>Buyer: Submited</div>;
                                    }else{
                                        return <div>Buyer: {contractHandoffUserState.buyer.id == -1 ? 'Waiting' : 'Connected'} - {contractHandoffUserState.buyer.state && contractHandoffUserState.buyer.state.replace('_' , ' ')}  </div>
                                    }
                                }
                            })()
                        }
                    </div>
                </div>

                <ContractHandoffProgressBar />
                <div className="contract-build-wizard-contaner mt-10 flex">
                    <div className='contract-build-wizard-contaner flex'>
                        {activeState == 'checklist' && (
                            <WizardContractCheckList  />
                        )}
                        {activeState == 'photo' && (
                            <WizardContractPhoto />
                        )}
                        {activeState == 'submit' && (
                            <WizardContractSubmit cb={() => {
                                socket.emit('updateUserState', edit_contract.id ,  edit_contract.id && edit_contract.created_by_user_id == user.id ? 'seller' : 'buyer'  , 'submited');
                            }} />
                        )}
                        {(activeState == 'confirm') && (
                            <WizardContractConfirmation />
                        )}
                    </div>
                </div>
            </div> 
            
            
        </div>
    </Container>
  );
}
export default ContractHandoffPage;