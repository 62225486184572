export const X_GET_ALL_CONTRACTS = 'X_GET_CONTRACTS' 
export const X_CREATE_CONTRACT = 'X_CREATE_CONTRACT' 
export const X_UPDATE_CONTRACT = 'X_UPDATE_CONTRACT' 
export const X_SEND_CONTRACTS = 'X_SEND_CONTRACTS' 
export const X_SEND_CONTRACT_INNER = 'X_SEND_CONTRACT_INNER' 
export const X_SINGLE_CONTRACT_BY_ID = 'X_SINGLE_CONTRACT_BY_ID' 
export const X_SINGLE_CONTRACT_BY_TOKEN = 'X_SINGLE_CONTRACT_BY_TOKEN' 
export const X_ARCHIVE_CONTRACT = 'X_ARCHIVE_CONTRACT' 
export const X_UNARCHIVE_CONTRACT = 'X_UNARCHIVE_CONTRACT' 
export const X_ACCEPT_CONTRACT = 'X_ACCEPT_CONTRACT' 
export const X_REJECT_CONTRACT = 'X_REJECT_CONTRACT' 
export const X_CANCELE_CONTRACT = 'X_CANCELE_CONTRACT' 
export const X_GET_CONTRACT_BY_INV_TOKEN = 'X_GET_CONTRACT_BY_INV_TOKEN' 
export const X_ACCEPT_CONTRACT_AFTER_LOGIN = 'X_ACCEPT_CONTRACT_AFTER_LOGIN'
export const X_ACCEPT_CONTRACT_AFTER_SETUP_PASSWORD = 'X_ACCEPT_CONTRACT_AFTER_SETUP_PASSWORD'
export const X_REMOVE_CONTRACT = 'X_REMOVE_CONTRACT'
export const CLEAR_SINGLE_CONTRACT = 'CLEAR_SINGLE_CONTRACT'

export const A_CONTRACT_ACTIVE_STATE = 'A_CONTRACT_ACTIVE_STATE'
export const A_HANDOFF_ACTIVE_STATE = 'A_HANDOFF_ACTIVE_STATE'

export const DATA_SINGLE_CONTRACT = 'DATA_SINGLE_CONTRACT'
export const DATA_HANDOFF_CONTRACT = 'DATA_HANDOFF_CONTRACT'
export const DATA_SINGLE_CONTRACT_SELLER = 'DATA_SINGLE_CONTRACT_SELLER'
export const DATA_SINGLE_CONTRACT_BUYER = 'DATA_SINGLE_CONTRACT_BUYER'
export const DATA_ALL_CONTRACT = 'DATA_ALL_CONTRACT'


  
/* export const contract_create = (payload) => (dispatch) => {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload : '' });
    dispatch({ type: SET_ERROR_MESSAGE, payload : '' });
    return ContractServices.contract_create(payload).then((response) => {
        var xp = {
            status: response.data.status,
            message: response.data.message,
        }
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: xp,
            });
          dispatch({
            type: SINGLE_CONTRACT,
            payload : response.data && response.data.data ? response.data.data : {} ,
          });
        }
        return Promise.resolve(xp);
      },
      (error) => {
        var data = error.response.data;
        var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
        
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
};

export const send_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.send_contract(payload).then((response) => {
        var xp = {
            status: response.data.status,
            message: response.data.message,
        }
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: xp,
            });
        }
        return Promise.resolve(xp);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const single_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.single_contract(payload).then((response) => {
        if(response.data.status == 200){
            var xp = {
                status: response.data.status,
                message: response.data.message,
            }
            dispatch({
                type: SET_RESPONSE,
                payload: xp,
            });
            dispatch({
                type: SINGLE_CONTRACT,
                payload : response.data ? response.data  : {} ,
            });
        }
        return Promise.resolve(xp);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const view_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.view_contract(payload).then((response) => {
        console.log('action payload', response)
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: {mesage: response.data.message},
            });
            dispatch({
                type: VIEW_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
        } 
        return Promise.resolve(response.data);
      },
      (error) => {
        console.log('error',error)
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const archive_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.archive_contract(payload).then((response) => {
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: {mesage: response.data.message},
            });
            dispatch({
                type: SINGLE_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
        } 
        return Promise.resolve(response.data);
      },
      (error) => {
        console.log('error',error)
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const unarchive_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.unarchive_contract(payload).then((response) => {
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: {mesage: response.data.message},
            });
            dispatch({
                type: SINGLE_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
        } 
        return Promise.resolve(response.data);
      },
      (error) => {
        console.log('error',error)
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const accept_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.accept_contract(payload).then((response) => {
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: {mesage: response.data.message},
            });
            dispatch({
                type: SINGLE_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
        } 
        return Promise.resolve(response.data);
      },
      (error) => {
        console.log('error',error)
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const reject_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.reject_contract(payload).then((response) => {
        if(response.data.status == 200){
            dispatch({
                type: SET_RESPONSE,
                payload: {mesage: response.data.message},
            });
            dispatch({
                type: SINGLE_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
        } 
        return Promise.resolve(response.data);
      },
      (error) => {
        console.log('error',error)
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const fetch_send_contract = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.single_contract(payload).then((response) => {
        if(response.data.status == 200){
            var xp = {
                status: response.data.status,
                message: response.data.message,
            }
            dispatch({
                type: SET_RESPONSE,
                payload: xp,
            });
            dispatch({
                type: SINGLE_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
            
        }
        return Promise.resolve(xp);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};


export const get_contract_by_invitation_token = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.get_contract_by_invitation_token(payload).then((response) => {
        if(response.data.status == 200){
            var xp = {
                status: response.data.status,
                message: response.data.message,
            }
            dispatch({
                type: SET_RESPONSE,
                payload: xp,
            });
            dispatch({
                type: INVITATION_CONTRACT,
                payload : response.data && response.data.data ? response.data.data : {} ,
            });
        }
        return Promise.resolve(response.data);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const accept_contract_after_login = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.accept_contract_after_login(payload).then((response) => {
        if(response.data.status == 200){
            var xp = {
                status: response.data.status,
                message: response.data.message,
            }
            dispatch({
                type: SET_RESPONSE,
                payload: xp,
            });
        }
        return Promise.resolve(response.data);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
};

export const fetch_all_contracts = (payload) => (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE,
    });
    return ContractServices.fetch_all_contracts(payload).then((response) => {    
        console.log('response.data',response.data)
        
        dispatch({
            type: FETCH_CONTRACTS,
            payload : response.data && response.data ? response.data  : [] ,
        });

        return Promise.resolve(response.data);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? error.response.data.message : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
}; */