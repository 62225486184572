import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import Container from '@mui/material/Container';
import {BELL_ICON, CONTRACT_BLOCKED, REJECT_ICON} from '../../helper/assets';
import Parser from 'html-react-parser';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/fontawesome-free-solid'
import { A_CONTRACT_ACTIVE_STATE, CLEAR_SINGLE_CONTRACT, X_GET_ALL_CONTRACTS } from '../../redux/actions/contracts.action';
import { CONTRACT_STATUS_BUYER, CONTRACT_STATUS_SELLER } from '../../helper/consts';
import { isContractDateExpired } from '../../helper/cf_helper';


function ContractsPage() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const all_contracts = useSelector((store) => store.contracts.all_contracts);

  const contractStatusSeller = CONTRACT_STATUS_SELLER;
  const contractStatusBuyer = CONTRACT_STATUS_BUYER;

  const [filter , setFilter] = useState({
    'contract' : 'all',
    'status' : 'active',
  })
  
  const applyFilter = (key , value) => {
    var x = {...filter}
    x[key] = value;
    dispatch({type : X_GET_ALL_CONTRACTS , payload : x})
    setFilter({...x});
  }


  useEffect(() => {
    dispatch({ type: CLEAR_SINGLE_CONTRACT });
    dispatch({type : X_GET_ALL_CONTRACTS , payload : filter})
    dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
        state : 'creating'
    }});
  }, []);

  return (
    <Container className='container-area'>
        <div className="content-area contract-listing">
            <div className="flex flex-col">
                <div className='w-full flex justify-between gap-5 mb-10 contract-top-headings'>
                    {/* <button className='btn btn-primary' type="button">Contracts</button>  */}
                    <div className='flex justify-between contract-main-action-buttons'>
                        <div className="flex gap-5">
                            <button className={`link-button` + (filter.status == 'active' ? ' active ' :  '') } type="button" onClick={() => {applyFilter('status' , 'active')}}>Active</button> 
                            <button className={`link-button` + (filter.status == 'archived' ? ' active ' :  '') } type="button" onClick={() => {applyFilter('status' , 'archived')}}>Archived</button> 
                            <button className='link-button' type="button" >Export</button> 
                        </div>
                    </div>
                    <Link className='btn btn-primary-outline' type="button" to='/contract/build' >Build Contract</Link> 
                </div>
                

                <div className='flex flex-col '>
                {
                    all_contracts && all_contracts.length > 0 ?
                        <div className="contract-listing">
                        {all_contracts.map((item, index) => (
                            <div className="c-list"  key={index}>
                                <div className="flex gap-3 justify-between heading-area">
                                    <div className="heading">
                                        <h4>{item.title} Agreement</h4>
                                        <div className="info">
                                            <span>{moment(item.delivery_pick_up_date).format('DD/MM/YYYY')}</span>
                                            {item.delivery_pick_up_time && <span>@ {moment(item.delivery_pick_up_time).format('hh:mm A')}</span>}
                                            <span>#{item.id}</span>
                                        </div>
                                    </div>
                                    <div className="actions">
                                        <div className="tag">
                                            Selling
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-direction-column info-area">
                                    <h6>Product</h6>
                                    <div className="grid">
                                        <span><b>Condition:</b> {item.product_condition}</span>
                                        <span><b>Serial Number: </b> {item.product_serial_number != '' ? item.product_serial_number : '---'}</span>
                                        <span><b>Quantity:</b> {item.product_qty}</span>
                                        <span><b>Price: </b>$ {item.product_total_purchase_price}</span>
                                    </div>
                                    <span style={{marginTop:'5px'}}><b>Description: </b>{item.product_description}</span>
                                </div>
                                <div className="flex info-area">
                                    <div className="grid">
                                        <div className="group">
                                            <h6>Buyer</h6>
                                            {item.buyer_name && <p>{item.buyer_name}</p>}
                                            {item.buyer_email && <p>{item.buyer_email}</p>}
                                            {item.buyer_phone && <p>{item.buyer_phone}</p>}
                                            {item.buyer_full_address && <p>{item.buyer_full_address}</p>}
                                        </div>
                                        <div className="group">
                                            <h6>Seller</h6>
                                            {item.seller_name && <p>{item.seller_name}</p>}
                                            {item.seller_email && <p>{item.seller_email}</p>}
                                            {item.seller_phone && <p>{item.seller_phone}</p>}
                                            {item.seller_full_address && <p>{item.seller_full_address}</p>}
                                        </div>
                                        <div className="group">
                                            <h6>Delivery Location</h6>
                                            <p>{item.delivery_pick_up_location}</p>
                                            <p>{item.delivery_address}</p>
                                            <p>
                                                {item.delivery_city ? item.delivery_city  : ''}
                                                {item.delivery_state ? ' ' + item.delivery_state  : ''}
                                                {item.delivery_zip ? ' ' + item.delivery_zip + ' ' : ''}
                                            </p>
                                            {item.delivery_meet_up_notes != '' && (<p><b>Notes:</b> {item.delivery_meet_up_notes}</p>)}
                                        </div>
                                    </div>
                                </div>
                                {isContractDateExpired(item.delivery_pick_up_date , item.delivery_pick_up_time) || item.is_archived && 
                                    <div className="flex info-area">
                                        <div className="grid">
                                            {isContractDateExpired(item.delivery_pick_up_date , item.delivery_pick_up_time) && <span className=''><b>Contract Expired?</b> Yes</span>}
                                            {item.is_archived && (<span className='icon-archive'><b>Is Archived?</b> Yes</span>)}
                                        </div>
                                    </div>
                                }
                                <div className="flex items-center justify-between gap-3 footer-area-c">
                                    <div className="profile-strength flex mt-3 gap-3">
                                        {
                                            (() => {
                                                var html = ``;
                                                if(item.status != 'rejected' && !item.is_contract_canceled){
                                                    var statusX = [0,0,0,0];
                                                    var counterX = 0;
                                                    for (const [key, value] of Object.entries(contractStatusSeller)) {
                                                        statusX[counterX] = 1;
                                                        if(key == item.status){
                                                            statusX[counterX] = 2
                                                            break;
                                                        }
                                                        counterX++;
                                                    }
                                                    
                                                    for (var i = 0 ; i < statusX.length ; i++) {
                                                        var printStatus = `unverified`;
                                                        if(statusX[i] == 1) printStatus = `clear`;
                                                        if(statusX[i] == 2) printStatus = `inreview`;
                                                        html += `<span className='strenght-style strenght-style-${printStatus} '></span>` ;
                                                    }
                                                }
                                                return Parser(html);
                                            })()
                                        }

                                        {
                                            (() => {
                                                var html = null;
                                                if(item.is_contract_canceled){
                                                    html = <span className='text-sm'>Contract has been canceled.</span>
                                                }else{
                                                    if(item.created_by_user_id == user.id) {
                                                        html = <span className='text-sm'>{contractStatusSeller[item.status]}</span>
                                                    }else{
                                                        html = <span className='text-sm'>{contractStatusBuyer[item.status]}</span>
                                                    }
                                                }
                                                return html;
                                            })()
                                        }
                                    </div> 
                                    <div className="footer-actions">
                                        <div className="open-contract-btn">
                                            <Link className='link-primary' to={`/contract/view/${item.id}`} >
                                                View contract {'>>'}
                                            </Link> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>                          
                    :
                    <div className='p-14 flex justify-center items-center'>
                        <h5 className='text-xl'>No contract found</h5>
                    </div>
                }
                    
                </div>
            
            </div> 
          
        </div>
    </Container>
  );
}
export default ContractsPage;