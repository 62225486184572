import React from 'react';
import aboutUsImage from './../../assets/about-us-image.jpg'
import HomepageLoginFormGroup from '../common/HomepageLoginFormGroup'
// This is one of our simplest components
// It doesn't have local state,
// It doesn't dispatch any redux actions or display any part of redux state
// or even care what the redux state is'

function AboutUsPage() {
  return (
    <>
      <div className='h-100-p root-page-bg-style'>
        <div className="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto h-100-p spliter-bg" >
          <div className="w-full flex justify-between gap-5 h-100-p">
            <HomepageLoginFormGroup className="w-2/5" />
            <div className='w-3/5  right-container flex flex-col content-center justify-start pt-10 bg-primary'>
              <div className='w-full flex flex-col gap-3 ps-20'>
                  <h1 className='text-2xl font-bold text-white'>ABOUT US</h1>
                  <div className='w-full'>
                    <img src={aboutUsImage} alt=""  className='w-full rounded-md' />
                  </div>
                  <p className='text-sm text-white'>
                    The aftermath of the pandemic has led to people paying increasingly more for, or even unable to find, essential goods and products due to the growing pressure on supply chains. As a result, more and more people are turning to peer-to-peer selling/buying platforms.
                  </p>
                  <p className='text-sm text-white'>
                    Unfortunately, not everyone on these peer-to-peer platforms a is honest or legitimate. In these cases, buyers and sellers face the reality of losing their money or belongings. Scams have continued to grow and increase in recent years without any meaningful or impactful intervention.
                  </p>
                  <p className='text-sm text-white'>
                    TradeOut’s contractual agreements help hold people accountable. The agreements, signed electronically by both parties, hold buyers accountable for purchasing an item and sellers accountable for delivering it. In addition to facilitating negotiations, TradeOut gives buyers and sellers a firmer legal footing to take action and increased peace of mind. In addition to the binding agreements, TradeOut will allow for a variety of different payment methods to help facilitate peer to peer (p2p) transactions.
                  </p>
              </div>            
            </div>
          </div> 
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
