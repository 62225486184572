import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import LoaderUI from '../common/Loader';
import Input from '../common/forms/input';
import { USER_UPDATE_SECRUTIY_INFO } from '../../redux/actions/user.action';

function SettingsSecurityTab() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const [disabled, setDisabled] = useState(false);
  const [passwordMatchFailed, setPasswordMatchFailed] = useState(false);
  const [loader, setLoader] = useState(false);


  const [formData, setFormData] = useState({
    old_password : '',
    new_password : '' ,
    confirm_password : '',
  });
  const handleInputChanged = (e) => {
    const { name, value } = e.target;    
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const updateSecurityInformation = (event) => {
    event.preventDefault();
    
    if(formData.new_password != formData.confirm_password){
      setPasswordMatchFailed(true);
      return false;
    }else{
      setPasswordMatchFailed(false);
    }

    dispatch({type: USER_UPDATE_SECRUTIY_INFO ,  payload: formData})
    
  };

  useEffect(() => {
    /* dispatch(clearMessage) */
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <h2 className='text-xl text-dark font-bold mb-3'>Change Password</h2>
        <div className="flex gap-5 mt-5">
          <div className="form-area w-full flex justify-center w-1/2">
            <form className="w-full rounded-md" onSubmit={updateSecurityInformation} >
              <div className="flex gap-3 w-full">
                <div className="w-full left flex flex-col gap-3">
                    <div className="flex flex-col gap-2">
                      <Input type="password" className="rounded-md nromal-padding " parentClassName="settings-input" placeholder="old password" label="Old Password" 
                         value={formData.old_password} name="old_password" required disabled={(disabled) ? "disabled" : ""} onChange={handleInputChanged}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Input type="text" className="rounded-md nromal-padding " parentClassName="settings-input" placeholder="new password"  label="New Password" 
                         value={formData.new_password} name="new_password" required disabled={(disabled) ? "disabled" : ""} onChange={handleInputChanged}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Input type="text" className="rounded-md nromal-padding " parentClassName="settings-input" placeholder="confirm password"  label="Confirm Password" 
                         value={formData.confirm_password} name="confirm_password" required disabled={(disabled) ? "disabled" : ""} onChange={handleInputChanged}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      { passwordMatchFailed && (
                        <small className='text-danger'>Password and confirm password does not match. please type again. </small>
                      )}
                    </div>
                </div>
              </div>
              <div className='w-full text-center flex justify-end mt-8'>
                <button type='submit' className='btn btn-primary float-right flex gap-2 items-center justify-center'>
                  {loader ?  
                      <LoaderUI />
                      :
                      <div className='flex  gap-2 items-center justify-center'>
                        <span className=''>update password</span> 
                      </div>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default SettingsSecurityTab;