import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { A_CONTRACT_ACTIVE_STATE, DATA_SINGLE_CONTRACT } from './../../../../redux/actions/contracts.action';
import MuiPhoneNumber from 'material-ui-phone-number';
import AddressValidationForm from '../../../AccountsPage/components/AddressValidationForm';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const onlyCountriesAllowed = ['us'];

function IsSeller_WizardContractBuyer () {

    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [cantSelectThisAddress, setCantSelectThisAddress] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [formData, setFormData] = useState({
        buyer_name: "",
        buyer_address_full: "",
        buyer_address: "",
        buyer_city: "",
        buyer_state: "",
        buyer_zip: "",
        buyer_email: "",
        buyer_phone: ""
    });

    const handleInputChanged = (e) => {
        const { name, value } = e.target;    
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const onChangePhoneNumber = (value) => {
        setFormData(prevState => ({
            ...prevState,
            ['buyer_phone']: value
        }));
    }

    const submitForm = (event) => {
        event.preventDefault();

        if(selectedAddress != null && selectedAddress.adminArea1Long && selectedAddress.adminArea1Long.toLowerCase() != "minnesota"){
            setCantSelectThisAddress(true);
            return false;
        }

        dispatch({ type: DATA_SINGLE_CONTRACT , payload : formData});
        dispatch({ type:  A_CONTRACT_ACTIVE_STATE , payload : {
            state : 'producut_description'
        }});

    }

    
    useEffect(() => {
        var x = {
            buyer_name: edit_contract.buyer_name ? edit_contract.buyer_name :  (edit_contract.id ? "" : ''),
            buyer_address_full: edit_contract.buyer_address_full ? edit_contract.buyer_address_full :  "",
            buyer_address: edit_contract.buyer_address ? edit_contract.buyer_address :  "",
            buyer_city: edit_contract.buyer_city ? edit_contract.buyer_city :  "",
            buyer_state: edit_contract.buyer_state ? edit_contract.buyer_state :  "",
            buyer_zip: edit_contract.buyer_zip ? edit_contract.buyer_zip :  "",
            buyer_email: edit_contract.buyer_email ? edit_contract.buyer_email :  "",
            buyer_phone: edit_contract.buyer_phone ? edit_contract.buyer_phone :  ""
        }
        setFormData((prevState) => ( prevState, {...x}))
        
        if(edit_contract && edit_contract.id){
            if(edit_contract.created_by_user_id == user.id){
                setIsEditable(true);
            }
        }
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Buyer Information</h1>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                        {edit_contract && edit_contract.id && 
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField
                                    required
                                    id="buyer_name" 
                                    label="Name" 
                                    size="small" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    value={formData.buyer_name} 
                                    name="buyer_name" 
                                    InputProps={{
                                        disabled: edit_contract && edit_contract.id ? true : false,
                                    }}
                                >
                                </TextField>
                            </FormControl>
                        </div>
                        }
                        
                        
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField
                                    required
                                    id="buyer_email" 
                                    label="Email" 
                                    type='email'
                                    size="small" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    value={formData.buyer_email} 
                                    name="buyer_email" 
                                    InputProps={{
                                        disabled: edit_contract && edit_contract.id ? true : false,
                                    }}
                                >
                                </TextField>
                            </FormControl>
                        </div>

                        {edit_contract && edit_contract.id && 
                        <>
                        {/* <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl id="disabled_click_on_flag">
                                <MuiPhoneNumber 
                                    id="buyer_phone" 
                                    onlyCountries={onlyCountriesAllowed}
                                    defaultCountry={'us'} 
                                    onChange={onChangePhoneNumber}  
                                    variant="outlined" 
                                    label="Phone" 
                                    size="small" 
                                    value={formData.buyer_phone} 
                                    InputProps={{
                                        disabled: isEditable,
                                    }}
                                />
                            </FormControl>
                        </div> */}

                        {!isEditable &&
                            <>
                            <div className="flex flex-col w-full gap-2 mb-2 address_auto_complete">
                                <AddressValidationForm disabled={isEditable} address={formData.buyer_address_full} cb={(address) => {
                                    setFormData(prevState => ({
                                        ...prevState,
                                        ['buyer_address_full']: address.address_full,
                                        ['buyer_address']: address.address1,
                                        ['buyer_state']: address.countryLong,
                                        ['buyer_zip']: address.postalCode,
                                        ['buyer_city']: address.locality,
                                    }));
                                    setSelectedAddress({...address})
                                }} />
                                
                            </div>
                            <div className="flex flex-col w-full gap-2 mb-2">
                                <FormControl>
                                    <TextField
                                        required
                                        id="buyer_address" 
                                        label="Address" 
                                        size="small" 
                                        onChange={handleInputChanged}  
                                        variant="outlined" 
                                        value={formData.buyer_address} 
                                        name="buyer_address" 
                                        InputProps={{
                                            disabled: isEditable,
                                        }} 
                                    >
                                    </TextField>
                                </FormControl>
                            </div>

                            <div className="flex flex-col w-full gap-2 mb-2">
                                <FormControl>
                                    <TextField
                                        required
                                        id="buyer_city" 
                                        label="City" 
                                        size="small" 
                                        onChange={handleInputChanged}  
                                        variant="outlined" 
                                        value={formData.buyer_city} 
                                        name="buyer_city"  
                                        InputProps={{
                                            disabled: isEditable,
                                        }}
                                    >
                                    </TextField>
                                </FormControl>
                            </div>
                            <div className="flex gap-2  mb-2">
                                <div className="flex flex-col w-1/2 gap-2">
                                    <FormControl>
                                        <TextField    
                                            required
                                            id="buyer_state" 
                                            label="State" 
                                            size="small" 
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.buyer_state} 
                                            name="buyer_state"  
                                            InputProps={{
                                                disabled: isEditable,
                                            }}
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                                <div className="flex flex-col w-1/2 gap-2 mb-2">
                                    <FormControl>
                                        <TextField    
                                            required
                                            id="buyer_zip" 
                                            label="Zip" 
                                            size="small" 
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.buyer_zip} 
                                            name="buyer_zip"  
                                            InputProps={{
                                                disabled: isEditable,
                                            }}
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>
                            </>
                        }
                        
                        </>
                        }
                        <div className="flex justify-between">
                            <div className='btn btn-primary-outline ' onClick={() => {
                                dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
                                    state : 'seller'
                                }});
                            }} >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.57129 2.64285L3.21415 8L8.57129 13.3571L7.49986 15.5L-0.000140326 8L7.49986 0.499996L8.57129 2.64285Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </div>
                            <button className='btn btn-primary-outline ' type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        
        <Dialog
                open={cantSelectThisAddress}
                onClose={() => {setCantSelectThisAddress(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Please wait!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You can not select address other then <b>Minnesota</b>. Please pick the address again. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setCantSelectThisAddress(false)}} >Edit</Button>
                </DialogActions>
            </Dialog>
    </>
    )
}

export default IsSeller_WizardContractBuyer;