import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GET_ACCOUNTS } from "./../../../../redux/actions/accounts.action";
import {
  A_CONTRACT_ACTIVE_STATE,
  DATA_SINGLE_CONTRACT,
} from "./../../../../redux/actions/contracts.action";
import { Autocomplete } from "@mui/material";
import AddressValidationForm from "../../../AccountsPage/components/AddressValidationForm";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function IsSeller_WizardContractSeller() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const edit_contract = useSelector((store) => store.contracts.edit_contract);
  const accounts = useSelector((store) => store.accounts);

  const [isEditable, setIsEditable] = useState(false);
  const [activeAddress, setActiveAddress] = useState(-1);
  const [address, setAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cantSelectThisAddress, setCantSelectThisAddress] = useState(false);

  const [formData, setFormData] = useState({
    seller_name: "",
    seller_address_full: "",
    seller_address: "",
    seller_city: "",
    seller_state: "",
    seller_zip: "",
    seller_email: "",
    seller_phone: "",
  });

  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (
      selectedAddress != null &&
      selectedAddress.adminArea1Long &&
      selectedAddress.adminArea1Long.toLowerCase() != "minnesota"
    ) {
      setCantSelectThisAddress(true);
      return false;
    }
    dispatch({ type: DATA_SINGLE_CONTRACT, payload: formData });
    dispatch({
      type: A_CONTRACT_ACTIVE_STATE,
      payload: {
        state: "buyer",
      },
    });
  };

  useEffect(() => {
    var x = {
      seller_name: edit_contract.seller_name
        ? edit_contract.seller_name
        : user.legal_name,
      seller_address_full: edit_contract.seller_address_full
        ? edit_contract.seller_address_full
        : user && user.address_full
        ? user.address_full
        : "",
      seller_address: edit_contract.seller_address
        ? edit_contract.seller_address
        : user && user.address_line1
        ? user.address_line1
        : "",
      seller_city: edit_contract.seller_city
        ? edit_contract.seller_city
        : user && user.address_city
        ? user.address_city
        : "",
      seller_state: edit_contract.seller_state
        ? edit_contract.seller_state
        : user && user.address_state
        ? user.address_state
        : "",
      seller_zip: edit_contract.seller_zip
        ? edit_contract.seller_zip
        : user && user.address_zip
        ? user.address_zip
        : "",
      seller_email: edit_contract.seller_email
        ? edit_contract.seller_email
        : user.email,
      seller_phone: edit_contract.seller_phone
        ? edit_contract.seller_phone
        : user.phone,
    };
    setFormData((prevState) => (prevState, { ...x }));

    if (edit_contract && edit_contract.id) {
      if (
        edit_contract.created_by_user_id &&
        edit_contract.created_by_user_id == user.id
      ) {
        /* Is Seller */
        setIsEditable(false);
      } else {
        /* Is Buyer */
        setIsEditable(true);
      }
    }
  }, []);

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="template-card pt-10 pb-10 ps-14 pe-14 bg-white">
          <div className="flex justify-between gap-3">
            <h1 className="text-2xl font-bold text-hepler">
              Seller Information
            </h1>
          </div>

          <form className="w-full rounded-md mt-4" onSubmit={submitForm}>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col w-full gap-2 mb-2">
                <FormControl style={{ minWidth: "100px" }}>
                  <TextField
                    style={{ minWidth: "100px" }}
                    required
                    id="seller_name"
                    label="Name"
                    size="small"
                    onChange={handleInputChanged}
                    variant="outlined"
                    value={formData.seller_name}
                    name="seller_name"
                    InputProps={{
                      disabled: isEditable,
                    }}
                  ></TextField>
                </FormControl>
              </div>

              <div className="flex flex-col w-full gap-2 mb-2">
                <FormControl>
                  <TextField
                    required
                    id="seller_email"
                    label="Email"
                    type="email"
                    size="small"
                    onChange={handleInputChanged}
                    variant="outlined"
                    value={formData.seller_email}
                    name="seller_email"
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                  ></TextField>
                </FormControl>
              </div>

              {!isEditable && (
                <>
                  <div className="flex flex-col w-full gap-2 mb-2 address_auto_complete">
                    <AddressValidationForm
                      disabled={isEditable}
                      address={formData.seller_address_full}
                      cb={(address) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          ["seller_address_full"]: address.address_full,
                          ["seller_address"]: address.address1,
                          ["seller_state"]: address.countryLong,
                          ["seller_zip"]: address.postalCode,
                          ["seller_city"]: address.locality,
                        }));
                        console.log("address", address);
                        setSelectedAddress({ ...address });
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full gap-2 mb-2">
                    <FormControl>
                      <TextField
                        required
                        id="seller_city"
                        label="City"
                        size="small"
                        onChange={handleInputChanged}
                        variant="outlined"
                        value={formData.seller_city}
                        name="seller_city"
                        InputProps={{
                          disabled: isEditable,
                        }}
                      ></TextField>
                    </FormControl>
                  </div>
                  <div className="flex gap-2  mb-2">
                    <div className="flex flex-col w-1/2 gap-2">
                      <FormControl>
                        <TextField
                          required
                          id="seller_state"
                          label="State"
                          size="small"
                          onChange={handleInputChanged}
                          variant="outlined"
                          value={formData.seller_state}
                          name="seller_state"
                          InputProps={{
                            disabled: isEditable,
                          }}
                        ></TextField>
                      </FormControl>
                    </div>
                    <div className="flex flex-col w-1/2 gap-2 mb-2">
                      <FormControl>
                        <TextField
                          required
                          id="seller_zip"
                          label="Zip"
                          size="small"
                          onChange={handleInputChanged}
                          variant="outlined"
                          value={formData.seller_zip}
                          name="seller_zip"
                          InputProps={{
                            disabled: isEditable,
                          }}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                </>
              )}

              <div className="flex justify-end">
                <button className="btn btn-primary-outline " type="submit">
                  <div className="flex  gap-2 items-center justify-center">
                    <svg
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z"
                        fill="#6622CC"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={cantSelectThisAddress}
        onClose={() => {
          setCantSelectThisAddress(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Please wait!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can not select address other then <b>Minnesota</b>. Please pick
            the address again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantSelectThisAddress(false);
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default IsSeller_WizardContractSeller;
