import {
    put,
    call,
    takeLatest,
    takeEvery
  } from 'redux-saga/effects'

  import { 
    SET_LOADING,
    UNSET_LOADING,
    USER_UPDATE_GENERAL_INFO,
    USER_UPDATE_PROFILE_PIC_INFO,
    USER_UPDATE_SECRUTIY_INFO,
    USER_UPDATE_ADDRESS_INFO,
    USER_UPDATE_PROFILE_VERIFICATION,
    SET_USER
  } from '../actions/user.action'

  import { 
    CLEAR_SNACKBAR,
    SET_SNACKBAR
  } from '../actions/message.action'

  import UserServices from "../api/user.api";


// Geneal Info update
function* userGeneralUpdate({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(UserServices.settings_user_profile_general, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_USER , payload: x.data.data.user })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'User detail has bee updated'} })
  }
}

// Update Profile Picture Info update
function* userProfilePictureUpdate({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(UserServices.settings_user_profile_general, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_USER , payload: x.data.data.user })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Profile Picture has been updated'} })
  }
}

// Update Address Info update
function* userAddressUpdate({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(UserServices.settings_address_details, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_USER , payload: x.data.data.user })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'User address has bee updated'} })
  }
}

// Update Profile Verification
function* userUpdatProfileVerification({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(UserServices.user_profile_verification, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.user));
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_USER , payload: x.data.user })
    yield put({ type: SET_SNACKBAR , payload :  {state : true , message: 'User Profile Verification has been submited. Please wait we will get back to you in 1 working day.'} })
  }
}

// Update Security Info update
function* userSecurityUpdate({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(UserServices.settings_security_details, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_USER , payload: x.data.data.user })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'User password has bee updated'} })
  }
}

// Export the saga  
export default function* userSaga() {
  yield takeEvery(USER_UPDATE_GENERAL_INFO, userGeneralUpdate)
  yield takeEvery(USER_UPDATE_PROFILE_PIC_INFO, userProfilePictureUpdate)
  yield takeEvery(USER_UPDATE_SECRUTIY_INFO, userSecurityUpdate)
  yield takeEvery(USER_UPDATE_ADDRESS_INFO, userAddressUpdate)
  yield takeEvery(USER_UPDATE_PROFILE_VERIFICATION, userUpdatProfileVerification)
}