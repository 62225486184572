import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import { ACCOUNT_BANK, BELL_ICON } from "../../helper/assets";
import Parser from "html-react-parser";
import SettingsGeneralTab from "./SettingsGeneralTab";
import SettingsSecurityTab from "./SettingsSecurityTab";
import SettingsAddressTab from "./SettingsAddressTab";
import { SET_SUCCESS_MESSAGE } from "../../redux/actions/types";
import AuthService from "./../../redux/api/auth.api";
import { distancematrix } from "@googlemaps/google-maps-services-js/dist/distance";
import {
  PROFILE_FETCH,
  SET_USER,
  UPDATE_USER,
} from "../../redux/actions/auth.action";

function SettingPage() {
  const pathSearch = useLocation().search;
  const history = useHistory();
  const dispatch = useDispatch();
  const userOjb = useSelector((store) => store.auth.user);

  const [tabs, setTabs] = useState("");

  /* const tabHandler = (tab_id) => {
    setTabs(tab_id);
  } */

  useEffect(() => {
    /* AuthService.profile().then( (x) => {
      //dispatch({type: UPDATE_USER , payload: x.data.data.user})
    }) */

    var px = new URLSearchParams(pathSearch);
    var get_tab_by_url = px.get("tab");
    if (get_tab_by_url) {
      setTabs(get_tab_by_url);
    }
  }, [dispatch, tabs]);

  return (
    <Container className="container-area">
      <div className="flex flex-col items-center pt-40 h-full">
        <h1 className="text-[4rem] font-bold text-primary">Settings</h1>
        <p className="text-base text-[#5E6368]">
          Edit your profile Details here. Edit your profile Details here. Edit
          your profile Details here.
        </p>
        <div className="tabs">
          <div className="tab-header">
            <ul>
              <li className={tabs == "general" ? "acitve" : ""}>
                <Link to="/settings?tab=general">General</Link>
              </li>
              <li className={tabs == "security" ? "acitve" : ""}>
                <Link to="/settings?tab=security">Security</Link>
              </li>
              <li
                className={
                  `${
                    /* userOjb.stripe_account_verified ? "" : "disabled-tab-btn" */
                    userOjb.stripe_account_verified ? "" : ""
                  } ` + (tabs == "address" ? "acitve" : "")
                }
              >
                <Link to="/settings?tab=address">Address</Link>
              </li>
              {/* <li className={tabs == 'privacy' ? 'acitve' : ''} onClick={() => {tabHandler('general')}}>Privacy</li>
                  <li className={tabs == 'accessibilty' ? 'acitve' : ''} onClick={() => {tabHandler('general')}}>Accessibilty</li> */}
            </ul>
          </div>
          <div className="bg-white w-full xl:w-[50rem] px-16 py-8 ">
            {tabs == "general" && (
              <div className="tab-content">
                <SettingsGeneralTab />
              </div>
            )}

            {tabs == "security" && (
              <div className="tab-content">
                <SettingsSecurityTab />
              </div>
            )}

            {tabs == "address" && (
              <div className="tab-content">
                <SettingsAddressTab />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
export default SettingPage;
