
import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { VERIFICATION_PLAIN, VERIFICATION_VERFIED } from '../../../helper/assets';
import Parser from 'html-react-parser';

function ContractHandoffContentTemplate ({seller , sellerphotos , buyer , buyerphotos}) {

    const verifyPhotos = (photos) => {
        var x = photos ? photos.length : 0;
        if(x){
            for(var i = 0 ; i < x ; i++){
                if(photos[i].item_image){
                    return true;
                }
            }
        }
        
        return false;
    }
    

    return (
        <div className="flex flex-direction-column handoff-checklist">
            <h3 className='text-2xl'>Handoff Content</h3>
            <hr className='mb-5 mt-2' />
            <div className="grid grid-cols-2 gap-10 ">  
                <div className="flex  handoff-single">
                <span className='title'>Seller</span>
                
                {seller ?
                    <div className="flex flex-col w-full gap-2 mb-2">
                        <div className="flex gap-5">
                            <div className="checklist">
                                {
                                        (() => {
                
                                        if(
                                            seller.physical_inspection &&
                                            seller.working_condition &&
                                            seller.sr_no_or_id_code_state &&
                                            seller.sr_no_or_id_code != ''
                                        ){
                                            return Parser(VERIFICATION_VERFIED);
                                        }else{
                                            return Parser(VERIFICATION_PLAIN);
                                        }
                                    })()
                                }
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <h3 className='text-base font-bold'>Checklist Completed</h3>
                                <ul className='verification_handoff_list'>
                                    <li>{seller.physical_inspection ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)} <span>Physical Inspection</span></li>
                                    <li>{seller.working_condition ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)}Working Condition</li>
                                    <li>{seller.sr_no_or_id_code_state && seller.sr_no_or_id_code != '' ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)}Serial Number or ID Code: {seller.sr_no_or_id_code}</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex gap-5 mt-5">
                            <div className="checklist">
                                {
                                        (() => {
                
                                        if(
                                            sellerphotos && sellerphotos.length > 0
                                        ){
                                            return Parser(VERIFICATION_VERFIED);
                                        }else{
                                            return Parser(VERIFICATION_PLAIN);
                                        }
                                    })()
                                }
                            </div>
                            
                            {sellerphotos && 
                            <div className="flex flex-col gap-3">
                                <h3 className='text-base font-bold'>Photos</h3>
                                <div className="grid grid-cols gap-3">
                                    {sellerphotos.map((item, index) => (
                                        <div key={index}>
                                        {item.item_image && (
                                            <div className="photo_added">
                                                <img src={item.item_image} alt={item.item_image_description} />
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="not-yet">
                        Not Handoff yet.
                    </div>
                    }
                </div>


                <div className="flex  handoff-single">
                <span className='title'>Buyer</span>
                {buyer ?
                    <div className="flex flex-col w-full gap-2 mb-2">
                        <div className="flex gap-5">
                            <div className="checklist">
                                {
                                        (() => {
                
                                        if(
                                            buyer.physical_inspection &&
                                            buyer.working_condition &&
                                            buyer.sr_no_or_id_code_state &&
                                            buyer.sr_no_or_id_code != ''
                                        ){
                                            return Parser(VERIFICATION_VERFIED);
                                        }else{
                                            return Parser(VERIFICATION_PLAIN);
                                        }
                                    })()
                                }
                            </div>
                            
                            <div className="flex flex-col gap-3">
                                <h3 className='text-base font-bold'>Checklist Completed</h3>
                                <ul className='verification_handoff_list'>
                                    <li>{buyer.physical_inspection ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)} <span>Physical Inspection</span></li>
                                    <li>{buyer.working_condition ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)}Working Condition</li>
                                    <li>{buyer.sr_no_or_id_code_state && buyer.sr_no_or_id_code != '' ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)}Serial Number or ID Code: {buyer.sr_no_or_id_code}</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex gap-5 mt-5">
                            <div className="checklist">
                                {
                                        (() => {
                
                                        if(
                                            buyerphotos && buyerphotos.length > 0
                                        ){
                                            return Parser(VERIFICATION_VERFIED);
                                        }else{
                                            return Parser(VERIFICATION_PLAIN);
                                        }
                                    })()
                                }
                            </div>
                            
                            {buyerphotos && 
                            <div className="flex flex-col gap-3">
                                <h3 className='text-base font-bold'>Photos</h3>
                                <div className="grid grid-cols gap-3">
                                    {buyerphotos.map((item, index) => (
                                        <div key={index}>
                                        {item.item_image && (
                                            <div className="photo_added">
                                                <img src={item.item_image} alt={item.item_image_description} />
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="not-yet">
                        Not Handoff yet.
                    </div>
                    }
                </div>
            </div>
        </div>
        
        
    );
}

export default ContractHandoffContentTemplate;
