import { spawn } from 'redux-saga/effects'

// Sagas
/* import messageSaga from './message.saga' */
import authSaga from './auth.saga'
import userSaga from './user.saga'
import accountsSaga from './accounts.saga'
import contractsSaga from './contracts.saga'

// Export the root saga
export default function* rootSaga() {
  console.log("Hello From Redux-Saga!")

  /* yield spawn(messageSaga) */
  yield spawn(authSaga)
  yield spawn(userSaga)
  yield spawn(accountsSaga)
  yield spawn(contractsSaga)
}