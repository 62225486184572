import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { DATA_HANDOFF_CONTRACT } from '../../../redux/actions/contracts.action';



function WizardContractConfirmation () {

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const handoff_contract_detail = useSelector((store) => store.contracts.handoff_contract_detail);
    const activeState = useSelector((store) => store.contracts.handoff_active_state);

    const [disabled, setDisabled] = useState(false);

    const [formData, setFormData] = useState({
        seller_exhange_confrim : false,
        buyer_exhange_confrim : false,
    });

    const handleInputChanged = (e) => {
        const { name, value } = e.target;    
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    const submitForm = (event) => {
        event.preventDefault();

        dispatch({ type: DATA_HANDOFF_CONTRACT , payload : formData});
        dispatch({ type: A_HANDOFF_ACTIVE_STATE , payload : {
            state : 'buyer_confirm'
        }});
    }

    
    useEffect(() => {
        var x = {
            seller_exhange_confrim: handoff_contract_detail.seller_exhange_confrim ? handoff_contract_detail.seller_exhange_confrim :  false,
            buyer_exhange_confrim: handoff_contract_detail.buyer_exhange_confrim ? handoff_contract_detail.buyer_exhange_confrim :  false
        }
        setFormData((prevState) => ( prevState, {...x}))
        
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Confirmation</h1>
                <p className='text-base'>Item Exchange Confirmed. Make sure the other party in this contract also completes the Item Exchange Confirmation process.</p>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col w-full gap-2 mb-2">
                                {activeState}
                        </div>
                        <div className="flex justify-between">
                            <Link to={`/contract/view/${params.id}`} className='btn btn-primary-light '>
                                <div className='flex  gap-2 items-center justify-center'>
                                    Return to contract
                                </div>
                            </Link>
                            {/* <button className='btn btn-primary' disabled type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                   Write a review
                                </div>
                            </button> */}
                        </div>
                    </div>
                </form>
            </div>
      </div>
    </>
    )
}

export default WizardContractConfirmation;