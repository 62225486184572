
function LoaderUI() {
    return (    
        <>
            <div className="load-container one">
                <div className="loader">Loading...</div>
            </div>
        </>
    )
}


export default LoaderUI;

