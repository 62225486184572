import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import bannerImage1 from "./../../assets/homepage-banner-bg-1.jpg";
import opacityImage from "./../../assets/login/2.png";
import Input from "./../common/forms/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SET_RESPONSE, CLEAR_RESPONSE } from "../../redux/actions/types";
import {
  otp_verification,
  otp_resend,
  AUTH_OTP_RESEND,
  AUTH_OTP_VERIFY,
} from "../../redux/actions/auth.action";
import LoaderUI from "../common/Loader";
import AlertBox from "../common/AlertBox";
import AuthService from "../../redux/api/auth.api";
import { x64 } from "crypto-js";
import { APP_LOGO } from "../../helper/assets";
import { Button } from "../Button/Button";

function ForgotPasswordPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [state, setState] = useState("not_sent");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const formHandler = (event) => {
    event.preventDefault();

    AuthService.reset_password_link(formData).then((x) => {
      if (x.status == 200) {
        setState("sent");
      } else {
        setMessage(
          x.data.errors && x.data.errors[0] && x.data.errors[0].title
            ? x.data.errors[0].title
            : "Unable to send you the reset password link. Please contact admin."
        );
      }
    });
  };

  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      {/* <div
        id="page-password-reset"
        className="h-screen root-page-bg-style"
        style={{
          backgroundImage: `url(${bannerImage1})`,
          backgroundSize: `contain`,
          backgroundPosition: `right center`,
        }}
      >
        <div className="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto p-4 h-screen">
          <div className="w-full flex justify-between flex-col  main-page-group">
            <h1 className="text-6xl mb-3">Forgot password</h1>
            <p className="text-xl mb-3">
              Enter email address associated wiht your account and we will send
              you password reset link.
            </p>

            <div className="w-full mt-10 flex justify-center">
              <div className="w-full regsiter-form">
                {state == "not_sent" ? (
                  <form
                    className="w-full rounded-md bg-primary-light p-10"
                    onSubmit={formHandler}
                  >
                    <div className="flex gap-3 w-full">
                      <div className="w-full left flex flex-col gap-3">
                        {message != "" && (
                          <h3 className="alert" role="alert">
                            {message}
                          </h3>
                        )}

                        <div className="w-full text-center flex justify-center">
                          <div className="w-2/3">
                            <Input
                              type="email"
                              className="rounded-md"
                              placeholder="Email *"
                              value={formData.email}
                              name="email"
                              required
                              disabled={disabled ? "disabled" : ""}
                              onChange={handleInputChanged}
                              icon='<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.21 11.761C14.21 12.601 14.449 12.936 15.074 12.936C16.467 12.936 17.354 11.161 17.354 8.209C17.354 3.697 14.066 1.537 9.961 1.537C5.738 1.537 1.897 4.369 1.897 9.721C1.897 14.833 5.257 17.617 10.417 17.617C12.169 17.617 13.345 17.425 15.144 16.825L15.53 18.432C13.754 19.009 11.856 19.176 10.393 19.176C3.625 19.176 0 15.456 0 9.72C0 3.936 4.201 0 9.985 0C16.009 0 19.2 3.6 19.2 8.016C19.2 11.76 18.025 14.616 14.329 14.616C12.648 14.616 11.545 13.944 11.401 12.455C10.969 14.111 9.817 14.616 8.256 14.616C6.168 14.616 4.416 13.007 4.416 9.768C4.416 6.504 5.953 4.488 8.713 4.488C10.177 4.488 11.089 5.064 11.495 5.976L12.192 4.704H14.208V11.761H14.21V11.761ZM11.259 8.593C11.259 7.274 10.274 6.721 9.458 6.721C8.57 6.721 7.587 7.44 7.587 9.553C7.587 11.233 8.331 12.169 9.458 12.169C10.25 12.169 11.259 11.665 11.259 10.273V8.593Z" fill="#6622CC"/></svg>'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full text-center flex justify-end mt-8">
                      <button
                        type="subimt"
                        className="bg-primary p-3 text-white rounded-md w-1/4 float-right flex gap-2 items-center justify-center"
                      >
                        {loading ? (
                          <LoaderUI />
                        ) : (
                          <div className="flex  gap-2 items-center justify-center">
                            <span className="font-bold">Send link</span>
                            <svg
                              width="8"
                              height="16"
                              viewBox="0 0 10 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="w-full rounded-md bg-primary-light p-10">
                    <p className="text-white p-14 text-center text-xl">
                      Password reset link has been sent to {formData.email}{" "}
                      account. Please check your email.
                    </p>
                  </div>
                )}

                <div className="mt-4 flex gap-2 items-center text-bold">
                  <span>Want to login?</span>
                  <Link to="/">
                    <b>Click here</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="page-login"
        className="h-screen root-page-bg-style"
        style={{
          backgroundImage: `url(${bannerImage1})`,
          backgroundSize: `contain`,
          backgroundPosition: `right center`,
        }}
      >
        <div className="flex h-full py-12 md:py-32  max-w-screen-xl  mx-auto px-4">
          <div className="flex bg-[#F6F6F6]">
            <div className="w-1/2 lg:block hidden relative">
              <img
                src={opacityImage}
                alt=""
                className="absolute w-full h-full top-0 left-0"
              />

              <div className="relative flex flex-col items-center text-center mt-20">
                <Link to="/">{APP_LOGO}</Link>
                <h1 className="text-5xl mb-8 leading-tight mt-5">
                  Forgot password
                </h1>
              </div>
            </div>
            <div className="w-full lg:w-1/2 bg-[#F6F6F6] flex lg:items-center">
              <div className="w-full p-10 overflow-auto">
                <div className="lg:hidden  relative flex flex-col items-center text-center">
                  <Link to="/">{APP_LOGO}</Link>
                  <h1 className="text-2xl sm:text-3xl md:text-4xl  mb-8 leading-tight mt-5">
                    Forgot password
                  </h1>
                </div>
                <p className="text-xl mb-3">
                  Enter the email associated with your account to receive a
                  password reset link.
                </p>
                {state == "not_sent" ? (
                  <form onSubmit={formHandler}>
                    <div className="w-full flex flex-col gap-3">
                      {message != "" && (
                        <h3 className="alert" role="alert">
                          {message}
                        </h3>
                      )}
                      <Input
                        type="email"
                        className="rounded-md"
                        placeholder="Email *"
                        value={formData.email}
                        name="email"
                        required
                        disabled={disabled ? "disabled" : ""}
                        onChange={handleInputChanged}
                        label="Email address"
                        icon='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14.21 11.761C14.21 12.601 14.449 12.936 15.074 12.936C16.467 12.936 17.354 11.161 17.354 8.209C17.354 3.697 14.066 1.537 9.961 1.537C5.738 1.537 1.897 4.369 1.897 9.721C1.897 14.833 5.257 17.617 10.417 17.617C12.169 17.617 13.345 17.425 15.144 16.825L15.53 18.432C13.754 19.009 11.856 19.176 10.393 19.176C3.625 19.176 0 15.456 0 9.72C0 3.936 4.201 0 9.985 0C16.009 0 19.2 3.6 19.2 8.016C19.2 11.76 18.025 14.616 14.329 14.616C12.648 14.616 11.545 13.944 11.401 12.455C10.969 14.111 9.817 14.616 8.256 14.616C6.168 14.616 4.416 13.007 4.416 9.768C4.416 6.504 5.953 4.488 8.713 4.488C10.177 4.488 11.089 5.064 11.495 5.976L12.192 4.704H14.208V11.761H14.21ZM11.259 8.593C11.259 7.274 10.274 6.721 9.458 6.721C8.57 6.721 7.587 7.44 7.587 9.553C7.587 11.233 8.331 12.169 9.458 12.169C10.25 12.169 11.259 11.665 11.259 10.273V8.593Z" fill="#76BD6D"/>
                              </svg>'
                      />
                    </div>
                    <Button isLoading={loading} type={'submit'} className="mt-6 w-full">
                      <div className="flex gap-2 items-center justify-center">
                        <span className="font-bold">Send link</span>
                        <svg
                          width="8"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </Button>
                  </form>
                ) : (
                  <div className="w-full rounded-md bg-primary-light p-10">
                    <p className="text-white p-14 text-center text-xl">
                      Password reset link has been sent to {formData.email}{" "}
                      account. Please check your email.
                    </p>
                  </div>
                )}

                <div className="mt-4 flex gap-2 items-center text-bold">
                  <span>Want to login?</span>
                  <Link to="/">
                    <b>Click here</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
