import React from 'react';
import LoginForm from '../LoginForm/LoginForm';
import { Link , useHistory } from 'react-router-dom';
import bannerImage1 from './../../assets/Untitled-2.png'
import HomepageLoginFormGroup from '../common/HomepageLoginFormGroup'
// This is one of our simplest components
// It doesn't have local state,
// It doesn't dispatch any redux actions or display any part of redux state
// or even care what the redux state is'

function OverviewPage() {
  return (
    <>
      <div className='h-100-p root-page-bg-style ' style={{backgroundImage:`url(${bannerImage1})`}}>
        <div className="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto p-4 h-100-p " >
          <div className="w-full flex justify-between gap-5 h-100-p">
            <HomepageLoginFormGroup className="w-2/5" />
            <div className='w-3/5  right-container flex flex-col content-center justify-center '>
              <div className='w-4/6 flex flex-col gap-3 ps-8'>
                  <p className='mb-4 text-xl'>Whether you’re adding treasures to your collection or selling an old car, TradeOut has a library of templates to make the process as simple as possible.</p>
                  <p className='text-xl'>TradeOut’s contracts are real legal documents, providing an exportable record of all your major sales and purchases, and giving you recourse if anything goes wrong.</p>
              </div>            
            </div>
          </div> 
        </div>
      </div>
    </>
  );
}

export default OverviewPage;
