import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';
import LoaderUI from '../common/Loader';
import { USER_UPDATE_ADDRESS_INFO } from '../../redux/actions/user.action';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import AddressValidationForm from '../../components/AccountsPage/components/AddressValidationForm';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function SettingsAddressTab() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cantSelectThisAddress, setCantSelectThisAddress] = useState(false);

  const [formData, setFormData] = useState({
    address_full : '',
    address_line1 : '',
    address_line2 : '' ,
    address_city : '',
    address_country : '',
    address_state : '',
    address_zip : ''
  });

  const handleInputChanged = (e) => {
    const { name, value } = e.target;    
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const joinAddress = (obj) => {
    return `${obj.address_line1} ${obj.address_line2} ${obj.address_line1} ${obj.address_city} ${obj.address_country} ${obj.address_state} ${obj.address_zip}`;
  }

  const updateAddress = (event) => {
    event.preventDefault();

    let tagetArress = "minnesota";
    if(
      !joinAddress(formData).toLowerCase().includes(tagetArress)
    ){
      setCantSelectThisAddress(true);
      return false;
    }

    let x = {...formData};

    //if(x.address_full == '') x.address_full = joinAddress(x)
    dispatch({type: USER_UPDATE_ADDRESS_INFO ,  payload: x})
  };

  useEffect(() => {
   /*  dispatch(clearMessage())  */
    var x = {
      address_full : user.address_full ? user.address_full : "",
      address_line1 : user.address_line1 ? user.address_line1 : "",
      address_line2 : user.address_line2 ? user.address_line2 : "",
      address_city : user.address_city ? user.address_city : "",
      address_country : user.address_country ? user.address_country : "",
      address_state : user.address_state ? user.address_state : "",
      address_zip : user.address_zip ? user.address_zip : ""
    }
    setFormData((prevState) => ( prevState, {...x}))
    
  }, []);


  return (
    <>
      <div className="flex flex-col">
        <h2 className='text-xl text-dark font-bold mb-3'>Address & Location</h2>
        <div className="flex gap-5 mt-5">
          <div className="form-area w-full flex justify-center w-1/2">
            <form className="w-full rounded-md" onSubmit={updateAddress} >
              <div className="flex gap-3 w-full">
                <div className="w-full left flex flex-col gap-3">

                    <div className="flex flex-col w-full gap-2 mb-2 address_auto_complete">
                        <AddressValidationForm address={formData.address_full} cb={(address) => {
                            console.log('address',address)
                            if(address.address_full){
                                setFormData(prevState => ({
                                  ...prevState, 
                                  ['address_full']: address.address_full,
                                  ['address_line1']: address.address1,
                                  ['address_country']: address.countryLong,
                                  ['address_state']: address.countryLong,
                                  ['address_zip']: address.postalCode,
                                  ['address_city']: address.locality,
                                }));
                                setSelectedAddress({...address})
                            }else{
                              setFormData(prevState => ({
                                ...prevState,
                                ['address_full']: '',
                                ['address_line1']: '',
                                ['address_country']: '',
                                ['address_state']: '',
                                ['address_zip']: '',
                                ['address_city']: '',
                              }));
                              setSelectedAddress({...address})
                            }
                            
                        }} />
                    </div>
                    
                   <div className="flex flex-col w-full gap-2 mb-2">
                        <FormControl>
                            <TextField 
                                disabled={user.loading}
                                id="address_line1" 
                                label="Address 1" 
                                size="small" 
                                onChange={handleInputChanged}  
                                variant="outlined" 
                                value={formData.address_line1} 
                                name="address_line1"  
                            >
                            </TextField>
                        </FormControl>
                    </div>
                    <div className="flex flex-col w-full gap-2 mb-2">
                        <FormControl>
                            <TextField 
                                disabled={user.loading}
                                id="address_line2" 
                                label="Address 2" 
                                size="small" 
                                onChange={handleInputChanged}  
                                variant="outlined" 
                                value={formData.address_line2} 
                                name="address_line2"  
                            >
                            </TextField>
                        </FormControl>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div className="flex flex-col w-full gap-2 mb-2">
                          <FormControl>
                              <TextField 
                                  disabled={user.loading}
                                  id="address_city" 
                                  label="City" 
                                  size="small" 
                                  onChange={handleInputChanged}  
                                  variant="outlined" 
                                  value={formData.address_city} 
                                  name="address_city"  
                              >
                              </TextField>
                          </FormControl>
                      </div>

                      <div className="flex flex-col w-full gap-2 mb-2">
                          <FormControl>
                              <TextField 
                                  disabled={user.loading}
                                  id="address_country" 
                                  label="Country" 
                                  size="small" 
                                  onChange={handleInputChanged}  
                                  variant="outlined" 
                                  value={formData.address_country} 
                                  name="address_country"  
                              >
                              </TextField>
                          </FormControl>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      <div className="flex flex-col w-full gap-2 mb-2">
                          <FormControl>
                              <TextField 
                                  disabled={user.loading}
                                  id="address_state" 
                                  label="State" 
                                  size="small" 
                                  onChange={handleInputChanged}  
                                  variant="outlined" 
                                  value={formData.address_state} 
                                  name="address_state"  
                              >
                              </TextField>
                          </FormControl>
                      </div>

                      <div className="flex flex-col w-full gap-2 mb-2">
                          <FormControl>
                              <TextField 
                                  disabled={user.loading}
                                  id="address_zip" 
                                  label="Zip" 
                                  size="small" 
                                  onChange={handleInputChanged}  
                                  variant="outlined" 
                                  value={formData.address_zip} 
                                  name="address_zip"  
                              >
                              </TextField>
                          </FormControl>
                      </div>
                    </div>
                </div>
              </div>
              <div className='w-full text-center flex justify-end mt-8'>
                <button type='submit' className='btn btn-primary float-right flex gap-2 items-center justify-center'>
                  {user.loading ?  
                      <LoaderUI />
                      :
                      <div className='flex  gap-2 items-center justify-center'>
                        <span className=''>update</span> 
                      </div>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Dialog
          open={cantSelectThisAddress}
          onClose={() => {setCantSelectThisAddress(false)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
              Please wait!
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  You can not select address other then <b>Minnesota</b>. Please pick the address again. 
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {setCantSelectThisAddress(false)}} >Edit</Button>
          </DialogActions>
      </Dialog>
    </>
  );
}
export default SettingsAddressTab;