import {
    put,
    call,
    takeLatest,
    takeEvery
  } from 'redux-saga/effects'

  import { 
    SET_LOADING,
    UNSET_LOADING,
    GET_ACCOUNTS,
    ADD_STRIPE_CC_ACCOUNTS,
    DELETE_STRIPE_CC_ACCOUNTS,
    ADD_STRIPE_BANK_ACCOUNTS,
    DELETE_STRIPE_BANK_ACCOUNTS,
    AFTER_SUCCESS,
    SET_ACCOUNTS
  } from '../actions/accounts.action'

  import { 
    CLEAR_SNACKBAR,
    SET_MESSAGE,
    SET_SNACKBAR
  } from '../actions/message.action'

  import AccountsService from "../api/accounts.api";


// Get All Accounts
function* getAllAccounts() {
  yield put({ type: AFTER_SUCCESS  , payload : false })
  yield put({ type: SET_LOADING })
  const x = yield call(AccountsService.get_all_accounts)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_ACCOUNTS , payload: x.data.data })
  }
}

// Add Stripe Account
function* addStripeCreditCardAccount({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(AccountsService.account_cc_add, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_ACCOUNTS , payload: x.data.data })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'New Card has been added'} })
    yield put({ type: AFTER_SUCCESS  , payload : true })
  }
}

// Account deleted
function* deleteStripeCreditCardAccount({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  console.log('payload',payload)
  const x = yield call(AccountsService.account_cc_delete, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    console.log('sec x' , x)
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_ACCOUNTS , payload: x.data.accounts })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Card has been deleted.'} })
  }
}


// Add Stripe Account
function* addStripeBankAccount({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  const x = yield call(AccountsService.account_bank_add, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_ACCOUNTS , payload: x.data.data })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Bank Account has been added.'} })
    yield put({ type: AFTER_SUCCESS  , payload : true })
  }
}

// Account deleted
function* deleteStripeBankAccount({payload}) {
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: SET_LOADING })
  console.log('payload',payload)
  const x = yield call(AccountsService.account_bank_delete, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_SNACKBAR , payload: {state: true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_ACCOUNTS , payload: x.data.accounts })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Bank Account has been deleted.'} })
  }
}


// Export the saga  
export default function* accountsSaga() {
  yield takeEvery(GET_ACCOUNTS, getAllAccounts)
  yield takeEvery(ADD_STRIPE_CC_ACCOUNTS, addStripeCreditCardAccount)
  yield takeEvery(DELETE_STRIPE_CC_ACCOUNTS, deleteStripeCreditCardAccount)
  yield takeEvery(ADD_STRIPE_BANK_ACCOUNTS, addStripeBankAccount)
  yield takeEvery(DELETE_STRIPE_BANK_ACCOUNTS,deleteStripeBankAccount)

}