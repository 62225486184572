import { combineReducers } from "redux";

import action from "./action.reducer";
import accounts from "./accounts.reducer";
import contracts from "./contracts.reducer";
import message from "./message.reducer";
import snackbar from "./snackbar.reducer";

import auth from "./auth.reducer";
import user from "./user.reducer";

export default combineReducers({
    action,
    auth,
    user,
    message, 
    snackbar, 
    accounts, 
    contracts,
});