export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const GET_ACCOUNTS = 'GET_ACCOUNTS'; 
export const ADD_STRIPE_CC_ACCOUNTS = 'ADD_STRIPE_CC_ACCOUNTS';
export const DELETE_STRIPE_CC_ACCOUNTS = 'DELETE_STRIPE_CC_ACCOUNTS'; 
export const ADD_STRIPE_BANK_ACCOUNTS = 'ADD_STRIPE_BANK_ACCOUNTS'; 
export const DELETE_STRIPE_BANK_ACCOUNTS = 'DELETE_STRIPE_BANK_ACCOUNTS'; 
export const DELETE_ACCOUNTS = 'DELETE_ACCOUNTS'; 
export const SET_ACCOUNTS = 'SET_ACCOUNTS'; 
export const AFTER_SUCCESS = 'AFTER_SUCCESS'; 
