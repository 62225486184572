import React, { useState, useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';


function ContractHandoffProgressBar() {

    const dispatch = useDispatch();
    const activeState = useSelector((store) => store.contracts.handoff_active_state);
 
    useEffect(() => {
        
    }, []);

    return (
        <div className="wizard-status-high grid grid-cols-3 gap-2 mt-14">
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>CHECKLIST</span> 
            <div className="progress-bar">
                <span style={{width: (
                    (() => {
                    
                        if(activeState  == 'checklist'){
                            return `50%`;
                        }else{
                            return `100%`;
                        }
                    })()
                )}}></span>
            </div>
        </div> 
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>PHOTO</span> 
            <div className="progress-bar">
                <span style={{width: (
                    (() => {
                        if(
                            activeState != 'checklist'
                        ){
                            if(activeState  == 'photo'){
                                return `50%`;
                            }else{
                                return `100%`;
                            }
                        }
                    })()
                )}}></span>
            </div>
        </div> 
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>SUBMIT & CONFIRM</span> 
            <div className="progress-bar">
                <span style={{width: (
                        (() => {
                            if(
                                activeState != 'checklist' &&
                                activeState != 'photo' 
                            ){
                                if(activeState  == 'submit'){
                                    return `50%`;
                                }else{
                                    return `100%`;
                                }
                            }
                        })()
                    )}}></span>
            </div>
        </div> 
        {/* <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>CONFIRM</span> 
            <div className="progress-bar">
                <span style={{width: (
                    (() => {
                        if(
                            activeState != 'checklist' &&
                            activeState != 'photo' &&
                            activeState != 'submit'
                        ){
                            if(activeState  == 'seller_confirm'){
                                return `100%`;
                            }else if(activeState == 'buyer_confirm'){
                                return `100%`;
                            }
                        }
                        return `0%`;
                    })()
                )}}></span>
            </div>
        </div> */}
    </div>
    )
}

export default ContractHandoffProgressBar