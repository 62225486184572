import axios from "axios";
const API_URL = process.env.REACT_APP_API_PATH;


const axiosInstance = axios.create({
  baseURL: API_URL + "/api/v1/",
  headers: {
    "Content-type": "application/json"
  }
});
 

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Do something with the response data
    return response;
  },
  error => {
    if (error.response && error.response.status === 403) {
      // Handle the 403 error here
      console.log('403 Forbidden Error:', error.response.data);
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      /* Replace this with actual request to redirect in reactjs on router level */
      location.reload();
      
    }

    return Promise.reject(error);
  }
);


export default axiosInstance;

