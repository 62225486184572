import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import IsSeller_WizardContractBuyer from './IsSeller/IsSeller_WizardContractBuyer';
import IsBuyer_WizardContractBuyer from './IsBuyer/IsBuyer_WizardContractBuyer';

function WizardContractBuyer() {
    
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    
    return (
        <>
            {
                edit_contract.role == 'seller' ?
                    <IsSeller_WizardContractBuyer />
                    :
                    <IsBuyer_WizardContractBuyer />
            }
        </>
    )
}

export default WizardContractBuyer;