import React, { useState, useEffect} from 'react';
import UserService from './../../../redux/api/user.api';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER } from '../../../redux/actions/auth.action';
import { SET_SNACKBAR } from '../../../redux/actions/message.action';


function StripeIdentityVerification(props) {

    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);

    const [stripe , setStripe] = useState(null);

    const handleClick = async (event) => {
        // Block native event handling.
        event.preventDefault();
    
        if (!stripe) {
            console.log('Stripe has not loaded yet')
            // Stripe.js hasn't loaded yet. Make sure to disable
            // the button until Stripe.js has loaded.
            return;
        }
    
        // Call your backend to create the VerificationSession.
        const response = await UserService.create_stripe_session();
    
        console.log('response',response )
        console.log('stripe',stripe )
        // Show the verification modal.
        const { error } = await stripe.verifyIdentity(response.data.client_secret);
    
        if (error) {
          console.log('[error]', error);
          
        } else {
            console.log('[user]', user);
            /* var x = await UserService.profile_verified_stripe({id: user.id});
            if(x.status == 200){
                localStorage.setItem("user", JSON.stringify(x.data.user));
                dispatch({ type: SET_USER , payload: x.data.user })
                dispatch({ type: SET_SNACKBAR , payload: {state: true , message: 'Profile has been submited for verfication.'} })
            } */
            console.log('Verification submitted!');
        }
    };
     

    const init = async () => {
        var x = await props.stripePromise;
        setStripe(x);
    }

    useEffect(() => {
        init();
    }, [/* dispatch */]);

    return (
        <>      
            <button className='btn btn-primary btn-sm' disabled={!stripe}  onClick={handleClick}  role='link' >Verify Profile</button>
        </>
    );
}
export default StripeIdentityVerification; 