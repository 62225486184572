export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const USER_UPDATE_GENERAL_INFO = 'USER_UPDATE_GENERAL_INFO'; 
export const USER_UPDATE_PROFILE_PIC_INFO = 'USER_UPDATE_PROFILE_PIC_INFO'; 
export const USER_UPDATE_SECRUTIY_INFO = 'USER_UPDATE_SECRUTIY_INFO'; 
export const USER_UPDATE_ADDRESS_INFO = 'USER_UPDATE_ADDRESS_INFO'; 
export const USER_UPDATE_PROFILE_VERIFICATION = 'USER_UPDATE_PROFILE_VERIFICATION'; 
export const SET_USER = 'SET_USER'; 
 
/* 
export const settings_user_profile_general = (payload) => (dispatch) => {
  return UserServices.settings_user_profile_general(payload).then((response) => {
    var user = response.data && response.data.data && response.data.data.user ? response.data.data.user : null;
    
    localStorage.setItem("user" , JSON.stringify(user))
    return Promise.resolve();
  },(error) => {
    var data = error.response.data;
    var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
    return Promise.reject();
  });
};

export const settings_security_details = (payload) => (dispatch) => {
  dispatch({ type: SET_SUCCESS_MESSAGE, payload : '' });
  dispatch({ type: SET_ERROR_MESSAGE, payload : '' });
  return UserServices.settings_security_details(payload).then((response) => {
    dispatch({
      type: SET_SUCCESS_MESSAGE,
      payload: 'Password has been updated',
    });
    return Promise.resolve(response.data);
  },(error) => {
    var data = error.response.data;
    var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
    
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: message,
    });
    return Promise.reject();
  });
};


export const settings_address_details = (payload) => (dispatch) => {
  clearMessage();
  return UserServices.settings_address_details(payload).then((response) => {
    var user = response.data && response.data.data && response.data.data.user ? response.data.data.user : null;
   
    localStorage.setItem("user" , JSON.stringify(user))
    dispatch(setUser(user))
    
    return Promise.resolve(response.data);
  },(error) => {
    var data = error.response.data;
    var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
    return Promise.reject();
  });
}; */
