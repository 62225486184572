import moment from 'moment';

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
}

export const isContractDateExpired = (date , time) => {
  var a = moment() 
  var temp_date = moment(date).format('DD/MM/YYYY');
  var temp_time = moment(time).format('HH:mm:ss');
  var b = moment(`${temp_date} ${temp_time}`)
  var delayInSeconds = b.diff(a, 'seconds', true);
  if(delayInSeconds < 0) delayInSeconds = 0;

  if(delayInSeconds == 0 ) {
    return true;
  }
  return false;
}