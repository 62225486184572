import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';

function AlertBox() {

    const serverMessage = useSelector((store) => store.message);
  
    return (
        <>
        {/* <div>{JSON.stringify(serverMessage)}</div> */}
        {serverMessage && serverMessage.status != 0 && 
            <h3 className={`alert ` + (serverMessage.status == 200 ? `alert-success` : ``)} role="alert">
                {serverMessage.message}
            </h3>
        }
        </>
    );
}

export default AlertBox;
