import {
    put,
    call,
    takeLatest,
    takeEvery
  } from 'redux-saga/effects'

  import { 
    X_GET_ALL_CONTRACTS,
    X_SEND_CONTRACTS,
    X_SEND_CONTRACT_INNER,
    X_SINGLE_CONTRACT_BY_ID,
    X_SINGLE_CONTRACT_BY_TOKEN,
    X_ARCHIVE_CONTRACT,
    X_UNARCHIVE_CONTRACT,
    X_ACCEPT_CONTRACT,
    X_REJECT_CONTRACT,
    X_GET_CONTRACT_BY_INV_TOKEN,
    X_ACCEPT_CONTRACT_AFTER_LOGIN,
    X_ACCEPT_CONTRACT_AFTER_SETUP_PASSWORD,
    A_CONTRACT_ACTIVE_STATE,
    A_HANDOFF_ACTIVE_STATE,
    DATA_SINGLE_CONTRACT,
    DATA_ALL_CONTRACT,
    X_CREATE_CONTRACT,
    DATA_SINGLE_CONTRACT_SELLER,
    DATA_SINGLE_CONTRACT_BUYER,
    X_REMOVE_CONTRACT,
    X_UPDATE_CONTRACT,
    X_CANCELE_CONTRACT
  } from '../actions/contracts.action'

  import { 
    CLEAR_MESSAGE,
    CLEAR_SNACKBAR,
    SET_MESSAGE,
    SET_SNACKBAR
  } from '../actions/message.action'

  import ContractsService from "../api/contracts.api";
import { ACTION_SET_LOADER } from '../actions/actions.action';
import { SET_USER } from '../actions/user.action';


// Get All Contracts
function* getAllContracts({ payload }) {
  yield put({ type: CLEAR_MESSAGE })
  const x = yield call(ContractsService.contract_all , payload)
  if(x.status && x.status != 200){
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: DATA_ALL_CONTRACT , payload: x.data.data })
  }
}

// Create new contract
function* createNewContract({ payload }) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_create , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    console.log('x.data.data', x.data.data);
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: 'New Contract has been created.'} })
    yield put({ type: A_CONTRACT_ACTIVE_STATE , payload: {state: 'send' }})
  }
}

// Update Contract
function* updateContract({ payload }) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_update , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    console.log('x.data.data', x.data.data);
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: 'Contract has been updated.'} })
  }
}

// Send new contract
function* sendContract({ payload }) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_send , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    console.log('x.data.data', x.data.data);
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.data })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: 'Contract has been sent.'} })
    yield put({ type: A_CONTRACT_ACTIVE_STATE , payload: {state: 'submited' }})
  }
}

// Get Contract By Token
function* getContractByToken({ payload }) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: CLEAR_SNACKBAR })
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_single_by_token , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload: {state: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}

// Get Contract By Token
function* getContractById({ payload }) {
  console.log('payload',payload)
  yield put({ type: ACTION_SET_LOADER , payload : true })
  var start_from = payload && payload.start_from ? payload.start_from : 'creating' ;
  const x = yield call(ContractsService.contract_single_by_id , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
    yield put({ type: A_CONTRACT_ACTIVE_STATE , payload: {state : start_from} })
  }
}

function* sendContractInner({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_send , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been sent'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}

function* archiveContract({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_archive , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been archived'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}

function* unArchiveContract({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_unarchive , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    console.log('snack bar called')
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been restored'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}

function* removeContract({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_remove , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    console.log('snack bar called')
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been removed' , redirect: '/contracts' }})

  }
}

function* rejectContract({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_reject_by_id , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    console.log('snack bar called')
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been rejected'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}


function* canceleContract({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_cancele_by_id , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been canceled.'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}


function* acceptContractAfterLogin({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_accept_after_login , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been Accepted'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
    localStorage.setItem("token", x.data.token.token);
    localStorage.setItem("user", JSON.stringify(x.data.user));
    yield put({ type: SET_USER , payload: x.data.user })
  }
}


function* acceptContractAfterSetupPassword({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_accept_after_setup_password , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been Accepted'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
    localStorage.setItem("token", x.data.token.token);
    localStorage.setItem("user", JSON.stringify(x.data.user));
    yield put({ type: SET_USER , payload: x.data.user })
  }
}


function* acceptContract({ payload }) {
  yield put({ type: ACTION_SET_LOADER , payload : true })
  const x = yield call(ContractsService.contract_accept_by_id , payload)
  if(x.status && x.status != 200){
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: ACTION_SET_LOADER , payload : false })
    yield put({ type: SET_SNACKBAR , payload : {state : true , message: 'Contract has been Accepted'} })
    yield put({ type: DATA_SINGLE_CONTRACT , payload: x.data.contract })
  }
}


// Export the saga  
export default function* accountsSaga() {
  yield takeEvery(X_GET_ALL_CONTRACTS, getAllContracts)
  yield takeEvery(X_CREATE_CONTRACT, createNewContract)
  yield takeEvery(X_UPDATE_CONTRACT, updateContract)
  yield takeEvery(X_SEND_CONTRACTS, sendContract)
  yield takeEvery(X_SINGLE_CONTRACT_BY_TOKEN, getContractByToken)
  yield takeEvery(X_SINGLE_CONTRACT_BY_ID, getContractById)
  yield takeEvery(X_SEND_CONTRACT_INNER, sendContractInner)
  yield takeEvery(X_ARCHIVE_CONTRACT, archiveContract)
  yield takeEvery(X_UNARCHIVE_CONTRACT, unArchiveContract)
  yield takeEvery(X_REMOVE_CONTRACT, removeContract)
  yield takeEvery(X_ACCEPT_CONTRACT, acceptContract)
  yield takeEvery(X_REJECT_CONTRACT, rejectContract)
  yield takeEvery(X_CANCELE_CONTRACT, canceleContract)
  yield takeEvery(X_ACCEPT_CONTRACT_AFTER_LOGIN, acceptContractAfterLogin)
  yield takeEvery(X_ACCEPT_CONTRACT_AFTER_SETUP_PASSWORD, acceptContractAfterSetupPassword)
 
}