import React, { useState } from "react";
import Parser from "html-react-parser";
import "./style.css";

function Input(props) {
  const is_required = props.required
    ? '<span className="text-danger">*</span>'
    : "";
  const label = props.label
    ? `<label className='text-lg mb-1.5 text-primary block'>${props.label} ${is_required}</label>`
    : ``;
  const icon = props.icon
    ? `<span className='text-primary'>${props.icon}</span>`
    : ``;

  const inputChange = (e) => {
    props.onChange ? props.onChange(e) : null;
  };

  return (
    <>
      <div
        className={
          props.label
            ? "label-applied ow-input-group-main " + props.parentClassName
            : "ow-input-group-main " + props.parentClassName
        }
      >
        {Parser(label)}
        <div className="bg-white rounded-md ow-input-group">
          {Parser(icon)}
          <input
            type={props.type}
            placeholder={props.placeholder}
            id={props.id}
            name={props.name}
            value={props.value}
            className={props.className}
            disabled={props.disabled}
            required={props.required}
            onChange={inputChange}
          />
        </div>
      </div>
    </>
  );
}

export default Input;
