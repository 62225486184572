import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';
import LoaderUI from '../../common/Loader';
import { UNSET_LOADING, USER_UPDATE_ADDRESS_INFO, USER_UPDATE_PROFILE_VERIFICATION } from '../../../redux/actions/user.action';
import userService from '../../../redux/api/user.api';

import AddressValidationForm from '../../../components/AccountsPage/components/AddressValidationForm';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {  } from '@mui/material/Select';
import { UN_VERIFIED_ACCOUNT_PNG, VERIFIED_ACCOUNT_PNG } from '../../../helper/assets';
import { UNSET_LOADING as UNSET_LOADING_2 } from '../../../redux/actions/auth.action';


const onlyCountriesAllowed = ['us'];
const document_types = ['Passport', 'Local ID Card'];

function ProfileVerification() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const userObj = useSelector((store) => store.user);
  
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cantSelectThisAddress, setCantSelectThisAddress] = useState(false);
  const [frontBackDocumentRequired, setFrontBackDocumentRequired] = useState(false);

  const [document_front, setDocumentFront] = useState(null);
  const [document_back, setDocumentBack] = useState(null);
  const [stripeStatus , setStripeStatus] = useState([]);
  const [doesDocumentRequired , setDoesDocumentRequired] = useState(true);
  const [recheckVerificationData , setRecheckVerificationData] = useState(false);


  const [formData, setFormData] = useState({
    address_full : '',
    address_line1 : '',
    address_city : '',
    address_country : '',
    address_state : '',
    address_zip : '',

    ssn_id: '',
    dob: '',
    phone: '',
    document_type: 'Local ID Card',
    document_front: null,
    document_back: null
  });

  const handleInputChanged = (e) => {
    const { name, value } = e.target;    
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

    const onChangePhoneNumber = (value) => {
        setFormData(prevState => ({
            ...prevState,
            ['phone']: value
        }));
    }

    const handleInputFileChange = async (event) => {
        const { name } = event.target;    
        if(name == 'document_front') setDocumentFront(event.target.files[0]);
        if(name == 'document_back') setDocumentBack(event.target.files[0]);
    }


  const updateAddress = (event) => {
    event.preventDefault();

    if(selectedAddress && selectedAddress.adminArea1Long && selectedAddress.adminArea1Long.toLowerCase() != "minnesota"){
      setCantSelectThisAddress(true);
      return false;
    }
    if(document_front == null || document_back == null){
        setFrontBackDocumentRequired(true);
        return false;
    }else{
        formData.document_front = document_front;
        formData.document_back = document_back;
    }

    console.log('formData' , formData)
    
    dispatch({type: USER_UPDATE_PROFILE_VERIFICATION ,  payload: formData})
  };

  useEffect(() => {  
    var x = {
        address_full: user.address_full ? user.address_full :  "",
        address_line1: user.address_line1 ? user.address_line1 :  "",
        address_city: user.address_city ? user.address_city :  "",
        address_country: user.address_country ? user.address_country :  "",
        address_state: user.address_state ? user.address_state :  "",
        address_zip: user.address_zip ? user.address_zip :  "",
        ssn_id: user.ssn_id ? user.ssn_id :  "",
        dob: user.dob ? user.dob :  "",
        phone: user.phone ? user.phone :  "",
        document_type: user.document_type ? user.document_type :  "Local ID Card",
    }
    setFormData((prevState) => ( prevState, {...x}))
      
  }, []);



  useEffect(() => {  
    dispatch({type: UNSET_LOADING})
    dispatch({type: UNSET_LOADING_2})
    if(user && user.stripe_account){
        userService.profile_verification_stripe_status({id: user.stripe_account}).then(res => {
            console.log('res.data',res.data)
            if(res.data.data && res.data.data.individual){ 
                var errors = res.data.data.individual.requirements && res.data.data.individual.requirements.errors ? res.data.data.individual.requirements.errors : [];
                console.log('errors',errors)
                if(errors.length > 0) {
                    errors.map(item => {
                        if(item.requirement == 'verification.document') setDoesDocumentRequired(true);
                    })
                }else{
                    setDoesDocumentRequired(false);
                }
                setStripeStatus(errors);
            }
        })
    }    
  }, [user]);


  return (
    <>
        {doesDocumentRequired && <h2 className='text-xl text-dark font-bold mb-1'>Profile Verification</h2>}
        {!doesDocumentRequired && <h2 className='text-xl text-dark font-bold mb-1'>Profile Verified</h2>}
        {doesDocumentRequired && <small className='text-sm text-dark mb-1'>Please fill up the following infomration to verifiy your acocunt.</small>}
        {!doesDocumentRequired && <small className='text-sm text-dark mb-1'>Your profile is verified.</small>}
        <div className="mt-3">
            <div className="flex flex-col">
                {stripeStatus.length > 0 && 
                    <div className="alert">
                        {stripeStatus.map((item , i) => (
                            <>
                                {item.requirement == 'verification.document' &&
                                    <label key={i} style={{lineHeight:'15px' , display:'block'}}>{item.reason}</label>
                                }
                            </>
                        ))}
                    </div>
                }

                {doesDocumentRequired && 
                <form className="w-full rounded-md" onSubmit={updateAddress} mul >
                    
                    <div className="flex gap-3 w-full mt-2">
                        <div className="w-full left flex flex-col gap-3">
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <h4>General Information</h4>
                                    <div className="flex flex-col w-full gap-2 mb-2">
                                        <FormControl>
                                            <TextField 
                                                disabled={false}
                                                required
                                                id="ssn_id" 
                                                label="SSN ID" 
                                                size="small" 
                                                onChange={handleInputChanged}  
                                                variant="outlined" 
                                                value={formData.ssn_id} 
                                                name="ssn_id"  
                                            >
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <div className="flex flex-col w-full gap-2 mb-2">
                                        <FormControl  disabled={doesDocumentRequired ? false : userObj.loading}   >
                                            <InputMask 
                                                disabled={doesDocumentRequired ? false : userObj.loading}
                                                mask="99 99 9999"
                                                id="dob" 
                                                label="Date of Birth (Day Month Year)" 
                                                size="small" 
                                                onChange={handleInputChanged}  
                                                variant="outlined" 
                                                required
                                                value={formData.dob} 
                                                name="dob"  
                                            >
                                                {(inputProps) => <TextField {...inputProps} type="text" />}
                                            </InputMask>
                                        </FormControl>
                                    </div>
                                    <div className="flex flex-col w-full gap-2 mb-2">
                                        <FormControl id="disabled_click_on_flag" required  disabled={doesDocumentRequired ? false : userObj.loading} >
                                            <MuiPhoneNumber 
                                                id="phone" 
                                                required
                                                disabled={doesDocumentRequired ? false : userObj.loading}
                                                onlyCountries={onlyCountriesAllowed}
                                                defaultCountry={'us'} 
                                                onChange={onChangePhoneNumber}  
                                                variant="outlined" 
                                                label="Phone" 
                                                size="small" 
                                                value={formData.phone} 
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="flex flex-col w-full gap-2 mb-2">
                                        <FormControl disabled={doesDocumentRequired ? false : userObj.loading} size="small" required  >
                                            <InputLabel id="formData.document_type">Document Type</InputLabel>
                                            <Select
                                                labelId="formData.document_type"
                                                id="formData.document_type"
                                                value={formData.document_type}
                                                label="Document Type"
                                                name="document_type"
                                                onChange={handleInputChanged} 
                                            >
                                                {document_types.map((item, index) => (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="grid grid-cols-2 gap-3">
                                        <div className="file-front file-upload-custom-btn">
                                            <input type="file" className="form-control" accept=".png, .jpg" disabled={false} name="document_front" onChange={handleInputFileChange} />
                                            <div className="flex gap-2 item-center">
                                                <img width={24} src={document_front ? VERIFIED_ACCOUNT_PNG : UN_VERIFIED_ACCOUNT_PNG} alt="" />
                                                <span style={{whiteSpace: 'nowrap'}}>Upload Front</span>
                                            </div>
                                        </div>

                                        <div className="file-front file-upload-custom-btn">
                                            <input type="file" className="form-control" accept=".png, .jpg" disabled={false} name="document_back" onChange={handleInputFileChange} />
                                            <div className="flex gap-2 item-center">
                                                <img width={24} src={document_back ? VERIFIED_ACCOUNT_PNG : UN_VERIFIED_ACCOUNT_PNG} alt="" />
                                                <span style={{whiteSpace: 'nowrap'}}>Upload Back</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h4>Address</h4>
                                    <div className="flex flex-col w-full gap-2 mb-2 address_auto_complete">
                                        <AddressValidationForm address={formData.address_full} disabled={doesDocumentRequired ? false : userObj.loading} required={true} cb={(address) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                ['address_full']: address.address_full,
                                                ['address_line1']: address.address1,
                                                ['address_country']: address.countryLong,
                                                ['address_state']: address.countryLong,
                                                ['address_zip']: address.postalCode,
                                                ['address_city']: address.locality,
                                            }));
                                            setSelectedAddress({...address})
                                        }} />
                                    </div>
                                    
                                    <div className="flex flex-col w-full gap-2 mb-2">
                                        <FormControl >
                                            <TextField 
                                                disabled={doesDocumentRequired ? false : userObj.loading}
                                                required
                                                id="address_line1" 
                                                label="Address 1" 
                                                size="small" 
                                                onChange={handleInputChanged}  
                                                variant="outlined" 
                                                value={formData.address_line1} 
                                                name="address_line1"  
                                            >
                                            </TextField>
                                        </FormControl>
                                    </div>
                                    <div className="grid grid-cols-2 gap-3">
                                        <div className="flex flex-col w-full gap-2 mb-2">
                                            <FormControl>
                                                <TextField 
                                                    disabled={doesDocumentRequired ? false : userObj.loading}
                                                    required
                                                    id="address_city" 
                                                    label="City" 
                                                    size="small" 
                                                    onChange={handleInputChanged}  
                                                    variant="outlined" 
                                                    value={formData.address_city} 
                                                    name="address_city"  
                                                >
                                                </TextField>
                                            </FormControl>
                                        </div>

                                        <div className="flex flex-col w-full gap-2 mb-2">
                                            <FormControl>
                                                <TextField 
                                                    disabled={doesDocumentRequired ? false : userObj.loading}
                                                    required
                                                    id="address_country" 
                                                    label="Country" 
                                                    size="small" 
                                                    onChange={handleInputChanged}  
                                                    variant="outlined" 
                                                    value={formData.address_country} 
                                                    name="address_country"  
                                                >
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 gap-3">
                                        <div className="flex flex-col w-full gap-2 mb-2">
                                            <FormControl>
                                                <TextField 
                                                    disabled={doesDocumentRequired ? false : userObj.loading}
                                                    required
                                                    id="address_state" 
                                                    label="State" 
                                                    size="small" 
                                                    onChange={handleInputChanged}  
                                                    variant="outlined" 
                                                    value={formData.address_state} 
                                                    name="address_state"  
                                                >
                                                </TextField>
                                            </FormControl>
                                        </div>

                                        <div className="flex flex-col w-full gap-2 mb-2">
                                            <FormControl>
                                                <TextField 
                                                    disabled={doesDocumentRequired ? false : userObj.loading}
                                                    required
                                                    id="address_zip" 
                                                    label="Zip" 
                                                    size="small" 
                                                    onChange={handleInputChanged}  
                                                    variant="outlined" 
                                                    value={formData.address_zip} 
                                                    name="address_zip"  
                                                >
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full text-center flex justify-end mt-8'>
                        <button type='submit' className='btn btn-primary float-right flex gap-2 items-center justify-center'>
                            {userObj.loading ?  
                                <LoaderUI />
                                :
                                <div className='flex  gap-2 items-center justify-center'>
                                    <span className=''>Submit For Verificaiton</span> 
                                </div>
                            }
                        </button>
                    </div>
                </form>
                }
            </div>
        </div>

        <Dialog
            open={cantSelectThisAddress}
            onClose={() => {setCantSelectThisAddress(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
              Please wait!
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  You can not select address other then <b>Minnesota</b>. Please pick the address again. 
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {setCantSelectThisAddress(false)}} >Edit</Button>
          </DialogActions>
      </Dialog>

        <Dialog
            open={frontBackDocumentRequired}
            onClose={() => {setFrontBackDocumentRequired(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
              Please wait!
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                 Frond and back side of the document image is required. Please upload and then submit. 
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {setFrontBackDocumentRequired(false)}} >Edit</Button>
          </DialogActions>
      </Dialog>
    </>
  );
}
export default ProfileVerification;