import { APP_LOGO } from "../../helper/assets";
import LoginForm from "../LoginForm/LoginForm";
import { Link, useHistory } from "react-router-dom";
import opacityImage from "./../../assets/login/2.png";
import { Button } from "../Button/Button";
function HomepageLoginFormGroup(props) {
  return (
    <div
      className={
        " py-12 md:py-32 flex h-full max-w-screen-xl  mx-auto px-4 " +
        props.className
      }
    >
      <dv className="flex  bg-[#F6F6F6]">
        <div className="w-1/2 lg:block hidden relative">
          <img
            src={opacityImage}
            alt=""
            className="absolute w-full h-full top-0 left-0"
          />

          <div className=" relative flex flex-col items-center text-center mt-20">
            <Link to="/">{APP_LOGO}</Link>
            <h1 className="text-5xl mb-8 leading-tight mt-5">
              E-Contracts. Trade <span className="block">with confidence.</span>
            </h1>
          </div>
        </div>
        <div className="w-full lg:w-1/2 bg-[#F6F6F6] flex lg:items-center">
          <LoginForm />
        </div>
      </dv>
    </div>
  );
}

export default HomepageLoginFormGroup;
