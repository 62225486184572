import { 
  ACTION_SET_LOADER
} from '../actions/actions.action'

const initialState = {
  loading: false,
}
export default(state = initialState, {type, payload}) => {
  switch(type) {
    case ACTION_SET_LOADER:
      return {
        ...state,
	      loading: payload ? payload : false
      }
    default:
      return state
  }
}