import {
  SET_LOADING,
  UNSET_LOADING,
  SET_ACCOUNTS,
  AFTER_SUCCESS,
} from "./../actions/accounts.action";
  
const initialState = {
  loading : false,
  new_account_added : false,
  data: []
};


  
export default function (state = initialState, action) {
  const { type, payload } = action;
  
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
	      loading: true
      }
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      }
    case AFTER_SUCCESS:
      return {
        ...state,
        new_account_added: payload
      }
    case SET_ACCOUNTS:
      var nx = payload ? payload : []
      console.log('account list' , nx)
      return {
        ...state,
        data: nx,
        loading: false,
      }
    default:
      return state
  }
}