
import React, { useEffect, useState } from 'react';
import moment from 'moment'

function ContractTemplate (props) {
    return (
        <>
        {props.data && props.data &&
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
                <div className="p-14 pt-0">
                    <h1 className='text-2xl text-center'>Bill of Sale</h1>
                    <p className='mt-4'>This Bill of Sale is entered into as of <b>{moment(props.data.delivery_pick_up_date).format('MM/DD/YYYY')}</b> between   
                        {props.data.role == 'seller' && <> TradeOut Customer Number <b>{props.data.seller_id}</b> (“Seller”) <b>{props.data.seller_name != '' ? props.data.seller_name : props.data.seller_email}</b> </>}
                        {props.data.role == 'buyer' && <> TradeOut Customer (“Seller”) <b>{props.data.seller_name != '' ? props.data.seller_name : props.data.seller_email}</b> </>}
                        and
                        {props.data.role == 'buyer' && <> TradeOut Customer {props.data.buyer_id && 'Number'} <b>{props.data.buyer_id}</b> (“Buyer”) <b>{props.data.buyer_name != '' ? props.data.buyer_name : props.data.buyer_email}</b></>}
                        {props.data.role == 'seller' && <> TradeOut Customer {props.data.buyer_id && 'Number'} <b>{props.data.buyer_id}</b> (“Buyer”) <b>{props.data.buyer_name != '' ? props.data.buyer_name : props.data.buyer_email}</b></>}
                    .</p>
                    <ul className='ul-number'>
                        <li>Seller wants to sell the following personal property: <br />
                            {/* <b>Product Name: </b>  {props.data.product_condition} <br/> */}
                            <b>Product Name: </b> {props.data.title} <br/>
                            {props.data.product_serial_number != '' && <><b>Product Serial Number: </b>  {props.data.product_serial_number} <br/></>}
                            <b>Product Description: </b> {props.data.product_description} <br/>
                            <b>Product Condition: </b> {props.data.product_condition} <br/>
                            <b>Product Quantity: </b> {props.data.product_qty} <br/>
                        in  <b>{props.data.product_condition}</b> condition (“Personal Property”). Seller hereby warrants ownership and/or title to the Personal Property, free and clear of all loans, liens, claims, security interests, encumbrances, and other matters affecting title arising by, through, or under Seller.  </li>
                        <li>Buyer agrees to pay Seller through the TradeOut platform good and valuable consideration in the amount of 
                                <b> $ {props.data.product_total_purchase_price}</b>. 
                                Seller acknowledges the adequacy of the purchase price paid for the Personal Property. Seller hereby acknowledges, Seller irrevocably sells, assigns, transfers, conveys, grants, bargains, and delivers to Buyer, all of its right, title, and interest in and to the Personal Property. Seller will deliver the Personal Property to Buyer on <b>{moment(props.data.delivery_pick_up_date).format('MM/DD/YYYY')}</b> at <b>{moment(props.data.delivery_pick_up_time).format('hh:mm A')}</b> at the following location: <b>{props.data.delivery_pick_up_location}, {props.data.delivery_address}, {props.data.delivery_city}, {props.data.delivery_state}, {props.data.delivery_zip}</b>. The TradeOut platform will credit the purchase price to Seller’s account once the Buyer confirms receipt of the Personal Property at the delivery.</li>
                        <li><b>Except for the warranty of title set forth above, the Personal Property is sold in “AS-IS” condition without any warranties, express or implied, including, without limitation, warranties of fitness or merchantability, whether arising by law, course of dealing, course of performance, usage of trade, or otherwise. By accepting this Bill of Sale, Buyer acknowledges that it has not relied on any representation or warranty made by Seller, or any other person on Seller’s behalf.</b></li>
                        <li>Seller agrees that, at any time on Buyer’s written request, Seller will do, execute, acknowledge, and deliver all such further acts, assignments, transfers, and conveyances as may be reasonably required by Buyer in order to assign, transfer, convey, assure, and confirm unto and vest in Buyer title to the Personal Property sold, conveyed, and transferred by this Bill of Sale.</li>
                        <li>If there is a dispute related to this Bill of Sale, Seller and Buyer agree to resolve the dispute using the applicable online marketplace’s rules to resolve the dispute, or if no rules exist or an online marketplace was not used, the dispute resolution process is outlined in TradeOut’s Terms & Conditions. Seller and Buyer acknowledge that TradeOut will only disclose their contact information to the other party as necessary for such party to resolve a dispute or pursue a claim under this Bill of Sale.</li>
                        <li>This Bill of Sale and all matters arising out of or relating to this Bill of Sale shall be governed by, interpreted under, construed under, and enforceable in accordance with the laws of the State of <b>{props.data.delivery_state}</b>, without giving effect to its conflict of laws provisions.</li>
                    </ul>
                    <p className='mt-4'>Seller and Buyer have each executed this Bill of Sale on the date first written above.</p>

                    {props.data.product_photos && 
                        <div className="contract-images">
                            {props.data.product_photos.map((item, index) => (
                                <div className="c-image" key={index} >
                                    <img src={item.url} alt={item.name} />
                                </div>
                            ))}
                        </div>
                    }
                    
                </div>
            </div>
        }
        </>
        
    );
}

export default ContractTemplate;
