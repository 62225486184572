import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContractCard from '../ContractCard/ContractCard';
import {BELL_ICON} from '../../helper/assets'; 
import Parser from 'html-react-parser';
import WizardContractCreate from './ContractFormWizard/WizardContractCreate';
import WizardContractSeller from './ContractFormWizard/WizardContractSeller';
import WizardContractBuyer from './ContractFormWizard/WizardContractBuyer';
import WizardContractProductDescription from './ContractFormWizard/WizardContractProductDescription';
import WizardContractPayment from './ContractFormWizard/WizardContractPayment';
import WizardContractDelivery from './ContractFormWizard/WizardContractDelivery';
import WizardContractReview from './ContractFormWizard/WizardContractReview';
import WizardContractSend from './ContractFormWizard/WizardContractSend';
import ProgressBar from './components/ProgressBar';
import BuildContractHeader from './components/BuildContractHeader';
import { CLEAR_SINGLE_CONTRACT, DATA_SINGLE_CONTRACT } from '../../redux/actions/contracts.action';

function ContractBuildPage() {

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user); 
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const activeState = useSelector((store) => store.contracts.contract_active_state);
  

    useEffect(() => {
        /* dispatch({ type: CLEAR_SINGLE_CONTRACT }); */
        dispatch({ type: DATA_SINGLE_CONTRACT });

        /* return () => {
            dispatch({ type: CLEAR_SINGLE_CONTRACT });
        } */
    }, [activeState]);
    
  return (
    <Container className='container-area'>
        <div className="content-area">
            <div className="flex flex-col">
                
                <BuildContractHeader />
                {/* <h3>Role: {edit_contract.role && edit_contract.role}</h3> */}
                <ProgressBar />

                <div className="contract-build-wizard-contaner mt-10 flex">
                    <div className='contract-build-wizard-contaner flex'>
                        {activeState == 'creating' && (
                            <WizardContractCreate />
                        )}
                        {activeState == 'seller' && (
                            <WizardContractSeller />
                        )}
                        {activeState == 'buyer' && (
                            <WizardContractBuyer />
                        )}
                        {activeState == 'producut_description' && (
                            <WizardContractProductDescription />
                        )}
                        {activeState == 'payment' && (
                            <WizardContractPayment />
                        )}
                        {activeState == 'delivery' && (
                            <WizardContractDelivery />
                        )}
                        {activeState == 'review' && (
                            <WizardContractReview />
                        )}
                        {(activeState == 'send' || activeState == 'submited' ) && (
                            <WizardContractSend contractid={edit_contract && edit_contract.data && edit_contract.data.id ? edit_contract.id : 0} source="build"  />
                        )}
                    </div>
                </div>

            </div> 
        </div>
    </Container>
  );
}
export default ContractBuildPage;