import React from 'react';
import HomepageLoginFormGroup from '../common/HomepageLoginFormGroup'
// This is one of our simplest components
// It doesn't have local state,
// It doesn't dispatch any redux actions or display any part of redux state
// or even care what the redux state is'

function PricingPage() {
  return (
    <>
      <div className='h-100-p root-page-bg-style'>
        <div className="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto h-100-p spliter-bg" >
          <div className="w-full flex justify-between gap-5 h-100-p">
            <HomepageLoginFormGroup className="w-2/5" />
            <div className='w-3/5  right-container flex flex-col content-center justify-start pt-10 bg-primary'>
              <div className='w-full flex flex-col gap-3 ps-20'>
                  <h1 className='text-2xl font-bold text-white'>PRICING</h1>
                  
                  <div className='p-10 bg-primary-extra-light rounded-md'>
                    <p className='text-base text-black mb-4'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ornare nulla ut massa pretium, id commodo ante dictum. Integer iaculis tellus arcu, et porta mi efficitur vel. Nulla eleifend tellus eget libero posuere, vitae blandit purus convallis.
                    </p>
                    <ul className='ul-disc mb-5'>
                      <li>$1/per contract under $249 </li>
                      <li>$3/per contract $250-749 </li>
                      <li>1% of total for over $750 </li>
                    </ul> 
                    <p className='text-base text-black mb-4'>
                      Maecenas vehicula, enim id faucibus pellentesque, massa diam vestibulum odio, at suscipit velit justo et nibh. Aliquam erat volutpat. Proin quis lorem et urna scelerisque dignissim quis quis elit. In vel elit vitae nulla euismod finibus nec id erat. 
                    </p>
                    <ul className='ul-disc'>
                      <li>monthly plan </li>
                    </ul>
                  </div>
              </div>            
            </div>
          </div> 
        </div>
      </div>
    </>
  );
}

export default PricingPage;
