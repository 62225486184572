
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import logo from '../../Nav/tradeout-logo.svg';
import ContractTempalte from './ContractTemplate';
function ContractDefaultTemplate () {
 
    const edit_contract = useSelector((store) => store.contracts.edit_contract);

    useEffect( () => {
        
    } , []);
    return (
        <>
            <div className="contract-invitation-block">
                {edit_contract && edit_contract.id ?
                    <div className="contract-biding">
                        <div className="contract-reivew text-sm09 pt-4 pb-4" >
                            <ContractTempalte data={edit_contract} />
                        </div>
                    </div>
                :
                <div className="loading">
                     <div className="contract-biding">
                        <div className="contract-reivew text-sm09 pt-4 pb-4 text-center" >
                           <div className='text-center contract-expired'>Contract has been exirepred or invalid token. Plase contract the sender.</div>
                        </div>
                    </div>
                </div>
                }
            </div>
            
        </>
    );
}

export default ContractDefaultTemplate;
