export const  CONTRACT_STATUS_SELLER = {
    'created_but_not_sent' :  'Created but not sent',
    'created_and_sent' :  'Request sent via email',
    'accepted' :  'Accepted',
    'completed' :  'Completed',
    'rejected' :  'Rejected',
  };

export const  CONTRACT_STATUS_BUYER = {
    'created_but_not_sent' :  'Created but not sent',
    'created_and_sent' :  'Pending to be accepted',
    'accepted' :  'Accepted',
    'completed' :  'Completed',
    'rejected' :  'Rejected',
  };