import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Link,
  Switch,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

/* Satic Pages */
import OverviewPage from "../OverviewPage/OverviewPage";
import PricingPage from "../PricingPage/PricingPage";
import HowItsWorksPage from "../HowItsWorksPage/HowItsWorksPage";
import AboutUsPage from "../AboutUsPage/AboutUsPage";

/* App Pages */
import AboutPage from "../AboutPage/AboutPage";
import LandingPage from "../LandingPage/LandingPage";
import LoginPage from "../LoginPage/LoginPage";
import RegisterPage from "../RegisterPage/RegisterPage";
import OTPPage from "../OTPPage/OTPPage";
import RecipientView from "../RecipientView/RecipientView";
import Notifications from "../Notifications/Notifications.jsx";
import "../../index.css";
import "./App.css";
import "./Responsive.css";

// imports for MUI date picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// imports for MUI color theme
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SettingPage from "../SettingPage/SettingPage";
import ContractsPage from "../ContractsPage/ContractsPage";
import ContractBuildPage from "../ContractsPage/ContractBuildPage";
import ContractSendPage from "../ContractsPage/ContractSendPage";
import ContractInvitationPage from "../ContractInvitationPage/ContractInvitationPage";
import ContractHandoffPage from "../ContractHandoffPage/ContractHandoffPage";
import ContractViewPage from "../ContractsPage/ContractViewPage";
import { PROFILE_FETCH, SET_USER } from "../../redux/actions/auth.action";
import ContractEditPage from "../ContractsPage/ContractEditPage";
import SnackbarUI from "./../common/SnackbarUI";
import ForgotPasswordPage from "./../ForgotPasswordPage/ForgotPasswordPage";
import CreateNewPasswordPage from "../CreateNewPasswordPage/CreateNewPasswordPage";
import FaqsPage from "../FaqsPage/FaqsPage";

function App() {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.auth.user);

  const colorTheme = createTheme({
    palette: {
      green: {
        main: "#4BC975",
      },
      purple: {
        main: "#6622CC",
      },
      grey: {
        main: "#5A5A5A",
      },
    },
  });

  const renderAdminSwitchClass = () => {
    var className = `h-100-p Main-content `;
    if (user && user.id) {
      if (user.is_email_varified) {
        return className + ` admin-dashboard`;
      }
    }
    return className;
  };

  const hideNavOn = ["/contract-invitation"];

  useEffect(() => {
    dispatch({ type: SET_USER });
  }, [dispatch]);

  /*  useEffect(() => {
    // Function to be called on application reload
    console.log('Application reloaded!');
    // Perform any additional tasks or actions on application reload

    // Attach the event listener for beforeunload

    // Cleanup the event listener when the component unmounts
    return () => {
      console.log('clean up')
    };
  }, []); */

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={colorTheme}>
        <Router>
          <div className={renderAdminSwitchClass()}>
            <SnackbarUI />
            <Nav hide={hideNavOn} />

            {user && user.is_email_varified && !user.profile_verified && (
              <div className="notificaiton-top-area">
                Profile Verification is required.{" "}
                <Link className="link text-white" to="/settings?tab=general">
                  Click here
                </Link>{" "}
                to verify or refresh if you already verfied the account{" "}
                <div
                  className="link text-white"
                  onClick={() => {
                    dispatch({ type: PROFILE_FETCH });
                  }}
                >
                  Refresh
                </div>
              </div>
            )}

            <Switch>
              {/* Visiting localhost:3000 will redirect to localhost:3000/dashboard */}
              {/* <Redirect exact from="/" to="/dashboard" /> */}

              {/* <ProtectedRoute exact path="/user" > 
                <UserPage />
              </ProtectedRoute>

              <ProtectedRoute exact path="/info" > 
                <InfoPage /> 
              </ProtectedRoute> */}

              <ProtectedRoute exact path="/dashboard">
                <ContractsPage />
              </ProtectedRoute>

              <ProtectedRoute exact path="/notifications">
                <Notifications />
              </ProtectedRoute>

              <ProtectedRoute exact path="/contracts">
                <ContractsPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/contract/build">
                <ContractBuildPage />
              </ProtectedRoute>

              <ProtectedRoute exact path="/contract/view/:id">
                <ContractViewPage />
              </ProtectedRoute>

              <ProtectedRoute exact path="/contract/edit/:id">
                <ContractEditPage />
              </ProtectedRoute>

              <ProtectedRoute exact path="/contract/send/:id">
                <ContractSendPage />
              </ProtectedRoute>

              <ProtectedRoute exact path="/contract/handoff/:id">
                <ContractHandoffPage />
              </ProtectedRoute>
              
              <ProtectedRoute exact path="/help-and-faqs">
                <FaqsPage />
              </ProtectedRoute>

              {/* <ProtectedRoute exact path="/accounts">
                <AccountsPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/account/add-new">
                <AddNewAccount />
              </ProtectedRoute> */}

              <ProtectedRoute exact path="/settings">
                <SettingPage />
              </ProtectedRoute>

              {/* Default Route */}
              <Route exact path="/">
                {user && user.id ? <Redirect to="/dashboard" /> : <LoginPage />}
              </Route>

              <Route exact path="/login">
                {user && user.id ? <Redirect to="/dashboard" /> : <LoginPage />}
              </Route>

              <Route exact path="/register">
                {user && user.id ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <RegisterPage />
                )}
              </Route>
              <Route exact path="/otp-verification">
                {user && user.id ? <Redirect to="/dashboard" /> : <OTPPage />}
              </Route>
              <Route exact path="/forgot-passowrd">
                {user && user.id ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <ForgotPasswordPage />
                )}
              </Route>

              <Route exact path="/contract-invitation/:data">
                {(user && user.id) || localStorage.getItem("token") ? (
                  <ContractInvitationPage />
                ) : (
                  <ContractInvitationPage />
                )}
              </Route>

              <Route exact path="/create-new-password/:token">
                <CreateNewPasswordPage />
              </Route>

              <Route exact path="/overview">
                <OverviewPage />
              </Route>
              <Route exact path="/pricing">
                <PricingPage />
              </Route>
              <Route exact path="/how-it-works">
                <HowItsWorksPage />
              </Route>
              <Route exact path="/about-us">
                <AboutUsPage />
              </Route>

              <Route exact path="/home">
                {user && user.id ? <Redirect to="/user" /> : <LandingPage />}
              </Route>

              <Route exact path="/about">
                <AboutPage />
              </Route>

              {/* NEW COMPONENT UNPROTECTED ROUTES */}
              <Route exact path="/recipient-view/:searchContractKey">
                <RecipientView />
              </Route>

              {/* If none of the other routes matched, we will show a 404. */}
              <Route>
                {" "}
                <h1>404</h1>{" "}
              </Route>
            </Switch>
          </div>
          {/* <Footer /> */}
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
