import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import LoaderUI from '../../common/Loader';
import { X_SEND_CONTRACTS } from './../../../redux/actions/contracts.action';
import AlertBox from '../../common/AlertBox';

function WizardContractSend(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const activeState = useSelector((store) => store.contracts.contract_active_state);
    const loading = useSelector((store) => store.action.loading);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);

    const [formData, setFormData] = useState({
        send_email_to: "",
        send_message_to: ""
    });

    const handleInputChanged = (e) => {
        const { name, value } = e.target;    
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const submitForm = (event) => {
        event.preventDefault();
        var x = {...formData};
        x.id = edit_contract.id;
        dispatch({type : X_SEND_CONTRACTS , payload : x});
    }
    
    useEffect(() => {
        console.log('edit_contract',edit_contract)
        var x = {
            send_message_to: edit_contract && edit_contract.send_message_to ? edit_contract.send_message_to :  "",
        }

        if(edit_contract.role == 'seller'){
            x.send_email_to = edit_contract && edit_contract.buyer_email ? edit_contract.buyer_email :  "";
        }else if(edit_contract.role == 'buyer'){
            x.send_email_to = edit_contract && edit_contract.seller_email ? edit_contract.seller_email :  "";
        }

        setFormData((prevState) => ( prevState, {...x}))
        
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                {
                    activeState == 'send' ?
                        <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                            <div className="flex flex-col gap-2">
                                <AlertBox />
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col mb-2 gap-2">
                                    <FormControl>
                                        <TextField 
                                            disabled={disabled}
                                            id="send_email_to" 
                                            label="Email" 
                                            size="small" 
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.send_email_to} 
                                            name="send_email_to"  
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                                <div className="flex flex-col w-full gap-2 mb-2">
                                    <FormControl>
                                        <TextField 
                                            disabled={disabled}
                                            id="send_message_to" 
                                            label="Message to Buyer/Seller" 
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            multiline
                                            rows={6}
                                            value={formData.send_message_to} 
                                            name="send_message_to"  
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>

                                <div className="flex justify-start">
                                    <button className='btn btn-primary-outline ' type="submit" >
                                        {loading ?  
                                            <LoaderUI />
                                            :
                                            <div className='flex  gap-2 items-center justify-center'>
                                                <span>Send Contract</span>
                                            </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    :
                    <div className='grid grid-cols-2 gap-4 items-center justify-between p-14'>
                        <h1 className='text-2xl text-primary font-bold'>You contract has been sent.</h1>
                        <div className="flex justify-center">
                            <Link className="btn btn-primary text-center" to='/contracts' >Review Contract</Link>
                        </div>
                    </div>
                }
                
            </div>
      </div>
    </>
    )
}

export default WizardContractSend;