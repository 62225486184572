import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LogOutButton from '../LogOutButton/LogOutButton';
import './Nav.css';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import logo from '../Nav/tradeout-logo.svg';
import { APP_LOGO, BURGET_BTN, CLOSE_BTN } from '../../helper/assets';

function Nav() {
  const user = useSelector((store) => store.user);
  const [navbar , setNavbar] = useState(null);
  const drawerWidth = 240;

  return (
    <> 
      {/* <nav className="bg-primary" >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/">
             {APP_LOGO}
          </Link>
          <button 
            onClick={() => {
              if(!navbar){
                setNavbar('active');
              }else{
                setNavbar(null);
              }
              
            }}
            data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            {navbar ? CLOSE_BTN : BURGET_BTN}
          </button>
          <div className={`hidden w-full md:block md:w-auto ` + navbar  } id="navbar-default">
          
          </div>
        </div>
      </nav> */}
    </>
  );
}

export default Nav;
