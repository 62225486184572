import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Calendar from 'ciqu-react-calendar'
import moment from 'moment'
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css'; 
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import { A_CONTRACT_ACTIVE_STATE, DATA_SINGLE_CONTRACT } from './../../../redux/actions/contracts.action';

import AddressValidationForm from '../../AccountsPage/components/AddressValidationForm';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function WizardContractDelivery() {

    const history = useHistory();
    const dispatch = useDispatch();
    const edit_contract = useSelector((store) => store.contracts.edit_contract);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [cantSelectThisAddress, setCantSelectThisAddress] = useState(false);

    const [callendarStartFromDate , setCallendarStartFromDate] = useState(moment().subtract('day',1))
   /*  const disablePastDt = (current) => {
        return current.isAfter(yesterday);
    }; */

    const [formData, setFormData] = useState({
        /* delivery_activate_on_date: "", */
        /* delivery_activate_on_time: "", */
        delivery_pick_up_date: "",
        delivery_pick_up_time: "",
        delivery_pick_up_location: "",
        delivery_address_full: "",
        delivery_address: "",
        delivery_city: "",
        delivery_state: "",
        delivery_zip: "",
        delivery_meet_up_notes: ""
    });

    const handleInputChanged = (e) => {
        const { name, value } = e.target;    
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const submitForm = (event) => {
        event.preventDefault();

        if(selectedAddress != null && selectedAddress.adminArea1Long && selectedAddress.adminArea1Long.toLowerCase() != "minnesota"){
            setCantSelectThisAddress(true);
            return false;
        }

        var x = {...formData};
        x.delivery_pick_up_date = moment(x.delivery_pick_up_date)
        x.delivery_pick_up_time = moment(x.delivery_pick_up_time)
        /* x.delivery_activate_on_date = moment(x.delivery_activate_on_date) */
        /* x.delivery_activate_on_time = moment(x.delivery_activate_on_time) */
        console.log('x',x)
        dispatch({ type: DATA_SINGLE_CONTRACT , payload : x});
        dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
            state : 'review'
        }});
    }

    
    useEffect(() => {
        var x = {
            delivery_pick_up_date: edit_contract.delivery_pick_up_date ? new Date(edit_contract.delivery_pick_up_date) :  moment.now()/* .add('days',1) */,
            delivery_pick_up_time: edit_contract.delivery_pick_up_time ? new Date(edit_contract.delivery_pick_up_time) :  moment.now(),
            /* delivery_activate_on_date: edit_contract.delivery_activate_on_date ? new Date(edit_contract.delivery_activate_on_date) :  moment.now(), */
            /* delivery_activate_on_time: edit_contract.delivery_activate_on_time ? new Date(edit_contract.delivery_activate_on_time) :  moment.now(), */
            delivery_pick_up_location: edit_contract.delivery_pick_up_location ? edit_contract.delivery_pick_up_location : "",
            delivery_address_full: edit_contract.delivery_address_full ? edit_contract.delivery_address_full :  "",
            delivery_address: edit_contract.delivery_address ? edit_contract.delivery_address :  "",
            delivery_city: edit_contract.delivery_city ? edit_contract.delivery_city :  "",
            delivery_state: edit_contract.delivery_state ? edit_contract.delivery_state :  "",
            delivery_zip: edit_contract.delivery_zip ? edit_contract.delivery_zip :  "",
            delivery_meet_up_notes: edit_contract.delivery_meet_up_notes ? edit_contract.delivery_meet_up_notes :  ""
        }
        setFormData((prevState) => ( prevState, {...x}))
        
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full contract-build-delivery">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Exchange Information</h1>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                    <div className="grid grid-cols-2 w-full gap-2 mb-2">
                            {/* <div className="flex flex-col w-full gap-2">
                                <label className=''>Contract Activate On Date *</label>
                                <DatePicker  
                                    value={formData.delivery_activate_on_date}
                                    dateFormat='DD/MM/YYYY'
                                    timeFormat={false}
                                    isValidDate={(current) => {return current.isAfter(moment().subtract(1,'day'))}}
                                    onChange={ (date) => {
                                        setFormData(prevState => ({
                                            ...prevState,
                                            ['delivery_activate_on_date']: date
                                        }));
                                        setCallendarStartFromDate(moment(date));
                                        setFormData(prevState => ({
                                            ...prevState,
                                            ['delivery_pick_up_date']: moment(date).add('day',1)
                                        }));
                                    }}
                                />
                            </div> */}
                            {/* <div className="flex flex-col w-full gap-2">
                                <label className=''>Contract Activate On Time *</label>
                                <DatePicker  
                                    value={formData.delivery_activate_on_time}
                                    dateFormat={false}
                                    timeFormat='hh:mm A'
                                    onChange={ (date) => {
                                        setFormData(prevState => ({
                                            ...prevState,
                                            ['delivery_activate_on_time']: date
                                        }));
                                    }}
                                />
                            </div> */}
                        </div>

                        <div className="grid grid-cols-2 w-full gap-2 mb-2">
                            <div className="flex flex-col w-full gap-2">
                                <label className=''>Handoff Date *</label>
                                <DatePicker  
                                    value={formData.delivery_pick_up_date}
                                    dateFormat='MM/DD/YYYY'
                                    timeFormat={false}
                                    isValidDate={(current) => {
                                        return current.isAfter(callendarStartFromDate)
                                    }}
                                    onChange={ (date) => {
                                        setFormData(prevState => ({
                                            ...prevState,
                                            ['delivery_pick_up_date']: date
                                        }));
                                    }}
                                />
                            </div>
                            <div className="flex flex-col w-full gap-2">
                                <label className=''>Handoff Time *</label>
                                <DatePicker  
                                    value={formData.delivery_pick_up_time}
                                    dateFormat={false}
                                    timeFormat='hh:mm A'
                                    onChange={ (date) => {
                                        setFormData(prevState => ({
                                            ...prevState,
                                            ['delivery_pick_up_time']: date
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className="flex flex-col w-full gap-2 mb-2 address_auto_complete">
                            <AddressValidationForm address={formData.delivery_address_full} cb={(address) => {
                                setFormData(prevState => ({
                                    ...prevState,
                                    ['delivery_address_full']: address.address_full,
                                    ['delivery_address']: address.address1,
                                    ['delivery_state']: address.countryLong,
                                    ['delivery_zip']: address.postalCode,
                                    ['delivery_city']: address.locality,
                                }));
                                setSelectedAddress({...address})
                            }} />
                        </div>

                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    required
                                    id="delivery_pick_up_location" 
                                    label="Pick-up Location" 
                                    size="small" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    value={formData.delivery_pick_up_location} 
                                    name="delivery_pick_up_location"  
                                >
                                </TextField>
                            </FormControl>
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    required
                                    id="delivery_address" 
                                    label="Address" 
                                    size="small" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    value={formData.delivery_address} 
                                    name="delivery_address"  
                                >
                                </TextField>
                            </FormControl>
                        </div>
                        
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    required
                                    id="delivery_city" 
                                    label="City" 
                                    size="small" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    value={formData.delivery_city} 
                                    name="delivery_city"  
                                >
                                </TextField>
                            </FormControl>
                        </div>
                        <div className="flex gap-2  mb-2">
                            <div className="flex flex-col w-1/2 gap-2">
                                <FormControl>
                                    <TextField 
                                        required
                                        id="delivery_state" 
                                        label="State" 
                                        size="small" 
                                        onChange={handleInputChanged}  
                                        variant="outlined" 
                                        value={formData.delivery_state} 
                                        name="delivery_state"  
                                    >
                                    </TextField>
                                </FormControl>
                            </div>
                            <div className="flex flex-col w-1/2 gap-2 mb-2">
                                <FormControl>
                                    <TextField 
                                        required
                                        id="delivery_zip" 
                                        label="Zip" 
                                        size="small" 
                                        onChange={handleInputChanged}  
                                        variant="outlined" 
                                        value={formData.delivery_zip} 
                                        name="delivery_zip"  
                                    >
                                    </TextField>
                                </FormControl>
                            </div>
                        </div>
                        
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    id="delivery_meet_up_notes" 
                                    label="Meet-up Notes" 
                                    size="small" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    multiline
                                    rows={4}
                                    value={formData.delivery_meet_up_notes} 
                                    name="delivery_meet_up_notes"  
                                >
                                </TextField>
                            </FormControl>
                        </div>

                        <div className="flex justify-between">
                            <div className='btn btn-primary-outline ' onClick={() => {
                                dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
                                    state : 'payment'
                                }});
                            }} >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.57129 2.64285L3.21415 8L8.57129 13.3571L7.49986 15.5L-0.000140326 8L7.49986 0.499996L8.57129 2.64285Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </div>
                            <button className='btn btn-primary-outline ' type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
      </div>

        <Dialog
            open={cantSelectThisAddress}
            onClose={() => {setCantSelectThisAddress(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Please wait!
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You can not select address other then <b>Minnesota</b>. Please pick the address again. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setCantSelectThisAddress(false)}} >Edit</Button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default WizardContractDelivery;