import React, { useEffect, useState } from 'react';
import HomepageLoginFormGroup from '../common/HomepageLoginFormGroup'
import backgroundImage1 from '../../assets/how-its-work-banner.jpg'
import { Link , useParams, useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import ContractDefaultTemplate from '../ContractsPage/Templates/ContractDefaultTemplate';
import LoginFormAuthBox from './../LoginForm/LoginFormAuthBox';
import RegisterFormAuthBox from './../RegisterForm/RegisterFormAuthBox';
import { X_REJECT_CONTRACT, X_SINGLE_CONTRACT_BY_TOKEN } from '../../redux/actions/contracts.action';
import { APP_LOGO } from '../../helper/assets';
import bannerImage1 from "./../../assets/homepage-banner-bg-1.jpg";

// This is one of our simplest components
// It doesn't have local state,
// It doesn't dispatch any redux actions or display any part of redux state
// or even care what the redux state is'

function ContractInvitationPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);

    const [showActiveForm , setShowActiveForm] = useState('login');
    const params = useParams();
    const dataKey = params.data;


    useEffect( () => {
        dispatch({ type : X_SINGLE_CONTRACT_BY_TOKEN , payload : {token : dataKey} })

        if(user){
            history.push('/contract/view/' + dataKey);
        }
    } , [user]);

    return (
        <>  
            <div className='fw-auth-area'>
                <div className={`div-left-area flex flex-direction-column justify-center ${showActiveForm == 'register' ? 'reigser-long' : ''}`} style={{
                    backgroundImage: `url(${bannerImage1})`,
                    backgroundSize: `cover`,
                    backgroundPosition: `right center`,
                    }}>
                        <div className="overlay"></div>
                    <div className="logo">
                        {APP_LOGO}
                    </div>
                    <h1 style={{textAlign:'center'}} className="text-2xl sm:text-1xl md:text-2xl  mb-2 leading-tight mt-5">
                        E-Contracts. Trade with confidence.
                    </h1>
                    <div className="forms">
                        {showActiveForm == 'login' && <LoginFormAuthBox />}
                        {showActiveForm == 'register' && <RegisterFormAuthBox />}
                    </div>

                    <div className="fixed-footer">
                        {showActiveForm == 'login' &&
                            <div className="text">
                                Do not have an account yet? <span onClick={ () => {
                                    setShowActiveForm('register');
                                }} className='temp-link font-bold hover:font-bolder cursor-pointer'>Join Tradeout Now</span>
                            </div>
                        }
                        {showActiveForm == 'register' &&
                            <div className="text">
                                Already have an account? <span onClick={ () => {
                                    setShowActiveForm('login');
                                }} className='temp-link font-bold hover:font-bolder cursor-pointer'>Login Tradeout Now</span>
                            </div>
                        }
                    </div>
                    
                </div>
                <div className="div-right-area">
                    <div className="container-auth">
                        <div className="flex flex-col gap-2">
                            <ContractDefaultTemplate />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className=''>
                
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                        <ContractDefaultTemplate />
                    </div>
                </div>
            </div> */}

            
        </>
    );
}

export default ContractInvitationPage;
