import http from './http';

const contract_create = async (payload) => {
    try {
        const x = await http.post('/contracts', payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};
const contract_update = async (payload) => {
    try {
        const x = await http.put('/contracts/update-contract', payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_send = async (payload) => {
    try {
        const x = await http.post('/contracts/send/' + payload.id,  payload ,  { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_single_by_id = async (payload) => {
    try {
        const x = await http.get('/contracts/single/' + payload.id  , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};


const contract_single_by_token = async (payload) => {
    try {
        const x = await http.get('/contracts/invitation-by-token/' + payload.token);
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_archive = async (payload) => {
    try {
        const x = await http.get('/contracts/archive/' + payload.id  , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_unarchive = async (payload) => {
    try {
        const x = await http.get('/contracts/restore/' + payload.id  , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_remove = async (payload) => {
    try {
        const x = await http.delete('/contracts/delete/' + payload.id  , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_accept_by_id = async (payload) => {
    try {
        const x = await http.post('/contracts/accept-contract-by-id/' + payload.id  , payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_reject_by_id = async (payload) => {
    try {
        const x = await http.get('/contracts/reject-contract-by-id/' + payload.id  , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_cancele_by_id = async (payload) => {
    try {
        const x = await http.get('/contracts/cancele/' + payload.id  , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_all = async (payload) => {
    try {
        const x = await http.post('/contracts/all' , payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_accept_after_login = async (payload) => {
    try {
        const x = await http.post('/contracts/accept-contract-by-token-and-login' , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_accept_after_setup_password = async (payload) => {
    try {
        const x = await http.post('/contracts/accept-contract-by-token-and-setup-password' , payload ,  { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const contract_handoff = async (payload) => {
    try {
        const x = await http.post('/contracts/handoff-submit' , payload ,  { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};


const contract_export_single = async (payload) => {
    try {
        const x = await http.get(`/export/contract/${payload.id}/pdf` ,  { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

export default {
    contract_create,
    contract_update,
    contract_send,    
    contract_single_by_id,
    contract_single_by_token,
    contract_archive,
    contract_unarchive,
    contract_accept_by_id,
    contract_reject_by_id,
    contract_cancele_by_id,
    contract_remove,
    contract_all,
    contract_accept_after_login,
    contract_accept_after_setup_password,
    contract_handoff,
    contract_export_single,
};