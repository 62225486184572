import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";

function FaqsPage () {

  return (
    <Container className="container-area">
      <div className="content-area">
        <h1 className="text-4xl font-bold text-primary">Help & Faqs</h1>
        <div className="static-page content-page">
          <h4>How do Contracts protect me from fraud?</h4>
          <p>Contracts provide a clear and legally binding agreement between the buyer and seller that outlines the terms and conditions of the sale. This includes details such as the item being sold, the price, and any warranties or guarantees. Having a contract in place helps to ensure that both parties are held accountable and have a clear understanding of their obligations. If any disputes arise, the contract serves as evidence of the agreement and can be used to resolve the issue. We believe that the contracts are an important tool for facilitating safe and successful transactions and encourage all users to take advantage of this feature.</p>
          <br/>

          <h4>How do buyers pay for the exchanges?</h4>
          <p>We recommend that buyers pay with online services that both the buyer and seller agree with. Tradeout is working hard to implement a quick and easy pre-payment that centralizes the transaction from the first to the last step.</p>
          <br/>

          <h4>Is TradeOut an online marketplace platform like Craigslist or Facebook Marketplace?</h4>
          <p>No, TradeOut is not an online marketplace. We verify the identity of users and provide the tools and resources they need to complete contracts for purchase and sale transactions on their own, including a wide range of payment options for the buyer.</p>
          <p>By using TradeOut, you can confidently and conveniently conduct online sales and purchases with peace of mind.</p>
          <br/>

          <h4>How do I know I will get my money back if something goes wrong with the transaction?</h4>
          <p>At TradeOut, we prioritize the protection of our users' funds and take quick action to resolve any issues that may arise. If you have a problem with a transaction, we will work with you and the payment processor you used to ensure that you receive your money back as soon as possible.</p>
          <p>We provide the necessary materials and support to the payment processor to verify the issue and determine who is at fault. If you are entitled to a refund, you can be confident that you will receive it promptly.</p>
          <br/>

          <h4>How much does it cost to use the platform?</h4>
          <p>TradeOut Does not charge for creating contracts and is free to use</p>

          <h4>In what states are TradeOuts Contracts binding</h4>
          <p>TradeOut contracts are currently only available for Minnesota-based transactions. We will keep everyone updated on our progress and will announce as we launch to more states as soon as possible.</p>

          <h4>How does identity verification work?</h4>
          <p>TradeOut LLC works with Stripe to conduct identity verification online. Stripe builds technology that’s used by millions of companies around the world such as Amazon, Google, and Zoom. Stripe helps with everything from accepting payments to managing subscriptions to verifying identities.</p>
          <p>Stripe helps TradeOut LLC confirm your identity by conducting the following checks:</p>
          <p style={{paddingLeft:'30px'}}>
            Stripe captures images of the front and back of your government-issued photo ID and reviews it to make sure that the document is authentic. They’ve built an automated identity verification technology that looks for patterns to help determine if an ID document is real or fake. This process is like a bank teller checking your ID document to confirm that it’s real.<br/><br/>
            Stripe captures photos of your face and reviews them to confirm that the photo ID belongs to you. They’ve built automated identity verification technology that uses distinctive physiological characteristics of your face (known as biometric identifiers) to match the photos of your face with the photo on the IDdocument. This process is similar to a bank teller confirming that the photo on your ID document is you based on your appearance—but it’s a higher-tech and more accurate way to identify you as a unique person.<br/><br/>
            Stripe collects your name, date of birth, and government ID number, and validates that it’s real. They’ll check this information against a global set of databases to confirm that it exists.
          </p>
          <p>
            Stripe asks for your consent before collecting and using your biometric information. They’ll only use your verification data in accordance with the permissions you grant before starting the verification process, and based on their Privacy Policy. <br/><br/>
            Learn more about how Stripe handles and stores your data.
          </p>

          <br/>

          <h4>How do I report fraud or abuse on TradeOut?</h4>
          <p>Please contact <a href="mail-to:help@tradeout.us">help@tradeout.us</a> with details and anything relevant to the situation</p>
        </div>
      </div>
    </Container>
  );
}
export default FaqsPage;
