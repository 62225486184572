import React, { useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import Container from '@mui/material/Container';
import ProgressBar from './components/ProgressBar';
import BuildContractHeader from './components/BuildContractHeader';
import WizardContractSend from './ContractFormWizard/WizardContractSend';
import { SINGLE_CONTRACT } from '../../redux/actions/types';
//import { fetch_send_contract, single_contract } from '../../redux/actions/contracts.action';

function ContractSendPage() {

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user); 
    const edit_contract = useSelector((store) => store.contracts.single_contract.data);
    const activeState = useSelector((store) => store.contracts.contract_active_state);

    const [isloaded, setLoaded] = useState(true)
    var x_loaded = false;
    useEffect(() => {
        var contract_id = params.id;
        dispatch(fetch_send_contract({
            id: contract_id
        })).then((res) => {
            console.log('dispathced from here')
            dispatch({ type: 'SEND_CONTRACT_STATE' , payload : {
                state : 'send'
            }});
        }).catch((err) => {

        }) ; 
    }, [activeState , dispatch]);
    
  return (
    <Container className='container-area'>
        <div className="content-area">
            <div className="flex flex-col">
                
                <BuildContractHeader />
                <ProgressBar />
                <div className="contract-build-wizard-contaner mt-10 flex">
                    <div className='contract-build-wizard-contaner flex'>
                        {edit_contract && edit_contract.id  && (
                            <WizardContractSend contractid={params.id} source="send" state="send" />
                        )}
                    </div>
                </div>

            </div> 
        </div>
    </Container>
  );
}
export default ContractSendPage;