import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import ProfilePictureChange from "./components/ProfilePictureChange";

import LoaderUI from "../common/Loader";
import Input from "../common/forms/input";
import { USER_UPDATE_GENERAL_INFO } from "../../redux/actions/user.action";
import {
  UN_VERIFIED_ACCOUNT_PNG,
  VERIFICATION_PLAIN,
  VERIFIED_ACCOUNT_PNG,
} from "../../helper/assets";
import StripeIdentityVerification from "./components/StripeIdentityVerification";
import { loadStripe } from "@stripe/stripe-js";
import AuthService from "../../redux/api/auth.api";
import UserService from "../../redux/api/user.api";
import ProfileVerification from "./components/ProfileVerification";
import {
  PROFILE_FETCH,
  SET_USER,
  UPDATE_USER,
} from "../../redux/actions/auth.action";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function SettingsGeneralTab() {
  const userOjb = useSelector((store) => store.auth.user);
  const [updateOnce, setUpdateOnce] = useState(true);
  const [userLocalState, setUserLocalState] = useState(userOjb);
  const dispatch = useDispatch();
  //const user = useSelector((store) => store.auth.user);
  //const [user , setUser] = useState({});

  const [formData, setFormData] = useState({
    legal_name: userOjb.legal_name,
    phone: userOjb.phone ? userOjb.phone : "",
  });

  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateGenearlInformation = (event) => {
    event.preventDefault();
    dispatch({ type: USER_UPDATE_GENERAL_INFO, payload: formData });
  };

  const [profileState, setProfileState] = useState(null);
  const [profileMessage, setProfileMessage] = useState("");

  const verifiyProfileUpdate = () => {
    var isVerified = userLocalState.profile_verified;
    if (!isVerified) {
      var session = setInterval(async () => {
        var x = await UserService.get_profile_update();
        setUserLocalState({ ...x.data.user });
        if (x.data.user && x.data.user.profile_verified) {
          isVerified = true;
        } else {
          console.log("still calling");
          setProfileMessage(x.data.user.profile_stripe_log);
        }
        if (isVerified) {
          console.log("done calling");
          clearInterval(session);
        }
      }, 10 * 1000);
    }
  };

  useEffect(() => {
    //verifiyProfileUpdate();
    console.log("userOjb", userOjb);
  }, []);

  return (
    <>
      <div className="flex flex-col settings-page">
        <h2 className="text-xl text-dark font-bold mb-3">
          Edit Personal Information
        </h2>
        <div className="flex gap-10 mt-5 block-group">
          <div className="w-1/3">
            <ProfilePictureChange />
          </div>
          <div className="form-area w-2/3">
            <form
              className="w-full rounded-md"
              onSubmit={updateGenearlInformation}
            >
              <div className="flex gap-3 w-full">
                <div className="w-full left flex flex-col gap-3">
                  <div className="flex flex-col gap-2">
                    <Input
                      type="text"
                      className="rounded-md nromal-padding "
                      parentClassName="settings-input"
                      placeholder="Full name"
                      label="Name"
                      value={formData.legal_name}
                      name="legal_name"
                      required
                      disabled={true ? "disabled" : ""}
                      onChange={handleInputChanged}
                    />
                    <small className="text-dark text-right w-full">
                      *recommended to use your real name
                    </small>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Input
                      type="text"
                      className="rounded-md nromal-padding "
                      parentClassName="settings-input"
                      placeholder="email"
                      label="Email"
                      disabled
                      value={userOjb.email}
                    />
                    <small className="text-dark text-right w-full">
                      *email address is not changeable
                    </small>
                  </div>
                  {/* <div className="flex flex-col gap-2">
                      <Input type="text" className="rounded-md nromal-padding " parentClassName="settings-input" placeholder="phone"  label="Phone Number" 
                         value={formData.phone} name="phone" required disabled={(userOjb.loading) ? "disabled" : ""} onChange={handleInputChanged}
                      />
                      <small className='text-dark text-right w-full'>*re-verify updated phone number</small>
                    </div> */}
                </div>
              </div>

              <div className="w-full text-center flex justify-end mt-8 ">
                <button
                  type="submit"
                  className="btn btn-primary float-right flex gap-2 items-center justify-center"
                >
                  {userOjb.loading ? (
                    <LoaderUI />
                  ) : (
                    <div className="flex  gap-2 items-center justify-center">
                      <span className="">update details</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        <hr className="mt-2 mb-2" />

        <div className="flex w-full  mt-5 profile-verification">
            <div className="flex w-full gap-5 justify-between align-center">
                <div className="flex gap-2">
                  <div className="img">
                    <img src={userLocalState.profile_verified_state == 'verified'  ? VERIFIED_ACCOUNT_PNG : UN_VERIFIED_ACCOUNT_PNG} alt="" />
                  </div>
                  <div className="flex flex-col">
                    <h3 className='text-xl font-bold'>
                      {
                        (() => { 
                          var message = 'Profile verification required' ;
                          if(userLocalState.profile_verified_state && userLocalState.profile_verified_state == 'verified'){
                            message = 'Profile verified' ;
                          }
                          if(userLocalState.profile_verified_state && userLocalState.profile_verified_state == 'under_verification'){
                            message = 'Profile verification in process.' ;
                          }
                          if(userLocalState.profile_verified_state && userLocalState.profile_verified_state == 'error'){
                            message = 'Profile verification issue.' ;
                          }
                          return message  ;
                        })()
                      }
                    </h3>
                    
                      {
                        (() => { 
                          var message = <>Please verify your profile.</> ;
                          if(userLocalState.profile_verified_state && userLocalState.profile_verified_state == 'verified'){
                            message = <p>Your profile is now verified.</p> ;
                          }
                          if(userLocalState.profile_verified_state && userLocalState.profile_verified_state == 'under_verification'){
                            message = <p>Profile Verification under process. Please wait, We will get back to your shortly.</p> ;
                          }
                          if(userLocalState.profile_verified_state && userLocalState.profile_verified_state == 'error'){
                            message = <p>{userLocalState.profile_stripe_log}</p> ;
                          }
                          return message  ;
                        })()
                      }
                  </div>
                </div>
                {(!userLocalState.profile_verified_state || userLocalState.profile_verified_state == 'error') && 
                  <div className="profile-verification-actions">
                    <StripeIdentityVerification stripePromise={stripePromise} />
                  </div>
                }
            </div>
        </div>
      </div>
    </>
  );
}
export default SettingsGeneralTab;
