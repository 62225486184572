import React, { useState, useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';


function ProgressBar() {

    const dispatch = useDispatch();
    const activeState = useSelector((store) => store.contracts.contract_active_state);
    
    const buildContactGroups = {
        'template' : ['creating'],
        'information' : ['seller', 'buyer','producut_description' ,'payment' , 'delivery'],
        'review' : ['review'],
        'send' : ['send' , 'submited'],
    }


    useEffect(() => {
        
    }, [buildContactGroups]);

    return (
        <div className="wizard-status-high grid grid-cols-4 gap-2 mt-14">
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>TEMPLATE</span> 
            <div className="progress-bar">
                <span style={{width: (activeState == 'creating' ? '50%' : '100%')}}></span>
            </div>
        </div> 
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>INFROMATION</span> 
            <div className="progress-bar">
            <span style={{width: (
                (() => {
                    if (activeState == 'seller') {
                        return `16.6%`;
                    }else if (activeState == 'buyer') {
                        return `32.26%`;
                    }else if (activeState == 'producut_description') {
                        return `49.8%`;
                    }else if (activeState == 'payment') {
                        return `66.4%`;
                    }else if (activeState == 'delivery') {
                        return `83.0%`;
                    }else{
                        if(activeState != 'creating'){
                            return `100%`;
                        }
                    }
                })()
            )}}></span>
            </div>
        </div> 
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>REVIEW</span> 
            <div className="progress-bar">
                <span style={{width: (
                    (() => {
                        if(
                            activeState != 'creating' &&
                            !buildContactGroups.information.includes(activeState) 
                        ){
                            if(activeState  == 'review'){
                                return `50%`;
                            }else{
                                return `100%`;
                            }
                        }
                        return `0%`;
                    })()
                )}}></span>
            </div>
        </div> 
        <div className="flex flex-col gap-1">
            <span className='text-sm09 text-primary text-center font-bold'>SEND</span> 
            <div className="progress-bar">
                <span style={{width: (
                    (() => {
                        if(
                            activeState != 'creating' &&
                            !buildContactGroups.information.includes(activeState)  &&
                            activeState != 'review'
                        ){
                            if(activeState  == 'send'){
                                return `50%`;
                            }else{
                                return `100%`;
                            }
                        }
                        return `0%`;
                    })()
                )}}></span>
            </div>
        </div>
    </div>
    )
}

export default ProgressBar