import React from "react";
import bannerImage1 from "./../../assets/homepage-banner-bg-1.jpg";
import HomepageLoginFormGroup from "../common/HomepageLoginFormGroup";

function LoginPage() {
  return (
    <>
      <div
        id="page-login"
        className="h-screen root-page-bg-style"
        style={{
          backgroundImage: `url(${bannerImage1})`,
          backgroundSize: `contain`,
          backgroundPosition: `right center`,
        }}
      >
        <HomepageLoginFormGroup />
      </div>
    </>
  );
}

export default LoginPage;
