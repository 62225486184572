import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import IsSeller_WizardContractPayment from './IsSeller/IsSeller_WizardContractPayment';
import IsBuyer_WizardContractPayment from './IsBuyer/IsBuyer_WizardContractPayment';

function WizardContractPayment() {
    
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    
    return (
        <>
            {
                edit_contract.role == 'seller' ?
                    <IsSeller_WizardContractPayment />
                    :
                    <IsBuyer_WizardContractPayment />
            }
        </>
    )
}

export default WizardContractPayment;