import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { A_CONTRACT_ACTIVE_STATE, DATA_SINGLE_CONTRACT } from './../../../../redux/actions/contracts.action';
import Select from '@mui/material/Select';

function IsSeller_WizardContractPayment () {

    const history = useHistory();
    const dispatch = useDispatch();
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const accounts = useSelector((store) => store.accounts);
    const user = useSelector((store) => store.auth.user);

    const [ccAccounts, setCCAccounts] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [formData, setFormData] = useState({
        payment_method: "cc",
        account: ""
    });

    const handleInputChanged = (e) => {
        const { name, value } = e.target;    
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    const submitForm = (event) => {
        event.preventDefault();
        dispatch({ type: DATA_SINGLE_CONTRACT , payload : formData});
        dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
            state : 'delivery'
        }});
    }

    const accountGroup = () => {
        var data = accounts && accounts.data ? accounts.data : [];
        var x = [];
        for(var i in data){
            var p = data[i];
            if(data[i].type == 'cc'){
                x.push({
                    title : `${p.s_card_name} (${p.s_card_brand} - ${p.s_card_last4})`,
                    id : p.id
                })
            }
        }
        setCCAccounts(x)

        var x = [];
        for(var i in data){
            var p = data[i];
            if(data[i].type == 'bank_account'){
                x.push({
                    title : `${p.s_account_holder_name} - ${p.s_last4}`,
                    id : p.id
                })
            }
        }
        setBankAccounts(x)
    }

    
    useEffect(() => {
        var x = {
            payment_method: edit_contract.payment_method ? edit_contract.payment_method : edit_contract.id ? (edit_contract.created_by_user_id == user.id ? 'bank' : 'cc') : 'bank',
            account: edit_contract.account ? edit_contract.account :  ""
        }
        setFormData((prevState) => ( prevState, {...x}))
        accountGroup();
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Payment Terms</h1>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                        <div className="grid grid-cols-2 w-full gap-2 mb-2">
                            <div className='grid grid-cols-1 w-full'>
                                <FormControl required={true} >
                                    <FormLabel id="demo-radio-buttons-group-label">Payment Method</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={formData.payment_method}
                                        onChange={handleInputChanged}
                                        name="payment_method"
                                        required
                                    >

                                    {
                                        (() => {
                                            var x = null;
                                            if(edit_contract.id){
                                                if(edit_contract.created_by_user_id == user.id){
                                                    x = <FormControlLabel value="bank" control={<Radio />} label="Bank Account" />
                                                }else{
                                                    x = <FormControlLabel value="cc" control={<Radio />} label="Credit Card" />
                                                }
                                            }else{
                                                x = <FormControlLabel value="bank" control={<Radio />} label="Bank Account" />   
                                            }
                                            return x;
                                        })()
                                    }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className='grid grid-cols-1 w-full'>
                                <div className='grid grid-cols-1 w-full'>
                                    {
                                        (() => {
                                            var x = null;
                                            if(formData.payment_method == 'cc'){
                                                var x = ccAccounts;
                                            }else if (formData.payment_method == 'bank') {
                                                x = bankAccounts;
                                            }
                                            if(x){
                                                return  <FormControl required={true} >
                                                            <InputLabel id="account">Select Account</InputLabel>
                                                            <Select
                                                                labelId="account"
                                                                id="account"
                                                                value={formData.account}
                                                                label="Select Account"
                                                                name="account"
                                                                onChange={handleInputChanged} 
                                                            >
                                                                <MenuItem value="-1">Select value</MenuItem>
                                                                {x.map((item, index) => (
                                                                    <MenuItem key={index} value={formData.payment_method + '_' + item.id}>{item.title}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                            }
                                            return null;
                                        })()
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        
                        <div className="flex justify-between">
                            <div className='btn btn-primary-outline ' onClick={() => {
                                    dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
                                        state : 'producut_description'
                                    }});
                                }} >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.57129 2.64285L3.21415 8L8.57129 13.3571L7.49986 15.5L-0.000140326 8L7.49986 0.499996L8.57129 2.64285Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </div>
                            <button className='btn btn-primary-outline ' type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
      </div>
    </>
    )
}

export default IsSeller_WizardContractPayment;