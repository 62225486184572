import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

function BuildContractHeader() {
  const dispatch = useDispatch();
  const activeState = useSelector(
    (store) => store.contracts.contract_active_state
  );

  const buildContractStates = {
    creating: {
      title: "Create a Contract",
      subtitle: "Select a template",
      desc: "Each template is designed for the specific product category.",
    },
    seller: {
      title: "Add the details",
      subtitle: "Build your contract",
      desc: "Complete all the information below.",
    },
    buyer: {
      title: "Add the details",
      subtitle: "Build your contract",
      desc: "Complete all the information below.",
    },
    producut_description: {
      title: "Add the details",
      subtitle: "Build your contract",
      desc: "Complete all the information below.",
    },
    payment: {
      title: "Add the details",
      subtitle: "Build your contract",
      desc: "Complete all the information below.",
    },
    delivery: {
      title: "Add the details",
      subtitle: "Build your contract",
      desc: "Complete all the information below.",
    },
    review: {
      title: "Review Contract",
      subtitle: "Review Your Contract",
      desc: "Make sure all the details are as accurate as possible: names, dates, locations, product descrtiption, etc..",
    },
    send: {
      title: "Send Contract",
      subtitle: "Your Contract is Ready to Send",
      desc: "",
    },
    submited: {
      title: "Send Contract",
      subtitle: "Your Contract Has Been Sent.",
      desc: "",
    },
  };

  useEffect(() => {}, [buildContractStates]);

  return (
    <div className="contract-build-title flex flex-direction-column  mb-10">
      <div className="w-full flex justify-center ">
        <h1
          className="text-5xl font-bold text-primary"
          style={{
            lineHeight: "45px",
            /* textAlign: 'right',
                    marginRight: '30px' */
          }}
        >
          {buildContractStates[activeState].title}
        </h1>
      </div>
      <div className="full flex gap-1 flex justify-center">
        <h3 className="text-normal mt-3 font-normal text-text-sec">
          {buildContractStates[activeState].subtitle}
        </h3>
      </div>
    </div>
  );
}

export default BuildContractHeader;
