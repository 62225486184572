import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import LoaderUI from '../../common/Loader';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { A_CONTRACT_ACTIVE_STATE, X_CREATE_CONTRACT, X_UPDATE_CONTRACT } from './../../../redux/actions/contracts.action';
import AlertBox from '../../common/AlertBox';
//import { contract_create } from '../../../redux/actions/contracts.action';


function WizardContractReview() {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const loading = useSelector((store) => store.action.loading);

    const [loader, setLoader] = useState(false);
    const [saveContractModal, setSaveContractModal] = useState(false);
    const [updateContractModal, setUpdateContractModal] = useState(false);

    const handleClickOpenCreateContract = () => {
        setSaveContractModal(true);
    };
    const handleClickOpenUpdateContract = () => {
        setUpdateContractModal(true);
    };
    
    const handleClose = () => {
        setSaveContractModal(false);
    }; 
   
    const createNewContractHandler = (event) => {
        setSaveContractModal(false);
        dispatch({ type : X_CREATE_CONTRACT , payload : edit_contract});
    }
    
    const updateContractHandler = (event) => {
        setUpdateContractModal(false);
        dispatch({ type : X_UPDATE_CONTRACT , payload : edit_contract});
    }
    
    useEffect(() => {
       
    }, []);

    return (
        <>
        <div className="flex justify-center w-full">
                <div className="template-card pt-10 pb-10 ps-14 pe-14">
                    {/* <h1 className='text-2xl font-bold text-hepler'>{edit_contract.type.toUpperCase()} Aggreement</h1> */}
                    <h1 className='text-2xl font-bold text-hepler'>Contract Agreement preview</h1>
                    <div className="contract-reivew text-sm09 pt-4 pb-4" >
                        <div className="p-0 pt-0">
                            <h1 className='text-2xl text-center'>Bill of Sale</h1>
                            <p className='mt-4'>This Bill of Sale is entered into as of <b>{moment(edit_contract.delivery_pick_up_date).format('MM/DD/YYYY')}</b> between   
                                {edit_contract.role == 'seller' && <> TradeOut Customer Number <b>{user.id}</b> (“Seller”) <b>{edit_contract.seller_name != '' ? edit_contract.seller_name : edit_contract.seller_email}</b> </>}
                                {edit_contract.role == 'buyer' && <> TradeOut Customer (“Seller”) <b>{edit_contract.seller_name != '' ? edit_contract.seller_name : edit_contract.seller_email}</b> </>}
                                and
                                {edit_contract.role == 'buyer' && <> TradeOut Customer Number <b>{user.id}</b> (“Buyer”) <b>{edit_contract.buyer_name != '' ? edit_contract.buyer_name : edit_contract.buyer_email}</b></>}
                                {edit_contract.role == 'seller' && <> TradeOut Customer (“Buyer”) <b>{edit_contract.buyer_name != '' ? edit_contract.buyer_name : edit_contract.buyer_email}</b>.</>}
                            .</p>
                            <ul className='ul-number'>
                                <li>Seller wants to sell the following personal property: <br />
                                    {/* <b>Product Name: </b>  {edit_contract.product_condition} <br/> */}
                                    <b>Product Name: </b> {edit_contract.title} <br/>
                                    {edit_contract.product_serial_number != '' && <><b>Product Serial Number: </b>  {edit_contract.product_serial_number} <br/> </>}
                                    <b>Product Description: </b> {edit_contract.product_description} <br/>
                                    <b>Product Condition: </b> {edit_contract.product_condition} <br/>
                                    <b>Product Quantity: </b> {edit_contract.product_qty} <br/>
                                in  <b>{edit_contract.product_condition}</b> condition (“Personal Property”). Seller hereby warrants ownership and/or title to the Personal Property, free and clear of all loans, liens, claims, security interests, encumbrances, and other matters affecting title arising by, through, or under Seller.  </li>
                                <li>Buyer agrees to pay Seller through the TradeOut platform good and valuable consideration in the amount of 
                                        <b> $ {edit_contract.product_total_purchase_price}</b>. 
                                        Seller acknowledges the adequacy of the purchase price paid for the Personal Property. Seller hereby acknowledges, Seller irrevocably sells, assigns, transfers, conveys, grants, bargains, and delivers to Buyer, all of its right, title, and interest in and to the Personal Property. Seller will deliver the Personal Property to Buyer on <b>{moment(edit_contract.delivery_pick_up_date).format('MM/DD/YYYY')}</b> at <b>{moment(edit_contract.delivery_pick_up_time).format('hh:mm A')}</b> at the following location: <b>{edit_contract.delivery_pick_up_location}, {edit_contract.delivery_address}, {edit_contract.delivery_city}, {edit_contract.delivery_state}, {edit_contract.delivery_zip}</b>. The TradeOut platform will credit the purchase price to Seller’s account once the Buyer confirms receipt of the Personal Property at the delivery.</li>
                                <li><b>Except for the warranty of title set forth above, the Personal Property is sold in “AS-IS” condition without any warranties, express or implied, including, without limitation, warranties of fitness or merchantability, whether arising by law, course of dealing, course of performance, usage of trade, or otherwise. By accepting this Bill of Sale, Buyer acknowledges that it has not relied on any representation or warranty made by Seller, or any other person on Seller’s behalf.</b></li>
                                <li>Seller agrees that, at any time on Buyer’s written request, Seller will do, execute, acknowledge, and deliver all such further acts, assignments, transfers, and conveyances as may be reasonably required by Buyer in order to assign, transfer, convey, assure, and confirm unto and vest in Buyer title to the Personal Property sold, conveyed, and transferred by this Bill of Sale.</li>
                                <li>If there is a dispute related to this Bill of Sale, Seller and Buyer agree to resolve the dispute using the applicable online marketplace’s rules to resolve the dispute, or if no rules exist or an online marketplace was not used, the dispute resolution process is outlined in TradeOut’s Terms & Conditions. Seller and Buyer acknowledge that TradeOut will only disclose their contact information to the other party as necessary for such party to resolve a dispute or pursue a claim under this Bill of Sale.</li>
                                <li>This Bill of Sale and all matters arising out of or relating to this Bill of Sale shall be governed by, interpreted under, construed under, and enforceable in accordance with the laws of the State of <b>{edit_contract.delivery_state}</b>, without giving effect to its conflict of laws provisions.</li>
                            </ul>
                            <p className='mt-4'>Seller and Buyer have each executed this Bill of Sale on the date first written above.</p>

                            <div className="contract-images">
                            {edit_contract.product_photos.map((item, index) => (
                                <div className="c-image" key={index} >
                                    <img src={item.url} alt={item.name} />
                                </div>
                            ))}
                    </div>
                        </div>
                    </div>

                    <hr className='mb-5 mt-3 text-primary-extra-light' />

                    <div className="flex flex-col gap-2">
                        <AlertBox />
                    </div>

                    <div className="flex justify-between">
                        <div className='btn btn-primary-outline ' onClick={() => {
                            dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
                                    state : 'delivery'
                                }});
                            }} >
                            <div className='flex  gap-2 items-center justify-center'>
                                <span>Edit</span>
                            </div>
                        </div>
                        {edit_contract && edit_contract.id ?
                            <button className='btn btn-primary-outline ' onClick={handleClickOpenUpdateContract} >
                                {loading ?  
                                    <LoaderUI />
                                    :
                                    <div className='flex  gap-2 items-center justify-center'>
                                        <span>Update Contract</span>
                                    </div>
                                }
                            </button>
                            :
                            <button className='btn btn-primary-outline ' onClick={handleClickOpenCreateContract} >
                                {loading ?  
                                    <LoaderUI />
                                    :
                                    <div className='flex  gap-2 items-center justify-center'>
                                        <span>Send Contract</span>
                                    </div>
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={saveContractModal}
                onClose={() => {setSaveContractModal(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please wait!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to create a new contract. Please make sure all the information is correct. You can change the contract information from contract listing page on later state. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setSaveContractModal(false)}} >Review</Button>
                    <Button onClick={createNewContractHandler} autoFocus>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={updateContractModal}
                onClose={() => {setUpdateContractModal(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please wait!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to update the contract and new copy will be sent to buyer. Please make sure all the information is correct. You can change the contract information from contract listing page on later state. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setUpdateContractModal(false)}} >Edit</Button>
                    <Button onClick={updateContractHandler} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default WizardContractReview;