import React, { useState , useEffect } from 'react';
import Container from '@mui/material/Container';
import { Link , useParams, useHistory , useLocation} from 'react-router-dom';

function ProfileVerification() {
  return (
    <>
      <Container className='container-area'>
        <div className="content-area">
          <h1>Profile verification is required. <Link className="link" to="/settings?tab=general">Click here</Link> to verify.</h1>
        </div>
      </Container>

    </>
  );
}

export default ProfileVerification;
