export const SET_LOADING = "SET_LOADING"
export const UNSET_LOADING = "UNSET_LOADING"
export const SET_OTPRESEND_LOADER = "SET_OTPRESEND_LOADER"
export const UNSET_OTPRESEND_LOADER = "UNSET_OTPRESEND_LOADER"
export const AUTH_REGISTER = "AUTH_REGISTER"
export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_OTP_VERIFY = "AUTH_OTP_VERIFY"
export const AUTH_OTP_RESEND = "AUTH_OTP_RESEND"
export const AUTH_CONTRACT_LOGIN = "AUTH_CONTRACT_LOGIN"
export const AUTH_SETUP_NEW_PASSWORD = "AUTH_SETUP_NEW_PASSWORD"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const SET_USER = "SET_USER"
export const UPDATE_USER = "UPDATE_USER"
export const PROFILE_FETCH = "PROFILE_FETCH"
export const AUTH_REGISTER_VIA_TOKEN = "AUTH_REGISTER_VIA_TOKEN"

export const register = () => {}
export const otp_verification = () => {}
export const otp_resend = () => {}
export const contract_login = () => {}
export const setup_new_password = () => {}
export const logout = () => {}
  /* import AuthService from "../api/auth.api";
 
  export const register = (payload) => (dispatch) => {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload : '' });
    dispatch({ type: SET_ERROR_MESSAGE, payload : '' });
    
    return AuthService.register(payload).then((response) => {
        dispatch({
          type: FETCH_USER,
          payload : response.data && response.data.data.user ? response.data.data.user : null ,
        });
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: 'Registeration has been success full',
        });
      
  
        return Promise.resolve(response.data);
      },
      (error) => {
        var data = error.response.data;
        var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
        
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const otp_verification = (payload) => (dispatch) => {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload : '' });
    dispatch({ type: SET_ERROR_MESSAGE, payload : '' });
    return AuthService.otp_verification(payload).then((response) => {
        dispatch({
          type: FETCH_USER,
          payload : response.data && response.data.user ? response.data.user : null ,
        });
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: 'OTP Verification has been completed',
        });
        return Promise.resolve(response.data);
      },
      (error) => {
        var data = error.response.data;
        var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
        
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };

  export const otp_resend = (payload) => (dispatch) => {
    dispatch({ type: SET_SUCCESS_MESSAGE, payload : '' });
    dispatch({ type: SET_ERROR_MESSAGE, payload : '' });
    return AuthService.otp_resend(payload).then((response) => {
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: 'New OTP code has been sent to you email address.',
        });
        return Promise.resolve(response.data);
      },
      (error) => {
        var data = error.response.data;
        var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
        
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
  
  export const login = (payload) => (dispatch) => {
    return AuthService.login(payload).then((response) => {
        //dispatch({type: FETCH_USER, payload : response.data && response.data.data && response.data.data.user ? response.data.data.user : null}); 
        return Promise.resolve(response.data);
      },
      (error) => {
        var data = error.response.data;
        var message = data.errors && data.errors[0] && data.errors[0].title ? data.errors[0].title : 'General Error'; 
        return Promise.reject();
      }
    );
  };
  
  export const contract_login = (payload) => (dispatch) => {
    return AuthService.contract_login(payload).then((response) => {
        dispatch({
          type: SET_RESPONSE,
          payload: response.data,
        });
  
        if(response.data.status == 200){
          dispatch({
            type: FETCH_USER,
            payload : response.data && response.data.user ? response.data.user : null ,
          });
        }
        
        return Promise.resolve(response.data);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? '' : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
  
  export const setup_new_password = (payload) => (dispatch) => {
    return AuthService.setup_new_password(payload).then((response) => {
        dispatch({
          type: SET_RESPONSE,
          payload: response.data,
        });
  
        if(response.data.status == 200){
          dispatch({
            type: FETCH_USER,
            payload : response.data && response.data.user ? response.data.user : null ,
          });
        }
        
        return Promise.resolve(response.data);
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)  ? '' : '' ;
        dispatch({
          type: SET_RESPONSE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
  
  export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
  }; */