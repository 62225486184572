import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import img_babycare from './../../../assets/contracts_bags_plain_comming_soon/babycare.png'
import img_books from './../../../assets/contracts_bags_plain_comming_soon/books.png'
import img_electronics from './../../../assets/contracts_bags_plain_comming_soon/electronics.png'
import img_fashion from './../../../assets/contracts_bags_plain_comming_soon/fashion.png'
import img_fitness from './../../../assets/contracts_bags_plain_comming_soon/fitness.png'
import img_furniture from './../../../assets/contracts_bags_plain_comming_soon/furniture.png'
import img_health from './../../../assets/contracts_bags_plain_comming_soon/health.png'
import img_mobile from './../../../assets/contracts_bags_plain_comming_soon/mobile.png'
import img_seasonal from './../../../assets/contracts_bags_plain_comming_soon/seasonal.png'
import img_toys from './../../../assets/contracts_bags_plain_comming_soon/toys.png'
import { A_CONTRACT_ACTIVE_STATE, DATA_SINGLE_CONTRACT } from './../../../redux/actions/contracts.action';
import { GET_ACCOUNTS } from '../../../redux/actions/accounts.action';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function WizardContractCreate() {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);

    const [comingSoonModal , setcomingSoonModal] = useState(false);
    const [contractRole , setContractRole] = useState(false);


  const handlerClick = (type) => {
    if(type == 'general'){
        dispatch({ type: DATA_SINGLE_CONTRACT , payload : {
            type : type
        }});
        setContractRole(true);
    }else{
        setcomingSoonModal(true);
    }
    
  }

  const handleRequest = (role) => {
    dispatch({ type: DATA_SINGLE_CONTRACT , payload : {
        role : role
    }});
    
    dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
        state : 'seller'
    }});
  }


  useEffect(() => {
    dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
        state : 'creating'
    }});
    dispatch({ type: GET_ACCOUNTS});
  }, [dispatch ]);

  return (
    <>
        <div className="grid grid-cols-3 gap-4">
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('general')}}>
                <div className="checkbox-view">
                    <div className=""></div>
                </div>
                <img src={img_fashion} alt="" />
                <span>General</span>
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('fitness')}}>
                <img src={img_fitness} alt="" />
                <span>Fitness</span>
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('furniture')}}>
                <img src={img_furniture} alt="" />
                <span>Furniture</span>
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('health')}}>
                <img src={img_health} alt="" />
                <span>Health</span>
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('mobile')}}>
                <img src={img_mobile} alt="" />
                <span>Mobile</span>
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('toys')}}>
                <img src={img_toys} alt="" />
                <span>Toys</span>
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('books')}}>
                <img src={img_books} alt="" />  
                <span>Books</span>              
            </div>
            <div className="contact-type-selection-cards cursor-pointer" onClick={() => {handlerClick('health')}}>
                <img src={img_health} alt="" />
                <span>Health</span>               
            </div>
        </div>

        <Dialog
            open={contractRole}
            onClose={() => {setContractRole(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                What`s Your Role?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please select Seller if you are going to sell and Buyer if you are going to buy something. 
                </DialogContentText> 
                <div className="actions flex gap-3 justify-center mt-4">
                    <button className='btn btn-primary ' onClick={() => {handleRequest('seller')}} >
                        <div className='flex  gap-2 items-center justify-center'>
                            <span>Seller</span>
                        </div>
                    </button>
                    <button className='btn btn-primary ' onClick={() => {handleRequest('buyer')}} >
                        <div className='flex  gap-2 items-center justify-center'>
                            <span>Buyer</span>
                        </div>
                    </button>
                </div>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={() => {setContractRole(false)}}>Cancel</Button>
            </DialogActions> */}
        </Dialog>


        <Dialog
            open={comingSoonModal}
            onClose={() => {setcomingSoonModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Coming Soon !
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Watch for updates in your inbox. Thank you for your cooperation and patience.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setcomingSoonModal(false)}}>Ok</Button>
            </DialogActions>
        </Dialog>        
    </>
  )
}

export default WizardContractCreate;