import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { A_HANDOFF_ACTIVE_STATE, DATA_HANDOFF_CONTRACT } from '../../../redux/actions/contracts.action';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function WizardContractCheckList () {

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const handoff_contract_detail = useSelector((store) => store.contracts.handoff_contract_detail);

    const [disabled, setDisabled] = useState(false);
    const [srDoesNotMatchModal, setSrDoesNotMatchModal] = useState(false);

    const [formData, setFormData] = useState({
        physical_inspection : false,
        working_condition : false,
        sr_no_or_id_code_state : false,
        sr_no_or_id_code : "",
    });

    const handleInputChanged = (e) => {
        const { name, value } = e.target;    
        console.log('name' , name , value);
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleCheckChanged = (e) => {
        const { name, checked } = e.target;    
        console.log('name' , name , checked);
        setFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    }


    const submitForm = (event) => {
        event.preventDefault();
        if(edit_contract.sr_no_or_id_code != formData.sr_no_or_id_code) {
            setSrDoesNotMatchModal(true);
            return;
        }
        console.log('formData',formData)
        dispatch({ type: DATA_HANDOFF_CONTRACT , payload : formData});
        dispatch({ type: A_HANDOFF_ACTIVE_STATE , payload : {
            state : 'photo'
        }});
    }

    

    
    useEffect(() => {
        /* if(edit_contract && edit_contract.id){
            if(edit_contract.created_by_user_id == user.id) {

            }else{}
        }
         */
        var x = {
            physical_inspection: handoff_contract_detail.physical_inspection ? handoff_contract_detail.physical_inspection :  false,
            working_condition: handoff_contract_detail.working_condition ? handoff_contract_detail.working_condition :  false,
            sr_no_or_id_code_state: handoff_contract_detail.sr_no_or_id_code_state ? handoff_contract_detail.sr_no_or_id_code_state :  false,
            sr_no_or_id_code: handoff_contract_detail.sr_no_or_id_code ? handoff_contract_detail.sr_no_or_id_code :  ""
        }
        setFormData((prevState) => ( prevState, {...x}))
        console.log('handoff_contract_detail',handoff_contract_detail)
        
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Checklist</h1>
                <p className='text-base'>Go through the checklist before confirming a successful exchange to make sure the item is as described by the seller.</p>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <div className="flex flex-col items-start gap-1">
                                <FormControlLabel className='label-bold' control={<Checkbox {...{checked : formData.physical_inspection}} name="physical_inspection" onChange={handleCheckChanged} />} label="Physical Inspection" />
                                <p className='ps-8'>Does the item look as described? Check for any blemishes that were mentioned.</p>
                            </div>  

                            <div className="flex flex-col items-start gap-1 mt-4">
                                <FormControlLabel className='label-bold' control={<Checkbox {...{checked : formData.working_condition}} name="working_condition" onChange={handleCheckChanged} />} label="Working Condition" />
                                <p className='ps-8'>Does the item work as stated? Check if devices turn on and are functioning as intended.</p>
                            </div>  

                            <div className="flex flex-col items-start gap-1 mt-4">
                                <FormControlLabel className='label-bold' control={<Checkbox {...{checked : formData.sr_no_or_id_code_state}} name="sr_no_or_id_code_state" onChange={handleCheckChanged} />} label="Serial Number or ID Code" />
                                <p className='ps-8'>Does this item have a serial number/IMEI number? Does it match the number provided previously by the seller?</p>
                                <div className="ps-8 mt-3">
                                    <FormControl>
                                        <TextField 
                                            disabled={!formData.sr_no_or_id_code_state}
                                            required
                                            id="sr_no_or_id_code" 
                                            label="Serial Number or IMEI Number" 
                                            size="small"
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.sr_no_or_id_code} 
                                            name="sr_no_or_id_code"  
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>  
                        </div>
                        <div className="flex justify-between mt-4">
                            <Link to={`/contract/view/${params.id}`} className='btn btn-primary-outline '>
                                <span>Back</span>
                            </Link>
                            <button className='btn btn-primary-outline ' type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
      </div>
      <Dialog
            open={srDoesNotMatchModal}
            onClose={() => {setSrDoesNotMatchModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Serial number does not match!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Serial number does not match should they choose to proceed with the transaction. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setSrDoesNotMatchModal(false)}}>Edit</Button>
                <Button onClick={() => {
                    dispatch({ type: DATA_HANDOFF_CONTRACT , payload : formData});
                    dispatch({ type: A_HANDOFF_ACTIVE_STATE , payload : {
                        state : 'photo'
                    }});
                }} autoFocus>
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default WizardContractCheckList;