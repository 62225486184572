import http from './http';


const all = async () => {
    try {
        const x = await http.get('/notifications/all' , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

export default {
    all
};