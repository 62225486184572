import http from './http';


const login = async (payload) => {
    try {
        const x = await http.post('/user/login', payload)
        return x
    } catch(error) {
        return error.response;
    }
};

const register = async (payload) => {
    try {
        const x = await http.post('/user/register', payload)
        return x
    } catch(error) {
        return error.response;
    }
};


const otp_verification = async (payload) => {
    try {
        const x = await http.post('/user/otp-verification', payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const otp_resend = async () => {
    try {
        const x = await http.get('/user/otp-regenerate',  { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const setup_new_password = (payload) => {
    return http.post('/user/setup-new-password', payload);
};
const contract_login = (payload) => {
    return http.post('/user/contract-login', payload);
};
  
const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
};
  
const profile = () => {
  return http.get('/user/profile' , { headers : { 'x-access-token': localStorage.getItem('token') } });
};

  
const reset_password_link = async (payload) => {
  
  try {
        const x = await http.post('/user/reset-password-link' , payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const setup_new_password_by_token = async (payload) => {
    try {
        const x = await http.post('/user/setup-new-password-by-token' , payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

export default {
    register,
    otp_verification,
    otp_resend,
    login,
    logout,
    profile,
    setup_new_password,
    contract_login,
    reset_password_link,
    setup_new_password_by_token,
};