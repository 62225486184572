import LoaderUI from "./Loader";


function LoaderMain() {
    return (    
        <>
            <div className="main-loader">
                <LoaderUI />
            </div>
        </>
    )
}


export default LoaderMain;

