import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import Container from '@mui/material/Container';
import Parser from 'html-react-parser';
//import { view_contract , archive_contract , unarchive_contract, single_contract , accept_contract , reject_contract} from '../../redux/actions/contracts.action';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/fontawesome-free-solid'
import ContractService from './../../redux/api/contracts.api';
import ContractTempalte from './Templates/ContractTemplate';
import ContractHandoffContentTemplate from './Templates/ContractHandoffContentTemplate';

import InputMask from 'react-input-mask';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, {  } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ARCHIVED_IMAGE, RECJECED_IMAGE, THREE_DOTS_VER_BTN } from '../../helper/assets';
import { reject } from 'lodash';
import { X_ACCEPT_CONTRACT, X_ARCHIVE_CONTRACT, X_CANCELE_CONTRACT, X_REJECT_CONTRACT, X_REMOVE_CONTRACT, X_SEND_CONTRACT_INNER, X_SINGLE_CONTRACT_BY_ID, X_UNARCHIVE_CONTRACT } from '../../redux/actions/contracts.action';
import AlertBox from '../common/AlertBox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AccountsService from "./../../redux/api/accounts.api";
import { SET_SNACKBAR } from '../../redux/actions/message.action';
import { GET_ACCOUNTS } from '../../redux/actions/accounts.action';
import { CONTRACT_STATUS_BUYER, CONTRACT_STATUS_SELLER } from '../../helper/consts';

import LoaderMain from '../common/LoaderMain';
import { isContractDateExpired } from '../../helper/cf_helper';
/* export interface State extends SnackbarOrigin {
    open: boolean;
} */

function ContractViewPage() {

    const history = useHistory(); 
    const params = useParams();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const accounts = useSelector((store) => store.accounts);
    const loading = useSelector((store) => store.action.loading);


    const [loader , setLoader] = useState(false);
    const [disabled , setDisabled] = useState(false);
    
    const [archiveModal , setArchiveModal] = useState(false);
    const [unarchiveModal , setUnArchiveModal] = useState(false);
    const [acceptContractModal , setAcceptContractModal] = useState(false);
    const [rejectContractModal , setRejectContractModal] = useState(false);
    const [sendContractModal , setSendContractModal] = useState(false);
    const [canceleContractModal , setCanceleContractModal] = useState(false);
    const [removeModal , setRemoveModal] = useState(false);
    const [addAccountModal , setAddAccountModal] = useState(false);
    const [contractReadyDownload , setContractReadyDownload] = useState(false);

    const [contractdownloadUrl , setContractdownloadUrl] = useState('');


    const [formDataSendFields, setFormDataSendFields] = useState({
        send_email_to: "",
        send_message_to: ""
    });

    const [accountNormalize, setAccountNormalize] = useState([]);
    const [selectedAccountIndex, setSelectedAccountIndex] = useState(-1);

    const contractStatusSeller = {
        'created_but_not_sent' :  'Created but not sent',
        'created_and_sent' :  'Sent to buyer',
        'accepted' :  'Accepted',
        'completed' :  'Completed',
    };
    const contractStatusBuyer = {
        'created_but_not_sent' :  'Created but not sent',
        'created_and_sent' :  'Pending to be accepted',
        'accepted' :  'Accepted',
        'completed' :  'Completed',
    };


    const [openMoreMenu , setOpenMoreMenu] = useState(null);

    const archive_contract_request = () => {
        setArchiveModal(false);
        dispatch({ type:X_ARCHIVE_CONTRACT , payload : {id: edit_contract.id}})
    }
    
    const un_archive_contract_request = () => {
        setUnArchiveModal(false);
        dispatch({ type: X_UNARCHIVE_CONTRACT , payload : {id: edit_contract.id}})
    }

    const accept_contract_request = () => {
        setAcceptContractModal(false);
        //var getAccount = accounts.data[selectedAccountIndex];
        var getAccount = 'none';
        dispatch({ type: X_ACCEPT_CONTRACT , payload : {id: edit_contract.id , accountId : getAccount.type + "__" + getAccount.id}})
    }
    
    const reject_contract_request = () => {
        setRejectContractModal(false);
        dispatch({ type: X_REJECT_CONTRACT , payload : {id: edit_contract.id}})
    }

    const cancele_contract_request = () => {
        setCanceleContractModal(false);
        dispatch({ type: X_CANCELE_CONTRACT , payload : {id: edit_contract.id}})
    }

    const handleInputChangedSendContractFields = (e) => {
        const { name, value } = e.target;    
        setFormDataSendFields(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const submitFormSendContract = () => {
        setSendContractModal(false);
        var x = {...formDataSendFields};
        x.id = edit_contract.id;
        dispatch({type : X_SEND_CONTRACT_INNER , payload : x});
        console.log('contract send requested')
    }
    const remove_contract_request = () => {
        setRemoveModal(false);
        dispatch({type : X_REMOVE_CONTRACT , payload : {id: edit_contract.id}});
    }

    const validateIfUserHaveAccounts = async () => {
        setAcceptContractModal(true)
        /* if(accounts && accounts.data.length > 0){
            setAcceptContractModal(true)
        }else{
            setAddAccountModal(true)
            //dispatch({type: SET_SNACKBAR , payload : {state : true , message: 'Please add account first to accept the contract' }})
        } */
    }

    const generatePDF = async (id) => {
        setLoader(true);
        var x = await ContractService.contract_export_single({id : id})
        if(x.status == 200){
            if(x.data.status == 200){
                console.log('link generated');
                setContractdownloadUrl(x.data.link);
                setContractReadyDownload(true);
            }else{
                dispatch({type: SET_SNACKBAR , payload : {state : true , message: 'Unable to prepare export link. Please refresh the page and try again.' }})
            }
        }else{
            dispatch({type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error' }})
        }
        setLoader(false);

    }

 
    useEffect(() => {
        dispatch({type : X_SINGLE_CONTRACT_BY_ID ,  payload : {id: params.id} }) 
        dispatch({type : GET_ACCOUNTS }) 
    }, []);


  return (
    <Container className='container-area content-page-single-lisitng'>
        {loader && <LoaderMain/>}
        <div className="content-area">
            <div className="flex flex-col">
                <div className='flex flex-col '>
                    {edit_contract && edit_contract.id ?
                    <div className="flex flex-col gap-2">
                        <div className="contract-biding">

                            {isContractDateExpired(edit_contract.delivery_pick_up_date , edit_contract.delivery_pick_up_time) &&
                                <div className="is_expired">Expired</div>
                            }

                            {edit_contract.is_archived &&
                                <div className="is_archived_tag">Archived</div>
                            }
                            {edit_contract.is_contract_canceled &&
                                <div className="is_archived_tag">Canceled</div>
                            }
                            
                            {edit_contract.status == 'rejected' &&
                                <div className="is_rejected">
                                    <img src={RECJECED_IMAGE} alt="Reject Contract" />
                                </div>
                            }
                            
                            <div id="contract_view" className="contract-reivew text-sm09 pt-4 pb-4" >
                                <ContractTempalte data={edit_contract} />
                                <ContractHandoffContentTemplate 
                                    seller={edit_contract.handoff_seller} 
                                    sellerphotos={edit_contract.ContractHandoffSellerPhotos} 
                                    buyer={edit_contract.handoff_buyer} 
                                    buyerphotos={edit_contract.ContractHandoffBuyerPhotos} 
                                />

                            </div>

                        </div>
                        <div className="fixed-contract-page-footer flex gap-5 justify-between">
                            <div className="flex">
                                <Link to="/contracts" className="btn btn-primary-outline hover:bg-primary">Back</Link>
                            </div>

                            <div className="flex gap-3 contract-status-view">
                                <div className="profile-strength flex mt-3 gap-3">
                                    {
                                        (() => {
                                            var html = ``;
                                            if(edit_contract.status != 'rejected' && !edit_contract.is_contract_canceled){
                                                var statusX = [0,0,0,0];
                                                var counterX = 0;
                                                for (const [key, value] of Object.entries(CONTRACT_STATUS_SELLER)) {
                                                    statusX[counterX] = 1;
                                                    if(key == edit_contract.status){
                                                        statusX[counterX] = 2
                                                        break;
                                                    }
                                                    counterX++;
                                                }
                                                
                                                for (var i = 0 ; i < statusX.length ; i++) {
                                                    var printStatus = `unverified`;
                                                    if(statusX[i] == 1) printStatus = `clear`;
                                                    if(statusX[i] == 2) printStatus = `inreview`;
                                                    html += `<span className='strenght-style strenght-style-${printStatus}'></span>` ;
                                                }
                                            }
                                            return Parser(html);
                                        })()
                                    }

                                    {
                                        (() => {
                                            var html = ``;
                                            if(edit_contract.is_contract_canceled){
                                                html = <span className='text-sm'>Contract has been canceled.</span>
                                            }else{
                                                if(edit_contract.created_by_user_id == user.id) {
                                                    html = <span className='text-sm'>{CONTRACT_STATUS_SELLER[edit_contract.status]}</span>
                                                }else{
                                                    html = <span className='text-sm'>{CONTRACT_STATUS_BUYER[edit_contract.status]}</span>
                                                }
                                            }
                                            return html;
                                        })()
                                    }
                                </div> 
                            </div>

                            <div className="flex gap-3 cotract-view-action-buttons">
                                {/* Edit Contract : Both Seller and buyer */}
                                <div className="flex gap-3 btn-default-group">
                                    {
                                        (() => { 
                                            var editContractLink = null ;
                                            if
                                            (
                                                !edit_contract.is_contract_accepted &&
                                                edit_contract.status != 'completed' && 
                                                !edit_contract.is_contract_canceled && 
                                                edit_contract.status != 'rejected' && 
                                                !edit_contract.is_archived && 
                                                !(edit_contract.handoff_seller &&  edit_contract.handoff_seller.id ) &&
                                                !isContractDateExpired(edit_contract.delivery_pick_up_date , edit_contract.delivery_pick_up_time)
                                            ){
                                                editContractLink = <Link to={`/contract/edit/${edit_contract.id}`} className="btn btn-primary-outline hover:bg-primary">Edit</Link>;
                                            }
                                            return editContractLink  ;
                                        })()
                                    }

                                    <div className="more-responsive-btn" onClick={() => {
                                        console.log('clicked')
                                        if(!openMoreMenu){
                                            setOpenMoreMenu('active');
                                        }else{
                                            setOpenMoreMenu(null);
                                        }
                                    }}>
                                        {THREE_DOTS_VER_BTN}
                                    </div> 
                                </div>
                                
                                <div className={`flex gap-3 btn-more-btn-group ` + openMoreMenu}>

                                    <button 
                                        onClick={() => {generatePDF(edit_contract.id)}} 
                                        className="btn btn-primary-outline hover:bg-primary"
                                    >
                                        Export{/*  to PDF */}
                                    </button>

                                    {!edit_contract.buyer_id && edit_contract.status == 'created_but_not_sent' && !edit_contract.is_contract_canceled && !isContractDateExpired(edit_contract.delivery_pick_up_date , edit_contract.delivery_pick_up_time) &&
                                        <button onClick={() => {
                                            var send_email_to = ``;
                                            if(edit_contract.role == 'seller'){
                                                send_email_to = edit_contract && edit_contract.buyer_email ? edit_contract.buyer_email :  "";
                                            }else if(edit_contract.role == 'buyer'){
                                                send_email_to = edit_contract && edit_contract.seller_email ? edit_contract.seller_email :  "";
                                            }
                                            setFormDataSendFields(prevState => ({
                                                ...prevState,
                                                ['send_email_to']: send_email_to
                                            }));
                                            setSendContractModal(true)
                                        }} className="btn btn-primary-outline hover:bg-primary">Send Contract</button>
                                    }
                                    
                                    {(edit_contract.created_by_user_id != user.id && edit_contract.status == 'created_and_sent' && !edit_contract.is_contract_canceled) &&  !isContractDateExpired(edit_contract.delivery_pick_up_date , edit_contract.delivery_pick_up_time) &&
                                        <div className="flex gap-3">
                                            <button onClick={() => {
                                                validateIfUserHaveAccounts();
                                            }} className="btn btn-primary-outline hover:bg-primary">Accept</button>
                                            <button onClick={() => {setRejectContractModal(true)}} className="btn btn-primary-outline hover:bg-primary">Reject</button>
                                        </div> 
                                    }

                                    {
                                        (() => {    
                                            var canceleContractPaymentButton = null;
                                            if(edit_contract.status == 'accepted'  && !edit_contract.is_archived && !edit_contract.is_contract_canceled && !isContractDateExpired(edit_contract.delivery_pick_up_date , edit_contract.delivery_pick_up_time)){
                                                canceleContractPaymentButton = <button className="btn btn-primary-outline hover:bg-primary" onClick={() => {setCanceleContractModal(true)}}>Cancel</button>;
                                            }
                                            return canceleContractPaymentButton;
                                        })()
                                    }


                                    {
                                        (() => {    
                                            var handofBtn = <Link to={`/contract/handoff/${edit_contract.id}`} className="btn btn-primary-outline hover:bg-primary">Handoff</Link>;
                                            if(edit_contract.status == 'accepted'  && !edit_contract.is_archived && !edit_contract.is_contract_canceled ){
                                                
                                                var a = moment() 
                                                var b = moment(edit_contract.delivery_pick_up_date);
                                                var delayInSeconds = b.diff(a, 'seconds', true);
                                                if(delayInSeconds < 0) delayInSeconds = 0;

                                                var delivery_pick_up_date = moment(b).format('YYYY-MM-DD');
                                                var isSameDate = moment(delivery_pick_up_date).isSame(a.format('YYYY-MM-DD'));
                                                if(isSameDate) delayInSeconds = 0;
                                                delayInSeconds = 0; // manual overide
                                                if(delayInSeconds == 0 && /* edit_contract.buyer_payment_status */ true ){
                                                    if(edit_contract.role == 'seller'){
                                                        if(edit_contract.created_by_user_id == user.id){
                                                            /* Seller */
                                                            if(!(edit_contract.handoff_seller && edit_contract.handoff_seller.id)){
                                                                return handofBtn;
                                                            }
                                                        } else {
                                                            /* Buyer */
                                                            if(!(edit_contract.handoff_buyer && edit_contract.handoff_buyer.id)){
                                                                return handofBtn;
                                                            }
                                                        }
                                                    }else if(edit_contract.role == 'buyer'){
                                                        if(edit_contract.created_by_user_id != user.id){
                                                            /* Seller */
                                                            if(!(edit_contract.handoff_seller && edit_contract.handoff_seller.id)){
                                                                return handofBtn;
                                                            }
                                                        } else {
                                                            /* Buyer */
                                                            if(!(edit_contract.handoff_buyer && edit_contract.handoff_buyer.id)){
                                                                return handofBtn;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            return null;
                                        })()
                                    }


                                    {/* Archive Button : only seller view */}
                                    {
                                        (() => {
                                            var archiveBtn = null ;
                                            if(edit_contract.created_by_user_id == user.id){
                                                if(
                                                    !edit_contract.is_archived && 
                                                    edit_contract.status != 'rejected' &&  
                                                    !(edit_contract.handoff_seller && edit_contract.handoff_seller.id) &&
                                                    !edit_contract.is_contract_canceled
                                                ){
                                                    archiveBtn = <button onClick={() => {setArchiveModal(true)}} className="btn btn-primary-outline hover:bg-primary">Archive</button>;
                                                }
                                            }
                                            return archiveBtn  ;
                                        })()
                                    }

                                    {/* Un-Archive Button : only seller view */}
                                    {
                                        (() => { 
                                            var unArchiveBtn = null ;
                                            if(edit_contract.created_by_user_id == user.id){
                                                if(
                                                    edit_contract.is_archived && 
                                                    edit_contract.status != 'rejected' && 
                                                    !(edit_contract.handoff_seller && edit_contract.handoff_seller.id) && 
                                                    !edit_contract.is_contract_canceled
                                                ){
                                                    unArchiveBtn = <button onClick={() => {setUnArchiveModal(true)}} className="btn btn-primary-outline hover:bg-primary">Un-Archive</button>;
                                                }
                                            }
                                            return unArchiveBtn  ;
                                        })()
                                    }
                                    
                                    {/* Remove Button : only seller view */}
                                    {
                                        (() => { 
                                            var removeBtn = null ;
                                            if(edit_contract.created_by_user_id == user.id){
                                                if(!(
                                                    edit_contract.handoff_seller && 
                                                    edit_contract.handoff_seller.id &&
                                                    !edit_contract.is_contract_canceled
                                                )){
                                                    removeBtn = <button onClick={() => {setRemoveModal(true)}} className="btn btn-danger-outline hover:bg-danger">Remove</button>;
                                                }
                                            }
                                            return removeBtn  ;
                                        })()
                                    }

                                </div>
                                
                            </div>
                        </div>
                    </div>
                    :
                    <div className="loading">
                        Loading
                    </div>     
                }

                </div>
            </div> 
        </div>

        <Dialog
            open={addAccountModal}
            onClose={() => {setAddAccountModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Before accepting this contract. Please select a bank account you would like to receive the payment. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setAddAccountModal(false)}}>Cancel</Button>
                <Button onClick={() => {
                    localStorage.setItem('hard_redirect', '/contract/view/' + edit_contract.id)
                    history.push('/accounts')
                    /* Redirect to account page. */
                }} autoFocus>
                    Add Account Now
                </Button>
            </DialogActions>
        </Dialog>
        
        
        <Dialog
            open={archiveModal}
            onClose={() => {setArchiveModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your are about to archive the contract. Are you sure you want to do this?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setArchiveModal(false)}}>Cancel</Button>
                <Button onClick={archive_contract_request} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={unarchiveModal}
            onClose={() => {setUnArchiveModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You are about to restore the contract. Are you sure you want to do it. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setUnArchiveModal(false)}}>Cancel</Button>
                <Button onClick={un_archive_contract_request} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={acceptContractModal}
            onClose={() => {setAcceptContractModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your are about to accept the contract. Please read the contract carefully before accepting it.
                </DialogContentText>
                {/* <div className="select-account mt-4 w-full ">
                    <FormControl className='w-2/3'>
                        <InputLabel id="seller_account">Select Account</InputLabel>
                        <Select
                            labelId="select_account"
                            id="select_account"
                            value={selectedAccountIndex}
                            label="Select Account"
                            name="select_account"
                            onChange={ (event) => {
                                console.log('event',event.target.value)
                                setSelectedAccountIndex(event.target.value);
                            }} 
                        >
                            <MenuItem value="-1">Select account</MenuItem>
                            {accounts && accounts.data.map((item, index) => (
                                <MenuItem key={index} value={index}>
                                    {
                                        (() => {
                                            var x = null;
                                            if(edit_contract.role == 'seller'){
                                                if(item.type == 'cc'){
                                                    x = `${item.s_card_name} (${item.s_card_brand} - ${item.s_card_last4})`
                                                }
                                            }else if(edit_contract.role == 'buyer'){
                                                if(item.type == 'bank'){
                                                    x = `${item.s_account_holder_name} - ${item.s_last4}`
                                                }
                                            }
                                            return x;
                                        })()
                                    }</MenuItem>
                            ))} 
                        </Select>
                </FormControl>
                </div> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setAcceptContractModal(false)}}>Cancel</Button>
                <Button onClick={accept_contract_request} 
                    disabled={false}
                    autoFocus 
                >
                    Accept
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={rejectContractModal}
            onClose={() => {setRejectContractModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your are about to reject the contract. Please read the contract carefully before rejecting it.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setRejectContractModal(false)}}>Cancel</Button>
                <Button onClick={reject_contract_request} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>


        <Dialog
            open={canceleContractModal}
            onClose={() => {setCanceleContractModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your are about to cancele the contract. Are you sure?.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setCanceleContractModal(false)}}>No</Button>
                <Button onClick={cancele_contract_request} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={removeModal}
            onClose={() => {setRemoveModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please wait!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your are about to remove the contract. This process is not reverseable.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setRemoveModal(false)}}>Cancel</Button>
                <Button onClick={remove_contract_request} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={sendContractModal}
            onClose={() => {setSendContractModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Send Contract
            </DialogTitle>
            <DialogContent>
                <div id="alert-dialog-description-send-contract" className="rounded-md mt-4" style={{width:'400px'}}>
                    <div className="flex flex-col gap-2">
                        <AlertBox />
                    </div>
                    <div className=" flex flex-col gap-3">
                        <div className="flex flex-col mb-2 gap-2">
                            <FormControl>
                                <TextField 
                                    disabled={loading}
                                    id="send_email_to" 
                                    label="Email" 
                                    size="small" 
                                    onChange={handleInputChangedSendContractFields}  
                                    variant="outlined" 
                                    value={formDataSendFields.send_email_to} 
                                    name="send_email_to"  
                                >
                                </TextField>
                            </FormControl>
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    disabled={loading}
                                    id="send_message_to" 
                                    label="Message to Buyer/Seller" 
                                    onChange={handleInputChangedSendContractFields}  
                                    variant="outlined" 
                                    multiline
                                    rows={6}
                                    value={formDataSendFields.send_message_to} 
                                    name="send_message_to"  
                                >
                                </TextField>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setSendContractModal(false)}}>Back</Button>
                <Button autoFocus onClick={() => {submitFormSendContract()}} >
                    Send Contract
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={contractReadyDownload}
            onClose={() => {setContractReadyDownload(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
               File is Ready
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You contract file is read to downlaod. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setContractReadyDownload(false)}} >Back</Button>
                <Button href={contractdownloadUrl} target="_blank" download>Download</Button>
            </DialogActions>
        </Dialog>
        
    </Container>
  );
}
export default ContractViewPage;