import React from "react";
import { Link, useLocation } from "react-router-dom";
import LogOutButton from "../LogOutButton/LogOutButton";
import "./Nav.css";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import NavLogin from "./NavLogin";
import NavDashboard from "./NavDashboard";

import logo from "../Nav/tradeout-logo.svg";
import NavDashboardResponsive from "./NavDashboardResponsive";

function Nav(props) {
  const location = useLocation();
  const user = useSelector((store) => store.auth.user);

  const drawerWidth = 240;

  const renderNav = () => {
    if (!props.hide.includes(location.pathname)) {
      if (user && user.id) {
        if (user.is_email_varified) {
          return (
            <>
              <NavDashboard />
              <NavDashboardResponsive />
            </>
          );
        } else {
          return <NavLogin />;
        }
      }

      if (location.pathname.includes("contract-invitation")) {
        return <></>;
      }

      // return <NavLogin />;
    }
  };

  return <>{renderNav()}</>;
}

export default Nav;
