import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { VERIFICATION_PLAIN, VERIFICATION_VERFIED } from '../../../helper/assets';
import Parser from 'html-react-parser';
import { A_HANDOFF_ACTIVE_STATE, DATA_HANDOFF_CONTRACT } from '../../../redux/actions/contracts.action';
import ContractsService from "./../../../redux/api/contracts.api";
import { SET_SNACKBAR } from '../../../redux/actions/message.action';

function WizardContractSubmit (props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.auth.user);
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const handoff_contract_detail = useSelector((store) => store.contracts.handoff_contract_detail);

    const [disabled, setDisabled] = useState(false);


    const submitForm = async (event) => {
        event.preventDefault();

        var sender = ``;
        if(edit_contract.role == 'seller'){
            if(edit_contract.id && edit_contract.created_by_user_id == user.id){
                sender = 'seller';
            }else{
                sender = 'buyer';
            }
        }else if(edit_contract.role == 'buyer'){
            if(edit_contract.id && edit_contract.created_by_user_id == user.id){
                sender = 'buyer';
            }else{
                sender = 'seller';
            }
        }

        var x = await ContractsService.contract_handoff({
            contract_id : edit_contract.id,
            sender : sender,
            data : handoff_contract_detail,
        });
        console.log('x',x);
        if(x.status == 200){
            dispatch({ type: SET_SNACKBAR , payload : {state : true , message: x.data.message }})
            props.cb();
            dispatch({ type: A_HANDOFF_ACTIVE_STATE , payload : {
                state : 'confirm'
            }});
        }else{
            dispatch({ type: SET_SNACKBAR , payload : {state : true , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error' }})
        }
    }

    const verifyPhotos = () => {
        var x = handoff_contract_detail.photos ? handoff_contract_detail.photos.length : 0;
        if(x){
            for(var i = 0 ; i < x ; i++){
                if(handoff_contract_detail.photos[i].url){
                    return true;
                }
            }
        }
        
        return false;
    }
    
    useEffect(() => {
        
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full WizardContractSubmit">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Review & Submit</h1>
                <p className='text-base'>Look over all the information before confirming your successful exchange.</p>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <div className="flex gap-5">
                                <div className="checklist">
                                    {
                                         (() => {
                    
                                            if(
                                                handoff_contract_detail.physical_inspection &&
                                                handoff_contract_detail.working_condition &&
                                                handoff_contract_detail.sr_no_or_id_code_state &&
                                                handoff_contract_detail.sr_no_or_id_code != ''
                                            ){
                                                return Parser(VERIFICATION_VERFIED);
                                            }else{
                                                return Parser(VERIFICATION_PLAIN);
                                            }
                                        })()
                                    }
                                </div>
                                
                                <div className="flex flex-col gap-3">
                                    <h3 className='text-2xl'>Checklist Completed</h3>
                                    <ul className='verification_handoff_list'>
                                        <li>{handoff_contract_detail.physical_inspection ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)} <span>Physical Inspection</span></li>
                                        <li>{handoff_contract_detail.working_condition ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)}Working Condition</li>
                                        <li>{handoff_contract_detail.sr_no_or_id_code_state && handoff_contract_detail.sr_no_or_id_code != '' ?  Parser(VERIFICATION_VERFIED) : Parser(VERIFICATION_PLAIN)}Serial Number or ID Code: {handoff_contract_detail.sr_no_or_id_code}</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="flex gap-5 mt-5">
                                <div className="checklist">
                                    {
                                         (() => {
                    
                                            if(
                                                verifyPhotos() 
                                            ){
                                                return Parser(VERIFICATION_VERFIED);
                                            }else{
                                                return Parser(VERIFICATION_PLAIN);
                                            }
                                        })()
                                    }
                                </div>
                                
                                <div className="flex flex-col gap-3">
                                    <h3 className='text-2xl'>Photos Added</h3>
                                    <div className="grid grid-cols gap-3">
                                        {handoff_contract_detail.photos.map((item, index) => (
                                            <>
                                            {item.file && (
                                                <div className="photo_added">
                                                    <img src={item.base64} alt="" />
                                                </div>
                                            )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className='btn btn-primary-outline ' onClick={() => {
                                    dispatch({ type: A_HANDOFF_ACTIVE_STATE , payload : {
                                        state : 'photo'
                                    }});
                                }} >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.57129 2.64285L3.21415 8L8.57129 13.3571L7.49986 15.5L-0.000140326 8L7.49986 0.499996L8.57129 2.64285Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </div>
                            <button className='btn btn-primary' type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                   Submit
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
      </div>
    </>
    )
}

export default WizardContractSubmit;