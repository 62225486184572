import { 
  SET_LOADING,
  UNSET_LOADING,
  SET_OTPRESEND_LOADER,
  UNSET_OTPRESEND_LOADER,
  AUTH_CONTRACT_LOGIN,
  AUTH_SETUP_NEW_PASSWORD,
  UPDATE_USER,
  AUTH_LOGOUT,
  SET_USER
} from '../actions/auth.action'

const userX = JSON.parse(localStorage.getItem("user"));
const initialState = {
  loading: false,
  loaderResendOTP: false,
  user: userX ? userX : null
}
  
  
export default(state = initialState, {type, payload}) => {
  switch(type) {
    // Set loading
    case SET_LOADING:
      return {
        ...state,
	      loading: true
      }
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      }
    case SET_OTPRESEND_LOADER:
      return {
        ...state,
        loaderResendOTP: true
      }
    case UNSET_OTPRESEND_LOADER:
      return {
        ...state,
        loaderResendOTP: false
      }
    // SET USER
    case SET_USER:
      var xUser = JSON.parse(localStorage.getItem("user"));
      var nx = payload ? payload : xUser
      return {
        ...state,
        user: nx,
        loading: false,
        loaderResendOTP: false
      }
    case UPDATE_USER:
      //var xUser = JSON.parse(localStorage.getItem("user"));
      //var nx = payload ?  : xUser
      return {
        ...state,
        user: payload,
        loading: false,
        loaderResendOTP: false
      }
    default:
      return state
  }
}