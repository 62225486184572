import {
    put,
    call,
    takeLatest,
    takeEvery
  } from 'redux-saga/effects'

  import { 
    SET_LOADING,
    UNSET_LOADING,
    AUTH_REGISTER,
    AUTH_LOGIN,
    AUTH_OTP_VERIFY,
    AUTH_OTP_RESEND,
    AUTH_CONTRACT_LOGIN,
    AUTH_SETUP_NEW_PASSWORD,
    AUTH_LOGOUT,
    SET_USER,
    PROFILE_FETCH,
    SET_OTPRESEND_LOADER,
    UNSET_OTPRESEND_LOADER,
  } from '../actions/auth.action'

  import { 
    CLEAR_MESSAGE,
    SET_MESSAGE
  } from '../actions/message.action'

  import AuthService from "../api/auth.api";


// Login
function* auhtLogin({payload}) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: SET_LOADING })
  console.log('payload',payload);
  const x = yield call(AuthService.login, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("token", x.data.data.token.token);
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_USER , payload: x.data.data.user })
  }
}

// Register
function* auhtRegister({payload}) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: SET_LOADING })
  const x = yield call(AuthService.register, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("token", x.data.data.token.token);
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: SET_USER , payload: x.data.data.user })
  }
}

// OPT Verify
function* authOTPVerify({payload}) {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: SET_LOADING })
  const x = yield call(AuthService.otp_verification, payload)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_LOADING })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.user));
    yield put({ type: SET_USER , payload: x.data.user })
  }
}

// OPT Resend
function* auhtOTPResend() {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: SET_OTPRESEND_LOADER })
  const x = yield call(AuthService.otp_resend)
  console.log('x',x)
  if(x.status && x.status != 200){
    yield put({ type: UNSET_OTPRESEND_LOADER })
    yield put({ type: SET_MESSAGE , payload: {status: x.status , message: x.data.errors && x.data.errors[0] && x.data.errors[0].title ? x.data.errors[0].title : 'General Error'} })
  }else{
    yield put({ type: SET_MESSAGE , payload: {status: 200 , message: 'OPT has been resent to your email address.'} })
    yield put({ type: UNSET_OTPRESEND_LOADER })
  }
}

// OPT Resend
function* fetchProfile() {
  const x = yield call(AuthService.profile)
  console.log('x',x)
  if(x.status && x.status != 200){
  }else{
    localStorage.setItem("user", JSON.stringify(x.data.data.user));
    yield put({ type: SET_USER , payload : x.data.data.user })
  }
}


// Logout
function* authLogout() {
  yield put({ type: CLEAR_MESSAGE })
  yield put({ type: UNSET_OTPRESEND_LOADER })
  yield put({ type: UNSET_LOADING })
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  yield put({ type: SET_USER })
}

// Export the saga 
export default function* authSaga() {
  yield takeEvery(AUTH_LOGIN, auhtLogin)
  yield takeEvery(AUTH_REGISTER, auhtRegister)
  yield takeEvery(AUTH_OTP_VERIFY, authOTPVerify)
  yield takeEvery(AUTH_OTP_RESEND, auhtOTPResend)
  yield takeEvery(AUTH_LOGOUT, authLogout)
  yield takeEvery(PROFILE_FETCH, fetchProfile)
}