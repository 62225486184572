import { 
  SET_SNACKBAR,
  CLEAR_SNACKBAR
} from '../actions/message.action'


const initialState = {
  state: false,
  message: undefined
}
  
export default(state = initialState, {type, payload}) => {
  
  switch(type) {
    // Set message
    case SET_SNACKBAR:
      return {
        ...state,
	      state: true,
	      message: payload.message,
	      redirect: payload.redirect ? payload.redirect : undefined,
      }
    // clear message
    case CLEAR_SNACKBAR:
      return {
        ...state,
        state: false,
	      message: undefined,
	      redirect: undefined,
      }
    default:
      return state
  }
}