import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Link , useParams, useHistory } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CLEAR_SNACKBAR } from '../../redux/actions/message.action';
import { SnackbarContent } from '@mui/material';

function SnackbarUI() {
    const history = useHistory();
    const dispatch = useDispatch();
    const snackbarAction = useSelector((store) => store.snackbar);

    const colors = {
        primary : '#000',
        alert : '',
        attention : '',
        secondary : '#ff0000',
    };


    const snackbarActionRender = (
        <React.Fragment>
         {/*  <Button color="secondary" size="small" onClick={() => { dispatch({type:CLEAR_SNACKBAR}) }}>
            UNDO
          </Button> */}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => { 
              if(snackbarAction.redirect){
                history.push(snackbarAction.redirect);
              }
              dispatch({type:CLEAR_SNACKBAR}) 
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            slots={{ root: 'span' }}
            anchorOrigin={{ vertical : 'bottom', horizontal : 'center' }}
            open={snackbarAction && snackbarAction.state ? snackbarAction.state : false}
            autoHideDuration={6000}
            onClose={() => {
              if(snackbarAction.redirect){
                history.push(snackbarAction.redirect);
              }
              dispatch({type:CLEAR_SNACKBAR})
            }}
        >
        <SnackbarContent 
            style={{
            backgroundColor: '#5E6368',
            color: '#fff',
          }}
          action={snackbarActionRender}
          message={snackbarAction && snackbarAction.message ? snackbarAction.message : ''}
        />
        </Snackbar>
    );
}

export default SnackbarUI;
