import React, { useState , useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../common/forms/input';
//import { login } from "../../redux/actions/auth";
import { SET_RESPONSE , CLEAR_RESPONSE, SET_ERROR_MESSAGE } from "../../redux/actions/types";
import LoaderUI from "../common/Loader";
import  { Redirect , useHistory , useParams} from 'react-router-dom'
import  AlertBox from '../common/AlertBox';
import { AUTH_LOGIN } from '../../redux/actions/auth.action'

function LoginFormAuthBox() {
  // const [username, setUsername] = useState('');
  const history = useHistory();
  const params = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    email : '',
    password : ''
  });
  
  const auth = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const loginUser = (event) => {
    event.preventDefault();
    localStorage.setItem("redirect_to", 'contract_by_token');
    localStorage.setItem("contract_token", params.data);
    formData.contract_token = params.data;
    dispatch({ type: AUTH_LOGIN, payload : formData })
  };

  useEffect(() => {
    
  }, [dispatch]);

  const handleInputChanged = (e) => {
    const { name, value } = e.target;    
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  return (
    <>
      <form className="w-full rounded-md p-10" onSubmit={loginUser}>
          {/* <div>Print : {JSON.stringify(server_response)}</div> */}
          <AlertBox />
        <div className="flex flex-col gap-3">
            <Input type="email" className="rounded-md" placeholder="Email *"
                value={formData.email} name="email" required disabled={(auth.loading) ? "disabled" : ""} onChange={handleInputChanged}
                icon='<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.21 11.761C14.21 12.601 14.449 12.936 15.074 12.936C16.467 12.936 17.354 11.161 17.354 8.209C17.354 3.697 14.066 1.537 9.961 1.537C5.738 1.537 1.897 4.369 1.897 9.721C1.897 14.833 5.257 17.617 10.417 17.617C12.169 17.617 13.345 17.425 15.144 16.825L15.53 18.432C13.754 19.009 11.856 19.176 10.393 19.176C3.625 19.176 0 15.456 0 9.72C0 3.936 4.201 0 9.985 0C16.009 0 19.2 3.6 19.2 8.016C19.2 11.76 18.025 14.616 14.329 14.616C12.648 14.616 11.545 13.944 11.401 12.455C10.969 14.111 9.817 14.616 8.256 14.616C6.168 14.616 4.416 13.007 4.416 9.768C4.416 6.504 5.953 4.488 8.713 4.488C10.177 4.488 11.089 5.064 11.495 5.976L12.192 4.704H14.208V11.761H14.21V11.761ZM11.259 8.593C11.259 7.274 10.274 6.721 9.458 6.721C8.57 6.721 7.587 7.44 7.587 9.553C7.587 11.233 8.331 12.169 9.458 12.169C10.25 12.169 11.259 11.665 11.259 10.273V8.593Z" fill="#6622CC"/></svg>'
            />
            <Input type="password" className="rounded-md" placeholder="Password"
              value={formData.password} name="password" required disabled={(auth.loading) ? "disabled" : ""} onChange={handleInputChanged}
              icon='<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.09765 11.1236C8.74966 11.1249 8.41174 11.2406 8.13585 11.4527C7.85996 11.6648 7.66137 11.9616 7.5706 12.2975C7.47983 12.6335 7.50192 12.9899 7.63346 13.3121C7.765 13.6343 7.99872 13.9044 8.29868 14.0808V16.3462H9.91611V14.0808C10.1534 13.9404 10.3504 13.741 10.4878 13.502C10.6253 13.2629 10.6985 12.9924 10.7005 12.7167V12.7167C10.7005 12.5066 10.6589 12.2987 10.5783 12.1048C10.4976 11.9109 10.3794 11.7348 10.2304 11.5867C10.0815 11.4387 9.90469 11.3215 9.71029 11.2421C9.51588 11.1626 9.30768 11.1223 9.09765 11.1236V11.1236Z" stroke="#6622CC" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0844 7.46487H3.10462C1.94227 7.46487 1 8.40714 1 9.56949V17.8954C1 19.0577 1.94227 20 3.10462 20H15.0844C16.2467 20 17.189 19.0577 17.189 17.8954V9.56949C17.189 8.40714 16.2467 7.46487 15.0844 7.46487Z" stroke="#6622CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.9082 7.46487V6.19333C3.9082 4.81598 4.45536 3.49503 5.4293 2.52109C6.40323 1.54715 7.72418 1 9.10154 1C10.4789 1 11.7998 1.54715 12.7738 2.52109C13.7477 3.49503 14.2949 4.81598 14.2949 6.19333V7.46487" stroke="#6622CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              '
            />
            <button type='subimt' className='bg-primary p-3 text-white rounded-md w-1/4 flex items-center justify-center'>
                {auth.loading ?  
                  <LoaderUI />
                  :
                  <span>login</span>
                }
            </button>
        </div>
    </form>
    </>
    
  );
}

export default LoginFormAuthBox;
