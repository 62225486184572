import http from './http';


const get_all_accounts = async (payload) => {
    try {
        const x = await http.get('/accounts' , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const account_cc_add = async (payload) => {
    try {
        const x = await http.post('/accounts/credit-card', payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};


const account_cc_delete = async (payload) => {
    try {
        const x = await http.delete('/accounts/credit-card/' + payload.id , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};
  

const account_bank_add = async (payload) => {
    try {
        const x = await http.post('/accounts/bank-account', payload , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};

const account_bank_delete = async (payload) => {
    try {
        const x = await http.delete('/accounts/bank-account/' + payload.id , { headers : { 'x-access-token': localStorage.getItem('token') } });
        return x
    } catch(error) {
        return error.response;
    }
};
export default {
    get_all_accounts, 
    account_cc_add,
    account_bank_add,
    account_cc_delete,
    account_bank_delete,
};