import React from 'react';
import HomepageLoginFormGroup from '../common/HomepageLoginFormGroup'
import backgroundImage1 from '../../assets/how-its-work-banner.jpg'
import './HowItsWorksPage.css';
// This is one of our simplest components
// It doesn't have local state,
// It doesn't dispatch any redux actions or display any part of redux state
// or even care what the redux state is'

function HowItsWorksPage() {
  return (
    <div className='h-screen root-page-bg-style' >
        <div className="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto h-screen spliter-bg  spliter-bg-how-its-works  "  >
          <div className="w-full flex justify-between gap-5 h-100-p">
            <HomepageLoginFormGroup className="w-2/5" />
            <div className='w-3/5  right-container flex flex-col content-center justify-start pt-10 bg-primary' style={{backgroundImage:`url(${backgroundImage1})` , backgroundSize: `cover` , backgroundPosition: `right center`}}>
              <div className='w-full flex flex-col gap-3 ps-20'>
                  <ul className='hiw-ul mt-14'>
                    <li>
                        <span className='round-number text-white bg-primary'>1</span>
                        <div className='flex flex-col'>
                          <h1 className='text-2xl font-bold  text-primary'>CREATE</h1>
                          <p className='text-base text-black mb-4 ps-8'>
                            Select a contract from a template library. Autofill your info and easily edit to fit your terms.
                          </p>
                        </div>
                    </li>
                    <li>
                        <span className='round-number text-white bg-primary'>2</span>
                        <div className='flex flex-col'>
                          <h1 className='text-2xl font-bold text-primary'>REVIEW</h1>
                          <p className='text-base text-black mb-4 ps-8'>
                            Open up or lockdown parts of your contract to control what others can edit, the TradeOut will send the contract to the other party.
                          </p>
                        </div>
                    </li>
                    <li>
                        <span className='round-number text-white bg-primary'>3</span>
                        <div className='flex flex-col'>
                          <h1 className='text-2xl font-bold text-primary'>REVISE</h1>
                          <p className='text-base text-black mb-4 ps-8'>
                            Edit in real-time without having to resend the contract. Chat within the contract and receive notifications whenever changes are made.
                          </p>
                        </div>
                    </li>
                    <li className='pb-10'>
                        <span className='round-number text-white bg-primary'>4</span>
                        <div className='flex flex-col'>
                          <h1 className='text-2xl font-bold text-primary'>SIGN</h1>
                          <p className='text-base text-black mb-4 ps-8'>
                          Sign digitally!
                          </p>
                        </div>
                    </li>
                    <li>
                        <span className='round-number text-white bg-primary'>5</span>
                        <div className='flex flex-col'>
                          <h1 className='text-2xl font-bold text-primary'>TRADE</h1>
                          <p className='text-base text-black mb-4 ps-8'>
                          Arrange the final steps through TradeOut, use the checklist to verify everyone is satisfied, everything will automatically save to your account.
                          </p>
                        </div>
                    </li>
                  </ul>
              </div>            
            </div>
          </div> 
        </div>
      </div>
  );
}

export default HowItsWorksPage;
