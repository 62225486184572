import { combineReducers } from 'redux';
import { 
    A_CONTRACT_ACTIVE_STATE, A_HANDOFF_ACTIVE_STATE, DATA_ALL_CONTRACT, 
    CLEAR_SINGLE_CONTRACT,
    DATA_SINGLE_CONTRACT,
    DATA_SINGLE_CONTRACT_SELLER,
    DATA_SINGLE_CONTRACT_BUYER, 
    DATA_HANDOFF_CONTRACT
} from '../actions/contracts.action';

const editContract = {
    status: 'created_but_not_sent'
};

const photoTemplate = {
    'note' : '',
    'url' : null
};



const arrayFill = (num) => {
    var x = [];
    for(var i = 0 ; i < num ; i++){
        x.push(photoTemplate);
    }
    return x;
}

const handoffContractDetail = {
    physical_inspection : false,
    working_condition : false,
    sr_no_or_id_code_state : false,
    sr_no_or_id_code : "",
    photos : arrayFill(5),
    /* buyer_physical_inspection : false,
    buyer_working_condition : false,
    buyer_sr_no_or_id_code_state : false,
    buyer_sr_no_or_id_code : "",
    buyer_photos : [], */
    seller_exhange_confrim : false,
    buyer_exhange_confrim : false,
};

const getSeller = {};
const getBuyer = {};

const contractActiveState = 'creating';
const handoffActiveState = 'checklist';

const allContracts = [];

const edit_contract = (state = editContract , action) => {
    const {type , payload} = action;
    switch (type) {
        case CLEAR_SINGLE_CONTRACT:
            var x = {...editContract};
            return x;
        case DATA_SINGLE_CONTRACT:
            var x = {...state};
            if(payload){
                Object.entries(payload).forEach(([key, value]) => {
                    x[key] = value;
                });
            }
            return x;
        default:
          return state;
    }
}

const handoff_contract_detail = (state = handoffContractDetail , action) => {
    const {type , payload} = action;
    switch (type) {
        case DATA_HANDOFF_CONTRACT:
            var x = {...state};
            if(payload){
                Object.entries(payload).forEach(([key, value]) => {
                    x[key] = value;
                });
            }
            return x;
        default:
          return state;
    }
}

const get_seller = (state = getSeller , action) => {
    const {type , payload} = action;
    switch (type) {
        case DATA_SINGLE_CONTRACT_SELLER:
            var x = {...state};
            if(payload){
                Object.entries(payload).forEach(([key, value]) => {
                    x[key] = value;
                });
            }
            return x;
        default:
          return state;
    }
}

const get_buyer = (state = getBuyer , action) => {
    const {type , payload} = action;
    switch (type) {
        case DATA_SINGLE_CONTRACT_BUYER:
            var x = {...state};
            if(payload){
                Object.entries(payload).forEach(([key, value]) => {
                    x[key] = value;
                });
            }
            return x;
        default:
          return state;
    }
}

const all_contracts = (state = allContracts , action) => {
    const {type , payload} = action;
    switch (type) {
        case DATA_ALL_CONTRACT:
            return payload
        default:
          return state;
    }
}


const contract_active_state = (state = contractActiveState , action) => {
    const {type , payload} = action;
    switch (type) {
        case A_CONTRACT_ACTIVE_STATE:
            return payload ? payload.state : 'creating';
        default:
          return state;
    }
}
const handoff_active_state = (state = handoffActiveState , action) => {
    const {type , payload} = action;
    switch (type) {
        case A_HANDOFF_ACTIVE_STATE:
            return payload ? payload.state : 'checklist';
        default:
          return state;
    }
}


export default combineReducers({
    edit_contract,
    get_buyer,
    get_seller,
    all_contracts,
    contract_active_state,
    handoff_active_state,
    handoff_contract_detail,
});
