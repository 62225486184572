import React from 'react';
import { Route, Redirect , useHistory } from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import {useSelector} from 'react-redux';
import OTPPage from '../OTPPage/OTPPage';
import ProfileVerification from '../ProfileVerification/ProfileVerification';
import AuthService from './../../redux/api/auth.api';
// A Custom Wrapper Component -- This will keep our code DRY.
// Responsible for watching redux state, and returning an appropriate component
// API for this component is the same as a regular route

// THIS IS NOT SECURITY! That must be done on the server
// A malicious user could change the code and see any view
// so your server-side route must implement real security
// by checking req.isAuthenticated for authentication
// and by checking req.user for authorization


function ProtectedRoute({ component, children, ...props }) {
  const history = useHistory();
  const user = useSelector((store) => store.auth.user);
  if(!user) {
    history.push('/')
  }

  /* AuthService.profile().then( (x) => {
    console.log('called x', x)
  } ); */

  // Component may be passed in as a "component" prop,
  // or as a child component.
  const ProtectedComponent = component || (() => children);

  var protectedRP = <OTPPage/>;
  if(user && user.is_email_varified){
    /* if(user.is_profile_verified){ */
    console.log('props', props.path)
      if(user.profile_verified){
        console.log('i am here')
        protectedRP = <ProtectedComponent />;
        var checkRedirect = localStorage.getItem("redirect_to");
        if(checkRedirect) {
          localStorage.setItem("redirect_to", null);
          if(checkRedirect == 'contract_by_token'){
            var contract_token = localStorage.getItem("contract_token");
            localStorage.setItem("contract_token", null);
            if(contract_token) history.push('/contract/view/' + contract_token)
          }
        }
      }else{
        if(props.path.includes('settings') || props.path.includes('help-and-faqs')){
          protectedRP = <ProtectedComponent />
        }else{
          protectedRP = <ProfileVerification />
        }
      }
      
    /* }else{
      // Verifi the Profile
      var protectedRP = <ProfileVerification/>;
    } */
    
  }
  
  return (
    <Route
      // all props like 'exact' and 'path' that were passed in
      // are now passed along to the 'Route' Component
      {...props}
    > 
      
      {user && user.id  ?
          protectedRP
        :
        // Otherwise, redirect to the Loginpage
          <LoginPage />
      }
    </Route>

  );
}

export default ProtectedRoute;
