
function RegisterConcentFormContent() {
    return (    
        <div className="content-page-static">
            <h3 className="text-center"><strong>TradeOut Customer Terms of Service</strong></h3>
            <p className="text-center">Effective September 20, 2023</p>
            <br/>
            <div className="flex gap-5"><span>1.</span><strong>Terms of Use</strong></div>
            <p>These Terms of Service, along with any other documents incorporated by reference are a contract between TradeOut LLC (collectively with its affiliates, successors and assigns, “TradeOut,” “we,” “us,” or “our,”) and the “User” (collectively, “you” or “your”). The contract services (“Services”) provided by TradeOut are owned and controlled by TradeOut. TradeOut provides a service where Users can contract to purchase or sell items found on marketplace applications (“Bill of Sale”) to enhance security and reduce common online scams. Your use of the Services to form contracts on your purchases and sales constitutes your agreement to all of the following terms, conditions, and notices (“Terms of Service”). Use of TradeOut’s website (“Site”) is governed by its Terms of Use (“Terms of Use”) and Privacy Policy (“Privacy Policy”), as found on the Site. If you do not agree with these Terms of Service, the Terms of Use, or the Privacy Policy, your sole remedy is to discontinue using the Site and Services. The Site and Services are not intended for use by individuals under the age of 18, and all Users must be a legal age to contract and use the Services. The Terms of Service, Terms of Use, and Privacy Policy take precedent over any Bill of Sale. </p>
            <p><strong>These Terms of Service, along with any other documents incorporated by reference are a contract between TradeOut LLC (collectively with its affiliates, successors and assigns, “TradeOut,” “we,” “us,” or “our,”) and the “User” (collectively, “you” or “your”). The contract services (“Services”) provided by TradeOut are owned and controlled by TradeOut. TradeOut provides a service where Users can contract to purchase or sell items found on marketplace applications (“Bill of Sale”) to enhance security and reduce common online scams. Your use of the Services to form contracts on your purchases and sales constitutes your agreement to all of the following terms, conditions, and notices (“Terms of Service”). Use of TradeOut’s website (“Site”) is governed by its Terms of Use (“Terms of Use”) and Privacy Policy (“Privacy Policy”), as found on the Site. If you do not agree with these Terms of Service, the Terms of Use, or the Privacy Policy, your sole remedy is to discontinue using the Site and Services. The Site and Services are not intended for use by individuals under the age of 18, and all Users must be a legal age to contract and use the Services. The Terms of Service, Terms of Use, and Privacy Policy take precedent over any Bill of Sale. </strong></p>
            
            <br/>
            <div className="flex gap-5"><span>1.</span><strong>Definitions</strong></div>
            <p>
            The following terms have their assigned meanings when used in the Terms of Service, and the terms in the preamble have the meanings assigned there:
            <br/> “Account” means a personal TradeOut account opened by a User on the Platform.
            <br/> “Arbitrator” has the meaning provided in Paragraph 16.
            <br/> “Authorized User” means a person designated by Buyer or Seller to fulfill the designating User’s obligations in an Exchange. 
            <br/> “Buyer” means a person purchasing Property in a Transaction.
            <br/> “Claim” has the meaning provide in Paragraph 16.
            <br/> “Exchange” means when the Buyer and Seller or any Authorized User of Buyer or Seller meet up at an agreed-upon location for the Seller to transfer the Property included in the Bill of Sale to Buyer.
            <br/> “Payment” means the electronic payment made by Buyer to Seller as agreed in the Bill of Sale.
            <br/> “Platform” means the Tradeout platform used to facilitate the Services.
            <br/> “Price Range” has the meaning provided in Paragraph 5.
            <br/> “Property” means any good(s) and item(s) transferred from Seller to Buyer in an Exchange, as agreed in the Bill of Sale.
            </p>
            
            <div className="flex gap-5"><span>1.</span><strong>Consent to Electronic Delivery of communications and E-Sign Disclosures</strong></div>
            <p>
            By consenting to the Terms of Service, User agrees that TradeOut may, at TradeOut’s option, provide communications to User in electronic form, including making them available to User in electronic form. TradeOut may do so by: (1) posting or making the communication available in User’s Account online; (2) posting the communication on the Platform or Site; (3) sending the communications to the email address User provide to us or by internal message service; or (4) sending User an email, text message, or push notification to the telephone number User provide to us. Technology provider may charge User fees or other costs for sending or receiving communications by email or text message. User agree that User are responsible for all such fees and costs. Any communications that TradeOut send User or that User send us may not be encrypted. The communications may include User’s name and other information pertaining to User’s Account or Loan.
            <br/>By agreeing that TradeOut may provide communications to User in electronic form, User are also agreeing that TradeOut are not required to provide User with a paper version of the communication unless User ask us for a paper copy, as described below in the section called “How to Request Paper Copies.” User agree that a communication is considered “delivered” to User and received by User on the date it is posted, made available, or sent to User as provided in this Agreement.
            <br/>Important: If User want to receive online (electronic) Account statements instead of paper statements in the mail, User must enroll for them by going to the Platform and following the online instructions.
            </p>

            <p>
            <u>Applicability of this Consent</u>
            User consent in this section called “Consent to Electronic Delivery of communications and E-Sign Disclosures” applies to: (1) all communications relating to the opening of User’s Account; (2) User’s use of User’s Account; (3) User’s use of the Services; (4) User’s Transactions and Bill of Sale(s); and (5) any other communications relating to User’s Account or Loan that User ask for or agree to receive electronically.
            </p>
            <p>
            <u>User Consent to Electronic Delivery of Communications </u>

            User agree that all communications covered by this Agreement will be provided to User or made available to User in electronic form. After viewing a communication, User will be able to download and/or print it for User’s records. User further agree that TradeOut may deliver communications to User by email, digital banking secure mail, text message, or push notification. In so doing, User agree that TradeOut may, but
            <br/>are not required to, send all email communications to User at the email address that User have provided to us most recently and that TradeOut maintain in our records.
            <br/>User consent to electronic delivery of communications is effective until User withdraw it. 

            </p>

            <p>
            <u>How to Withdraw User Consent for Electronic Delivery</u>

            User have the right to withdraw User’s consent to receive communications in electronic form at any time. 
            <br/>If User withdraw User’s consent for electronic delivery, User cannot use the Services. Withdrawal of User’s consent will have no legal impact on the effectiveness, validity, or enforceability of any communications that TradeOut delivered to User in electronic form before the effective date of User’s withdrawal. If User wishes to withdraw User’s consent, User must contact TradeOut.

            </p>

            <p>
            <u>How to Update User Contact Information</u>
            User agree that User will notify TradeOut of any changes in User’s email address or telephone number. User may change User’s email address or telephone number by changing User’s profile within the Platform. User may also notify TradeOut of any such changes by contacting TradeOut.
            </p>

            <p>
            <u>How to Request Paper Copies </u>
            User may ask us to mail User a paper copy of any communication provided or made available to User in electronic form, and TradeOut will provide the paper copy. To request a paper copy of any communication, contact TradeOut at 651-243-0144. TradeOut may charge a fee for a paper copy. 
            </p>

            
            <p>
            <u>Device Requirements</u>
            In order to access and retain communications provided or made available to User in electronic form, User must have the ability to operate the most recent or prior version of Windows, Mac OS X, iOS, or Android. Additionally, User’s operating system must support the most recent or prior version of Microsoft Edge, Mozilla Firefox, Safari, or Google Chrome. User will also need the most recent or prior version of a Portable Document Format (“PDF”) reader. By consenting, User confirms that User has the ability to view communications electronically in PDF and that User’s intended use for the Services meets the requirements described in this Terms of Service. For maximum security, we do not share Account user name and passwords or access codes with persons User does not authorize to access User’s Account, or Transaction information. For optimal function, always use the latest version of software for User’s device. Beta versions of browsers and operating systems are not recommended or supported.
            <br/> If a change in requirements for User’s device is needed for User to access and retain communications delivered to User in electronic form, TradeOut will notify User of the revised requirements for access or retention. At that time, User will have the right to withdraw User’s consent. See the heading above called “How to Withdraw User Consent for Electronic Delivery” for a description of the fees and consequences if User withdraw User’s consent.

            </p>

            <strong>4.	Transaction Process</strong>
            <u>Contract Formation</u>
            <p>To use the Services, the Buyer and the Seller must have or create an Account. Any User may initiate a new deal through the Platform to create a draft Bill of Sale by logging into their Account and following the prompts given by the Platform. In beginning a Transaction, Users acknowledge they are not receiving legal advice from TradeOut or the Services, and they are responsible for paying any taxes as a result of the exchange. Further, the User acknowledges they are the legal age to contract in their state. After initiating a Transaction, the User shall invite the other User to the Bill of Sale within the Platform. Both Users must sign and agree to the terms created through the Bill of Sale before an Exchange. </p>
            <u>Exchange Process</u>
            <p>The Exchange must occur within [7] days of execution of the Bill of Sale. On the date of the Exchange, Buyer and Seller shall meet at an agreed-upon location. TradeOut recommends the Parties meet for the Exchange at a public and safe location. When the Property has been exchanged, both Parties shall notify TradeOut of the Transaction through the “Confirm Item Exchange” function on the Platform. Users must document and upload pictures and videos of the Exchange to the Platform alongside with the Bill of Sale. The Parties shall share the secure code received from TradeOut to verify the Exchange and Transaction is complete.</p>
            <p>In the event Buyer or Seller is unable to meet for the Exchange, they may delegate their obligation to an Authorized User. The Authorized User must be a User that has agreed to this Terms of Service and is designated in the Transaction to complete the Exchange. The Authorized User will then have access to the Transaction for the Exchange verification. The Authorized User is responsible for fulfilling their designated obligations and must comply with this Terms of Service, the Terms of Use, and the Privacy Policy. </p>
            
            <strong>5.	Prohibited Uses</strong>
            <p>TradeOut allows for the Transactions of goods from $100 to $3,000 (“Price-Range”). The Services cannot be used to enter into Transactions exceeding the Price-Range or Transactions for services. TradeOut prohibits any Transactions that involve the sale or exchange of the following items: (1) counterfeit and infringing products; (2) firearms, knives, and weapons; (3) dangerous or hazardous materials, including but not limited to, aerosols and ozone-depleting substances, fuels, combustibles, poisons, pesticides, radioactive materials, or toxic plant materials; (4) vehicles, motorcycles, boats, trailers, or other licensed property; (5) real property and real estate; (6) food and drinks; (7) tobacco and alcohol; (8) marijuana, drugs and drug paraphernalia; (9) medical devices and prescription items; (10) items that have been recalled by governments or manufacturers; (11) animals, and animal products; (12) illegal or stolen items; (13) name brand shoes; (14) adult content and materials, including pornography or sex toys; (15) telecommunications manipulation equipment, including jamming devices; and (16) any items that are prohibited items on any marketplace from which the deal originated. The Service is not a marketplace.</p>
            <p>If a User has any questions regarding the prohibited items listed above, please reach out to TradeOut Customer Service at 651-243-0144 or help@tradeout.us for further guidance.</p>
            <p>The Service is not a marketplace.</p>

            <strong>6.	Internal Messaging Service</strong>
            <p>TradeOut provides an internal messaging service for the convenience of finalizing deals and agreements through the Platform. The internal messages will be available for use until the Exchange is completed.</p>
            <p>TradeOut messaging services may not be used for unlawful, discriminatory, fraudulent, or offensive communications. Additionally communications must not be used in any way that violates someone else’s intellectual property rights or to share content that the User does not own or have the rights to share.</p>
            <p>TradeOut reserves the right to suspend, terminate or otherwise limit a User’s access to the Platform for communications and messaging that violates these terms.   </p>
            <p>Users may report conduct or communications taking place on the TradeOut messaging service to TradeOut by emailing help@tradout.us. Upon receipt of the report TradeOut shall review the conversation in question and issue a decision on whether to restrict the violating User’s access to the Platform. Users may not misuse or abuse the reporting feature. TradeOut reserves the right to limit Users access to any content or features on Platform if doing so is reasonably necessary to avoid misuse of the Services.</p>

            <strong>7.	Identity Verification</strong>
            <p>In order to create an Account and access the Services, User must provide identifying information, including legal name, address, telephone number, and email address, and Stripe will use biometric technology and other sources to confirm a User’s identity and for fraud and security purposes. By agreeing to identity verification, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time as found at: https://stripe.com/legal/ssa. By providing this information, User authorizes TradeOut to share the identifying information to other Users in the Transaction only in the event of a Transaction Dispute where a User looks to enforce a court or other legal remedy under the Bill of Sale. TradeOut reserves the right to provide any identifying information to law enforcement, and will do so with their own discretion. </p>

            <strong>8.	Use of Platform and Services </strong>
            <p>By using the Platform and the Services, Users represent, warrant, and covenant to TradeOut as follows:</p>
            <ul>
            <li>Users shall comply with all provisions of these Terms of Service in all respects relating to the Services and the Platform.</li>  
            <li>Users shall comply in all respects with all federal, state, local or administrative law (including common law), statute, rule or regulation, regulatory guidance, or court order applicable to the sale and purchase of Property.</li>
            <li>Users shall use the Platform and the Services for the sole purpose of contracting for the sale, purchase, and exchange of Property.</li>
            <li>Seller warrants and represents that Seller has lawful title to and ownership of the Property sold to Buyer.</li>

            </ul>

            <strong>9.	Electronic Communication </strong>
            <p>TradeOut may contact Users through telephone dialing, text messaging systems, voicemail systems, electronic mail, and automated messages regarding Bills of Sales, the Platform, Transactions, Transaction Disputes and Claims (“Communication”). By sharing contact information, User gives TradeOut permission to use Communications to contact User. User will be responsible for any incurred charges resulting from any Communication. User will also be responsible for ensuring that TradeOut has the correct and updated contact information, including email addresses and phone numbers. Users may update contact information by accessing their Account on the Platform.</p>
            <p>User acknowledges that the Communication received may also include copies of Account data and Bills of Sale. By agreeing to receive Communication electronically, TradeOut is not responsible for providing User any paper copies of Communication or encrypting its messages. User may revoke consent to electronic communication by notifying TradeOut Customer Support. In the event User revokes consent to electronic Communication, TradeOut may terminate or suspend User’s account and access to the Services. User may not open a new Account on the Platform without consenting to electronic delivery of Communication. </p>

            <strong>10.	Termination and Suspension of Service</strong>
            <p>In the event User breaches these Terms of Service, TradeOut may terminate or suspend a User’s account and their access to the Services and Platform. TradeOut may terminate or suspend a User for selling prohibited Property; repeatedly cancelling Transactions; violating the internal messaging rules; failing to provide proper identification information; fraud; misusing the Services; filing multiple Transaction Disputes without merit; multiple Buyer complaints about Property or Exchanges; degrading the security, stability or reliability of the Platform; or any other misconduct or illegal behavior. TradeOut reserves the right to make termination and suspension decisions within their sole discretion based off User’s conduct. By agreeing to these Terms of Service, User acknowledges that they may be terminated or suspended at any time, and that they may no longer have access to their Account. </p>
            <p>User may terminate their Account by un-enrolling from the Platform. To do so, User may contact TradeOut, or may go to their Account settings in the Platform. By un-enrolling from the Platform, User agrees they will no longer have access to the Platform, or information contained thereon, or the Services. These Terms of Service will survive, and will continue in full force and effect, even if User un-enrolls from the Services.</p>

            <strong>11.	Disclaimer of Warranties and Limits of Liability</strong>        
            <p><strong>For purposes of this section, the “TradeOut Parties” means: (1) TradeOut; (2) TradeOut’s employees, officers, and directors; (3) TradeOut’s agents, licensors, independent contractors, and subcontractors; and (4) third party service providers that the TradeOut Parties use. </strong></p>          
            <p><strong>The Services and Platform are provided AS-IS. The TradeOut Parties make no warranties of any kind for the Services or Platform. This includes both express warranties and implied warranties. Implied warranties include implied warranties of merchantability and fitness for a particular purpose. A warranty is a guarantee that the Services or Platform will meet certain standards. No license to User is implied by these disclaimers.</strong></p>          
            <p><strong>The TradeOut Parties do not warrant that the Services or Platform will be uninterrupted or error free, or that defects will be corrected. They also do not warrant that the Services or Platform is free of viruses or other harmful components. They also do not warrant the timeliness, accuracy, reliability or completeness of the Services or Platform. Any dated information is published as of that date only, and TradeOut Related Parties do not undertake any obligation or responsibility to update or amend any such information.</strong></p>          
            <p><strong>Without limitation, the TradeOut Parties will not be liable to User for any loss, claim, injury, or damage in connection with: (1) these Terms of Service and Terms of Use; (2) User’s use of the Platform and Services or inability to use the Platform and Services; (3) any problems with the communications between User and us; (4) any unauthorized interception of the communications between User and us;(5) any problems with the communications between Users through the use of our messaging software; (6) any computer virus; or (7) the actions or inactions of any Buyers, Sellers, Users, or third parties. This limitation on the TradeOut Parties’ liability applies, even if any of them are aware of the possibility that any events described in (1) through (7) could occur. </strong></p>          
            <p><strong>In no event will any of the TradeOut Parties be liable to User for any incidental, consequential, special, indirect, or punitive damages. This limitation of liability applies, whether User bases an action against the TradeOut Parties on a contract; a warranty; a tort (such as claim of negligence, recklessness, or intentional act); or any other theory. This limitation of liability applies even if any of the TradeOut Parties know about the possibility of such damages. Therefore, by way of example, the TradeOut Parties will not be liable to User for lost data, lost profits or goodwill, investment losses, finance charges, lost opportunities, cost of obtaining substitute services, or other economic damages.</strong></p>          
            <p><strong>The laws of some states may prohibit the limitation of liability for consequential damages, or incidental damages, or other kinds of damages. In those states, to the extent their laws apply, the limitation of liability with respect to consequential damages, or incidental damages (or other damages specified by law in such states), may not apply.</strong></p>          
            
            <strong>12.	User Data Retention</strong>
            <p>TradeOut will retain Bill of Sale and Transaction data for internal purposes, regulatory compliance, and other purposes. Users should retain copies of all Bills of Sale and any Transaction information.  Transaction information will be maintained under a User’s Account for 60 days after completion of the Transaction. After 60 days, Users may request that TradeOut Provide copies of a Bill of Sale. Users will be charged a $50 fee to access any archived Bills of Sale stored by TradeOut. All Bills of Sale will be deleted after one year from the Transaction completion date.</p>

            <strong>13.	Severability</strong>
            <p>If any provision of these Terms of Service is determined to be unenforceable, or invalid, such determination shall not affect the validity or enforceability of the remaining provisions.</p>

            <strong>14.	Right to Modify</strong>
            <p>TradeOut reserves the right to modify these Terms of Service at any time without notice. However, the most current version of these Terms of Service will always be posted on the Platform and available by clicking the link at the bottom of the webpage. By continuing to use the Services and Platform after these Terms of Service have been modified and within the Platform, User agrees to be bound by the Terms of Service as modified.</p>

        <strong>15.	Governing Law</strong>
        <strong>Users access to and use of the Services and Platform are governed by and will be construed in accordance with applicable the laws of the State of Minnesota, without regard to the principles of conflicts of laws.</strong>

        <strong>16.	Arbitration Provision</strong>
        <p>The following arbitration provision (“Provision”) applies to any claims that Users may have against TradeOut or any claims that TradeOut may have against Users under the Service or these Terms of Service.</p>


        <div className="table-dis">
            <h4>Background and Scope</h4>
            <table>
            <tr>
                <th width="200">Question</th>
                <th width="200">Short Answer</th>
                <th>Further Detail</th>
            </tr>
            <tbody>
                <tr>
                    <td>What is arbitration?</td>
                    <td>An alternative to court</td>
                    <td>In arbitration, a neutral third-party arbitrator (“Arbitrator”) solves a dispute in an informal hearing on an individual basis.</td>
                </tr>
                <tr>
                    <td>Is it different from court and jury trials?</td>
                    <td>Yes</td>
                    <td>The hearing is private. There is no jury. It is usually less formal, faster, and less expensive than a court lawsuit. Pre-hearing fact finding is limited. Appeals are limited. Courts rarely overturn arbitration awards.</td>
                </tr>
                <tr>
                    <td>Can you opt-out of this Provision?</td>
                    <td>Yes, within 60 days</td>
                    <td>If you do not want this Provision to apply, you must send us a signed notice within 30 calendar days after you agree to these Terms of Service. You must send the notice in writing (and not electronically) to TradeOut, LLC, P.O. Box 4522, St. Paul, MN 55101, Attention: Arbitration Opt-Out. Provide your name, address, and email address. State that you “opt out” of the Provision. Opting out will not affect the other Provisions of these Terms of Service. </td>
                </tr>
                <tr>
                    <td>What is this Provision about?</td>
                    <td>The parties' agreement to arbitrate Claims</td>
                    <td>Unless prohibited by applicable law and unless you opt out, you and we agree that you or we may elect to arbitrate individually or require individual arbitration of any “Claim” as defined below.</td>
                </tr>
                <tr>
                    <td>Who does the Provision cover?</td>
                    <td>You, us and certain “Related Parties” </td>
                    <td>This Provision governs you and us, your heirs and our agents. It also covers certain “Related Parties”: (1) the parents, subsidiaries and affiliates of ours or any other Related User; (2) any third party that may deliver Property on your behalf; (3) the employees, directors, officers, shareholders, members and representatives of ours or any Related User; (4) the successors and assigns of ours or any Related User; and (5) any person or company that is involved in a Claim you pursue at the same time you pursue a related Claim with us. </td>
                </tr>
                <tr>
                    <td>What Claims does the Provision cover?</td>
                    <td>All Claims (except certain Claims about this Provision)</td>
                    <td>This Provision governs all “Claims” that would usually be decided in court and are between us (or any Related User) and you. In this Provision, the word “Claims” has the broadest reasonable meaning. It includes all claims even indirectly related to your purchase on credit, the Product or these Terms of Service (including without limitation your application, your Account, past Transactions, information you previously gave us, payments, privacy and customer information) or the relationships between you and us and/or “Related Parties” resulting therefrom.  “Claims” includes, but is not limited to, initial claims, counterclaims, cross-claims and third-party claims, and claims based on any constitution, statute, regulation, ordinance, common law rule (including rules relating to contracts, torts, negligence, fraud or other intentional wrongs) and equity. It includes claims that seek relief of any type, including damages and/or injunctive, declaratory or other equitable relief.  It includes claims related to the validity in general of these Terms of Service. However, it does not include disputes about the validity, coverage or scope of this Provision or any part of this Provision. (This includes a Claim about the rules against class arbitration and against an Arbitrator issuing relief to anyone except you, us and Related Parties.) All such disputes are for a court and not an Arbitrator to decide.  But a Claim about these Terms of Service as a whole is for an Arbitrator, not a court, to decide.</td>
                </tr>
                <tr>
                    <td>Who handles the arbitration?</td>
                    <td>Usually AAA or NAM</td>
                    <td>Arbitrations are conducted under this Provision and the rules of the arbitration administrator in effect when the arbitration is started. The arbitration administrator will be either:
                        ●	The American Arbitration Association (“AAA”), 120 Broadway, 21st Floor, New York, NY 10271, www.adr.org.
                        ●	National Arbitration and Mediation (“NAM”), 122 East 42nd Street, Suite 803, New York, NY 10168, www.namadr.com
                        ●	Any other company picked by agreement of the parties.
                        If all the above options are unavailable, a court with jurisdiction will pick the administrator. No arbitration may be administered without our consent by any administrator that would permit a class arbitration under this Provision. The Arbitrator will be selected under the administrator's rules. However, the Arbitrator must be a lawyer with at least ten years of experience or a retired judge unless you and we otherwise agree.
                    </td>
                </tr>
                <tr>
                    <td>Can Claims be litigated?</td>
                    <td>Sometimes</td>
                    <td>Either party may bring a lawsuit if the other party does not demand arbitration. We will not demand arbitration of any lawsuit you bring as an individual action in small-claims court or an equivalent court, so long as the Claim is pending only in that court. Both parties may use lawful self-help remedies. This includes set-off. Both parties may seek remedies which don't claim money damages. This includes pre-judgment seizure, injunctions, or equitable relief.  Even if all parties have opted to litigate a Claim in court, you or we may elect arbitration with respect to any Claim made by a new party or any Claim later asserted by a party in that or any related or unrelated lawsuit (including a Claim initially asserted on an individual basis but modified to be asserted on a class, representative or multi-party basis).  Nothing in that litigation shall constitute a waiver of any rights under this Provision.</td>
                </tr>
                <tr>
                    <td>Are you and we giving up any rights?</td>
                    <td>Yes </td>
                    <td>
                        <b>
                            For Claim that are arbitrated under this Provision, you and we give up our rights to:
                            <ul>
                            <li>Have juries decide Claims.</li>
                            <li>Have courts, other than small-claims courts, decide Claims.</li>
                            <li>Serve as a private attorney general or in a representative capacity in court or in arbitration.</li>
                            <li>Join a Claim that you, we or Related Parties have with a dispute that others have.</li>
                            <li>Bring or be a class member in a class action in court or in a class arbitration.</li>
                            </ul>	
                            The arbitrator shall have no authority to conduct any arbitration inconsistent with this section or to issue any relief that applies to any person or entity except you or us or Related Parties individually.
                        </b>
                    </td>
                </tr>
                <tr>
                    <td>Can you or another consumer start a class arbitration?</td>
                    <td>No</td>
                    <td><b>The Arbitrator is not allowed to handle any Claim on a class or representative basis.</b> All Claims subject to this Provision must be decided in an <b>individual</b> arbitration or an individual small-claims action. You may not pursue any type of collective action or class action against us in arbitration. </td>
                </tr>
                <tr>
                    <td>What law applies?</td>
                    <td>The Federal Arbitration Act (“FAA”) </td>
                    <td>This Agreement and the purchase involve interstate commerce. Thus, the FAA governs this Provision. The Arbitrator must apply substantive law consistent with the FAA. The Arbitrator must honor statutes of limitation and privilege rights.  The Arbitrator is authorized to award all remedies permitted by applicable substantive law, including, without limitation, compensatory, statutory and punitive damages (subject to constitutional limits that would apply in court), declaratory, injunctive and other equitable relief, and attorneys' fees and costs.  In the event of any conflict or inconsistency between this Provision and the administrator's rules or the Terms of Service, this Provision will govern.</td>
                </tr>
                <tr>
                    <td>Will this Clause continue to govern?</td>
                    <td>Yes, unless otherwise agreed.</td>
                    <td>The Provision stays effective unless the parties sign an agreement stating it doesn't or you timely opt out by the process described below. The Clause governs if you rescind the transaction. It governs if you default, renew, prepay, or pay. It governs if your contract is discharged through bankruptcy. The Provision remains effective, despite a transaction's termination, amendment, expiration, or performance.</td>
                </tr>
                <tr className="full-row">
                    <td colSpan="3">Process</td>
                </tr>
                <tr>
                    <td>What must a party do before starting a lawsuit or arbitration?</td>
                    <td>Send a written Claim notice and work to resolve the Claim.</td>
                    <td>We can try to resolve Claims if you call us at 651-243-0144. If this doesn't resolve the Claim, before starting a lawsuit or arbitration, the complaining party must give the other party written notice of the Claim. The notice must explain in reasonable detail the nature of the Claim and any supporting facts. If you are the complaining party, you must send the notice in writing (and not electronically) to TradeOut, LLC, P.O. Box 4522, St. Paul, MN 55101, Attention: Claims. You or an attorney you have personally hired must sign the notice and must provide the Account information and a phone number where you (or your attorney) can be reached. A letter from us to you will serve as our written notice of a Claim. Once a Claim notice is sent, the complaining party must give the other party a reasonable opportunity over the next 30 days to resolve the Claim on an individual basis.</td>
                </tr>
                <tr>
                    <td>How does an arbitration start?</td>
                    <td>Mailing a notice</td>
                    <td>If the parties do not reach an agreement to resolve the Claim within 30 days after notice of the Claim is received, the complaining party may commence a lawsuit or an arbitration, subject to the terms of this Provision. To start an arbitration, the complaining party picks the administrator and follows the administrator's rules. If one party begins or threatens a lawsuit, the other party can demand arbitration. This demand can be made in court papers, such as a motion to compel arbitration. Once an arbitration demand is made, no lawsuit can be brought and any existing lawsuit must stop unless a court rules otherwise.  </td>
                </tr>
                <tr>
                    <td>Will any hearing be held nearby? </td>
                    <td>Yes </td>
                    <td>The Arbitrator may decide that an in-person hearing is unnecessary and that he or she can resolve a Claim based on written filings and/or a conference call. However, any in-person arbitration hearing shall take place in a location that is reasonably near your residence or in another location agreed to by you and us.</td>
                </tr>
                <tr>
                    <td>What about appeals?</td>
                    <td>Very limited </td>
                    <td>Appeal rights under the FAA are very limited. The Arbitrator's award will be final and binding. Any appropriate court may enter judgment upon the Arbitrator's award. No arbitration award involving the parties will have any preclusive effect as to issues or claims in any dispute involving anyone who is not a party to the arbitration, nor will an arbitration award in prior disputes involving other parties have preclusive effect in an arbitration between the parties to this Provision.</td>
                </tr>
                <tr className="full-row">
                    <td colSpan="3">Arbitration Fees and Awards</td>
                </tr>
                <tr>
                    <td>Who bears arbitration fees?</td>
                    <td>Usually, we do. </td>
                    <td>We will pay all filing, administrative, hearing and Arbitrator fees if you act in good faith, cannot get a waiver of such fees and ask us to pay. We will always pay amounts required under applicable law or the administrator's rules or if payment is required to enforce this Provision.</td>
                </tr>
                <tr>
                    <td>When will we cover your legal fees and costs?</td>
                    <td>If you win </td>
                    <td>If you win an arbitration, we will pay the reasonable fees and costs for your attorneys, experts and witnesses. We will also pay these amounts if required under applicable law or the administrator's rules or if payment is required to enforce this Provision. The Arbitrator shall not limit his or her award of these amounts because your Claim is for a small amount.</td>
                </tr>
                <tr>
                    <td>Will you ever owe us for arbitration or attorneys' fees?</td>
                    <td>Only for bad faith </td>
                    <td>The Arbitrator can require you to pay our fees if (and only if): (1) the Arbitrator finds that you have acted in bad faith (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)); and (2) this power does not make this Provision invalid.</td>
                </tr>
                <tr>
                    <td>Can an award be explained?</td>
                    <td>Yes</td>
                    <td>A party may request details from the Arbitrator within 14 days of the ruling.  The Arbitrator will determine whether to grant such request.</td>
                </tr>
                <tr>
                    <td>What happens if a part of this Provision cannot be enforced?</td>
                    <td>It depends</td>
                    <td>If any portion of this Provision cannot be enforced, the rest of the Provision will continue to apply, except that (A) this Provision (except for this sentence) will be void if a court rules that the Arbitrator can decide a Claim on a class basis and the court's ruling is not reversed on appeal, and (B) if a claim is brought seeking public injunctive relief and a court determines that the restrictions in this Provision prohibiting the arbitrator from awarding relief on behalf of third parties are unenforceable with respect to such claim (and that determination becomes final after all appeals have been exhausted), the claim for public injunctive relief will be determined in court and any individual claims seeking monetary relief will be arbitrated.  In such a case the parties will request that the court stay the claim for public injunctive relief until the arbitration award pertaining to individual relief has been entered in court.  In no event will a claim for public injunctive relief be arbitrated.</td>
                </tr>
            </tbody>
            </table>
        </div> 
    </div>
    )
}


export default RegisterConcentFormContent;

