import { 
  SET_MESSAGE,
  CLEAR_MESSAGE
} from '../actions/message.action'


const initialState = {
  status: 0,
  message: ''
}
  
export default(state = initialState, {type, payload}) => {
  
  switch(type) {
    // Set message
    case SET_MESSAGE:
      return {
        ...state,
	      status: payload && payload.status ? payload.status : 0,
	      message: payload && payload.message ? payload.message : 0,
      }
    // clear message
    case CLEAR_MESSAGE:
      return {
        ...state,
        status: 0,
	      message: '',
      }
    default:
      return state
  }
}