import React, { useState, useEffect} from 'react';
import { Link , useParams, useHistory } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import TextField from '@mui/material/TextField';
import FormControl, { formControlClasses } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { A_CONTRACT_ACTIVE_STATE, DATA_SINGLE_CONTRACT } from './../../../redux/actions/contracts.action';

// we're using these objects from the storage sdk - there are others for different needs
import { BlobServiceClient } from "@azure/storage-blob";
import { SET_SNACKBAR } from './../../../redux/actions/message.action';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function WizardContractProductDescription() {

    const account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
    const sas = process.env.REACT_APP_AZURE_STORAGE_SAS;
    const containerName = process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME;


    const history = useHistory();
    const dispatch = useDispatch();
    const edit_contract = useSelector((store) => store.contracts.edit_contract);
    const user = useSelector((store) => store.auth.user);

    const [isSeller, setIsSeller] = useState(true);
    const [tempProductPhotos, setTempProductPhotos] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        product_condition: "excellent",
        product_description: "",
        product_qty: "",
        product_serial_number: "",
        product_total_purchase_price: "",
        product_photos: []
    });

    const [formValidationModal ,  setFormValidationModal] = useState(false);
    const [formValidationErrors ,  setFormValidationErrors] = useState([]);

    const handleInputChanged = (e) => {
        var  pass  = true;   
        const { name, value } = e.target;   
        if(name == 'product_total_purchase_price' || name == 'product_qty'){
            if(value < 0){
                pass = false;
            }
        }
        if(pass){
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    const handleInputFileChange = (event) => {
        let files = event.target.files;
        console.log(files);
        for(var i = 0 ; i < files.length ; i++){
            /* imageReader(files[i]); */
            uploadImage(files[i]);
        }
    }

    const imageReader = (image) => {
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
            var gx = [...tempProductPhotos];
            gx.push(e.target.result);
            setTempProductPhotos(prevState => ({
                ...prevState,
                gx
            }));
        }
    }

    const removeImage = async (index) => {
        var gx = [...formData.product_photos];
        const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blockBlobClient = await containerClient.getBlockBlobClient(gx[index].name);
        await blockBlobClient.delete({
            deleteSnapshots: 'include'
        });

        gx.splice(index, 1);
        dispatch({ type: SET_SNACKBAR , payload : {state : true , message: 'Product Picture/Video has been removed'} })
        setFormData(prevState => ({
            ...prevState,
            ['product_photos']: gx
        }));
    }

    const submitForm = (event) => {
        event.preventDefault();

        if(!(formData.product_total_purchase_price >= 100 && formData.product_total_purchase_price <= 3000)){
            setFormValidationModal(true);
            setFormValidationErrors([
                ...formValidationErrors,
                'Product price should be between 100 to 3,000. Any value below or above is not acceptable.'
            ]);
            return;
        }

        dispatch({ type: DATA_SINGLE_CONTRACT , payload : formData});
        dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
            //state : 'payment' 
            state : 'delivery' // bypass payment selection
        }});
    }


    async function uploadImage(file) {
        const blobName = file.name;
        const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        const blockBlobClient = blobClient.getBlockBlobClient();
        const result = await blockBlobClient.uploadData(file, {
          blockSize: 4 * 1024 * 1024,
          concurrency: 20,
          onProgress: ev => console.log('ev',ev)
        });
       
        var gx = [...formData.product_photos];
        gx.push({
            name: blobName,
            url: blockBlobClient.url
        });
        dispatch({ type: SET_SNACKBAR , payload : {state : true , message: 'Product Picture/Video has been uploaded'} })
        setFormData(prevState => ({
            ...prevState,
            ['product_photos']: gx
        }));

    }
    
    useEffect(() => {
        var x = {
            title: edit_contract.title ? edit_contract.title : '',
            product_condition: edit_contract.product_condition ? edit_contract.product_condition :  "excellent",
            product_description: edit_contract.product_description ? edit_contract.product_description :  "",
            product_qty: edit_contract.product_qty ? edit_contract.product_qty :  "",
            product_serial_number: edit_contract.product_serial_number ? edit_contract.product_serial_number :  "",
            product_total_purchase_price: edit_contract.product_total_purchase_price ? edit_contract.product_total_purchase_price :  "",
            product_photos: edit_contract.product_photos ? edit_contract.product_photos :  []
        }
        setFormData((prevState) => ( prevState, {...x}))
        if(x.product_photos){
            /* setIsPhotoUploaded(true); */
        }

        if(edit_contract.created_by_user_id && edit_contract.created_by_user_id == user.id){
            setIsSeller(true);
        }
        
    }, [/* dispatch */]);

    return (
    <>
      <div className="flex justify-center w-full contract-build-product-detail">
            <div className="template-card pt-10 pb-10 ps-14 pe-14">
                <h1 className='text-2xl font-bold text-hepler'>Product Description</h1>
                <form className="w-full rounded-md mt-4" onSubmit={submitForm} >
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl 
                                required={true}
                                InputProps={{ 
                                    disabled : !isSeller 
                                }}
                            >
                                <FormLabel id="demo-radio-buttons-group-label">Product Condition</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={formData.product_condition}
                                    onChange={handleInputChanged}
                                    name="product_condition"
                                    row
                                >
                                    <FormControlLabel value="New" className='required-strik-avoid'  control={<Radio required={true}  />} label="New" />
                                    <FormControlLabel value="Used-Excellent" control={<Radio />} label="Used-Excellent" />
                                    <FormControlLabel value="Used-Good" control={<Radio />} label="Used-Good" />
                                    <FormControlLabel value="Used-Poor" control={<Radio />} label="Used-Poor" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    required
                                    id="title" 
                                    label="Product Name" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    size="small" 
                                    value={formData.title} 
                                    name="title"  
                                    InputProps={{ 
                                        disabled : !isSeller
                                    }}
                                >
                                </TextField>
                            </FormControl>
                        </div>
                        
                        <div className="flex flex-col w-full gap-2 mb-2">
                            <FormControl>
                                <TextField 
                                    required
                                    id="product_description" 
                                    label="Product Description" 
                                    onChange={handleInputChanged}  
                                    variant="outlined" 
                                    multiline
                                    rows={6}
                                    value={formData.product_description} 
                                    name="product_description"  
                                    InputProps={{ 
                                        disabled : !isSeller
                                    }}
                                >
                                </TextField>
                            </FormControl>
                        </div>

                        <div className="grid grid-cols-2 gap-3">
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col mb-2 gap-2">
                                    <FormControl>
                                        <TextField 
                                            required
                                            id="product_qty" 
                                            label="Quantity Purchased" 
                                            size="small" 
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.product_qty} 
                                            name="product_qty"
                                            type='number'
                                            InputProps={{ 
                                                inputProps: { min: 0 },
                                                disabled : !isSeller
                                            }}
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                                <div className="flex flex-col  gap-2 mb-2">
                                    <FormControl>
                                        <TextField 
                                            id="product_serial_number" 
                                            label="Serial Number (Optional)" 
                                            size="small" 
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.product_serial_number} 
                                            name="product_serial_number"  
                                            InputProps={{ 
                                                disabled : !isSeller
                                            }}
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                                <div className="flex flex-col  gap-2 mb-2">
                                    <FormControl>
                                        <TextField 
                                            id="product_total_purchase_price" 
                                            label="Total Purchase Price (Min 100 to Max 3,000)" 
                                            size="small" 
                                            type='number'
                                            onChange={handleInputChanged}  
                                            variant="outlined" 
                                            value={formData.product_total_purchase_price} 
                                            name="product_total_purchase_price" 
                                            InputProps={{ 
                                                inputProps: { min: 0 },
                                                disabled : !isSeller,
                                                min: 100,
                                                max: 3000  
                                            }}
                                            required
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <div className="image-uploader-box w-full flex flex-col gap-2">
                                    <div className="photo-uploader-box">
                                        <ul>
                                        {formData && formData.product_photos.map((item, index) => (
                                            <li className="imagepreview-holder"  key={index}>
                                                <div className="image-preview">
                                                    <img src={item.url}/>
                                                </div>
                                                {isSeller &&
                                                    <span onClick={ () => { removeImage(index) }}>Remove</span>
                                                }
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                    {isSeller &&
                                    <div className="upload-image-btn">
                                        <div className="btn btn-primary btn-sm file-input-blocker">
                                            <input type="file" className="form-control" name="upload_file" accept=".png, .jpg"  onChange={handleInputFileChange} />
                                            <span>Browser or Upload</span>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className='btn btn-primary-outline ' onClick={() => {
                                    dispatch({ type: A_CONTRACT_ACTIVE_STATE , payload : {
                                        state : 'buyer'
                                    }});
                                }} >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.57129 2.64285L3.21415 8L8.57129 13.3571L7.49986 15.5L-0.000140326 8L7.49986 0.499996L8.57129 2.64285Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </div>
                            <button className='btn btn-primary-outline ' type="submit" >
                                <div className='flex  gap-2 items-center justify-center'>
                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z" fill="#6622CC"/>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
      </div>


        <Dialog
            open={formValidationModal}
            onClose={() => {setFormValidationModal(false); setFormValidationErrors([])}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Please wait!
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className='form-validation-errors'>
                    Some important fields required you attention. Please put the correct information before you submit.
                </DialogContentText>
                
                <div className="block mt-4" >
                    <ul className='flex flex-col gap-1 form-validation-errors '>
                        {formValidationErrors.map( (error) => (
                            <li className='p-1'>{error}</li>
                        ))}
                    </ul>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setFormValidationModal(false); setFormValidationErrors([]) }}>Ok</Button>
            </DialogActions>
        </Dialog>   

    </>
    )
}

export default WizardContractProductDescription;