import { 
  SET_LOADING,
  UNSET_LOADING,
  SET_USER
} from '../actions/user.action'

const userX = JSON.parse(localStorage.getItem("user"));
const initialState = {
  loading: false,
  user: userX ? userX : null
}
  
  
export default(state = initialState, {type, payload}) => {
  switch(type) {
    // Set loading
    case SET_LOADING:
      return {
        ...state,
	      loading: true
      }
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      }
    /* case SET_USER:
      var xUser = JSON.parse(localStorage.getItem("user"));
      var nx = payload ? payload : xUser
      return {
        ...state,
        user: nx,
        loading: false,
      } */
    default:
      return state
  }
}